import React from "react";
import {IPageLinkProps} from "../../page.props";
import {TabLayout} from "../../foundation/tab_layout";
import {faAward, faList, faPerson} from "@fortawesome/free-solid-svg-icons";
import {MemberDetailTraining} from "./member_detail_training";
import {MemberDetailRoles} from "./member_detail_roles";
import {MemberDetailGeneral} from "./member_detail_general";


const MemberDetail: React.FC<IPageLinkProps> = ({user, profile, gotoUrl, refresh, setRefresh}) => {

    return <>
        <TabLayout items={[
            {label: 'Stamm\u00ADdaten', icon: faPerson, component: <MemberDetailGeneral gotoUrl={gotoUrl} user={user} profile={profile} refresh={refresh} setRefresh={setRefresh} />},
            {label: 'Aus\u00ADbil\u00ADdungen', icon: faAward, component: <MemberDetailTraining gotoUrl={gotoUrl} user={user} profile={profile} refresh={refresh} setRefresh={setRefresh} />},
            {label: 'Funk\u00ADtionen & Lizenzen', icon: faList, component: <MemberDetailRoles gotoUrl={gotoUrl} user={user} profile={profile} refresh={refresh} setRefresh={setRefresh} />},
        ]} />
    </>
}

export default MemberDetail