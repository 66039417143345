import {IWithFilterProps, withFilter} from "../../../../../../api/withFilter";
import React, {useEffect, useState} from "react";
import {ITrainerJudocardFilter, IUserModel} from "../../../../../../api/models";
import {ItemNavGroup} from "../../../../../../components/form/ItemNavGroup";
import {ItemNav} from "../../../../../../components/form/ItemNav";
import {FD, Item} from "../../../../../../components/form";
import {faAdd} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import styles from './AdminOrgLicence.module.scss'
import {useParamRole} from "../../../../../../components/useParam";
import {LicenceService} from "../../../../../../api/licence";
import {ELicenceStatus, ILicenceOrder} from "../../../../../../api/models";


interface ITrainerKyuProps {
    user: IUserModel;
}

const AdminOrgLicenceFilter: React.FC<IWithFilterProps<ITrainerJudocardFilter>> = ({filter}) => {
    return <p>Todo implement filter</p>
}


const AdminOrgLicenceKyu: React.FC<ITrainerKyuProps> = ({user}) => {
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState<ILicenceOrder[]>([]);

    const role = useParamRole(user)

    useEffect(() => {
        refresh();
    }, [user])

    const refresh = async () => {
        setLoading(true);
        if (role != null) {
            setOrders(await LicenceService.all(role.orgId));
        }
        setLoading(false);
    }

    const translateStatus = (status: ELicenceStatus) => {
        switch (status) {
            case "canceled":
                return "Storniert";
            case "created":
                return "Erstellt";
            case "submitted":
                return "Bestellt";
            case "approved":
                return "Erledigt";

        }
    }

    return <>
        <ItemNavGroup label={role?.orgName}>
            <ItemNav
                align="center"
                label="Neue Bestellung anlegen"
                icon={faAdd}
                href={`/more/organization/licence/${role?.funkMgId}/new`}
            />
            {orders?.length > 0 ? orders.map(e => (
                <ItemNav
                    key={`order-${e.id}`}
                    href={`/more/organization/licence/${role?.funkMgId}/${e.id}/order`}
                    className={classNames(e.status === 'submitted' && styles.submitted, e.status === 'approved' && styles.approved)}>

                        <div className={styles.entry}>
                            <div className={styles.date}>
                                {FD(e.created)}
                                {role.orgId !== e.orgId && <div className={styles.org}>{e.orgName}</div>}
                            </div>
                            <div className={styles.name}>

                                <div className={styles.info}>

                                    <div className={styles.status}>{translateStatus(e.status)} </div>
                                    <div
                                        className={styles.kyupruefung}>{e.kyuExamId ? 'KyuPrüfung: ' + e.kyuExamId : null}</div>
                                </div>
                                <div className={styles.judocardGroup}>
                                    {e.years.map(year => (
                                        <div className={styles.judocard} key={`year-${e.id}-${year}`}>{year}</div>))}
                                    <div className={styles.amount}>{e.items} Judocards</div>

                                </div>
                            </div>
                        </div>
                </ItemNav>
            )) : <Item type="list" label="Keine Bestellungen verfügbar"/>}

        </ItemNavGroup>
    </>
}

export default withFilter('Judocard', {}, AdminOrgLicenceKyu, undefined, -1);