import React, {useEffect, useMemo, useState} from "react";
import {Card, TContentState} from "../../../../components/foundation";
import {Label, Item, DateLabel, Button, PDS} from '../../../../components/form';
import {useParams} from "react-router";
import {MapContainer, Marker, Popup, TileLayer, ZoomControl} from 'react-leaflet';
import L from 'leaflet';
import styles from './TournamentDetail.module.scss';
import marker from '../../../../theme/images/marker.svg';
import moment from "moment";
import {Share} from '@capacitor/share'
import {withFilter} from "../../../../api/withFilter";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShare, faExternalLink} from "@fortawesome/free-solid-svg-icons";
import {IEvent} from "../../../../api/models/EventArcheryModel";
import {CompetitionsService, OrderService} from "../../../../api";
import {IOrderInventory, IOrderInventoryCartItem, IUserModel} from "../../../../api/models";
import {useNavigate} from "react-router-dom";
import {ItemNavGroup} from "../../../../components/form/ItemNavGroup";
import {CartItem} from "../../../../components/public/cart_item";

const HARDCODED_INVENTORY_ORDERCAT_FOR_STARTGELD = 'TURNIER';
const HARDCODED_INVENTORY_ORDERCAT_FOR_ESSEN = "TURNIER_ESSEN"

interface ITournamentDetailProps {
    user: IUserModel;
    previewState?: TContentState;
    preview?: IEvent;
}

const TournamentDetail: React.FC<ITournamentDetailProps> = ({user, previewState, preview}) => {
    const route = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [inventory, setInventory] = useState<IOrderInventory[]>();

    useEffect(() => {
        OrderService.inventory().then(setInventory);
    }, []);

    const tournaments: IEvent[] = JSON.parse(localStorage.getItem('tournaments') || '[]');
    let tournament: IEvent | undefined = preview;
    if (tournaments && route?.id) {
        tournament = tournament || tournaments.find(x => x?.id === Number(route.id));
    }

    const items = useMemo(() => {
        // const ret: {category: string, items: IOrderInventoryCartItem[]}[] = [
        //     {
        //         category: "Essen",
        //         items: [{
        //             itemId: inventory?.find(x => x?.ordercat === HARDCODED_INVENTORY_ORDERCAT_FOR_ESSEN)?.id || 0,
        //             quantity: 0,
        //             tournament: tournament?.id,
        //         }]
        //     }
        // ];
        const ret = null;
        tournament?.bowClasses?.forEach((bowClass, bowClassIndex) => {
            const newClass = {
                category: bowClass.label,
                items: [],
            };
            tournament?.ageClasses?.forEach(ageClass => {
                const isEnrolled = tournament?.enrolled?.some(enrollment =>
                    enrollment.bowClassId === bowClass.id && enrollment.enrolledAgeClass === ageClass
                );
                // const buttonLabel = isEnrolled
                //     ? `Angemeldet in: ${ageClass}`
                //     : `Anmelden in ${ageClass} €${tournament.meeting_param?.fee}`;

                if (isEnrolled) {

                } else {
                    //newClass.items.push({
                       // itemId: inventory?.find(x => x.inventoryParams.gender ==)
                    //})
                }
                /*<div key={`${bowClassIndex}-${ageClassIndex}`}>
                    <Button
                        width="full"
                        label={buttonLabel}
                        className={isEnrolled ? styles.enrolled : styles.fee}
                        disabled={isEnrolled}
                        onClick={() => !isEnrolled && pay(tournament, bowClass.id, ageClass)}
                    />
                </div>*/
            });
            if (newClass.items.length > 0) {
                //ret.push(newClass);
            }
        });
        //return ret;
     }, [inventory, tournament])

    //const navigate = useNavigate();


    /* const openPdf = (id: number, uuid: string) => {
        navigate(`/event/pdf/${id}/${uuid}`);
    }*/

    const sharePdf = async (name: string, url: string): Promise<void> => {
        await Share.share({
            title: name,
            url: CompetitionsService.resolveEventUrl(url)
        });
    }

    const pay = async (event: IEvent, bowClassId: number, ageClass: string) => {

        OrderService.addCart({
                itemId: inventory?.find(x => x.ordercat === HARDCODED_INVENTORY_ORDERCAT_FOR_STARTGELD)?.id || 0,
                quantity: 1,
                price: Number(event.meeting_param.fee),
                tournament: event.id,
                bowClassId: bowClassId,
                ageClass: ageClass
            }
        )
        navigate(`/shop/cart`)
        //navigate(`${gotoUrl}/cart`)
    }

    /*tournament.countryIoc*/
    /* <div className={styles.share}> // add share cal
         <FontAwesomeIcon icon={faShare}/>
       </div>
    */
    // console.log('tournament:',tournament);
    return <>
        {tournament && (
            <div className={styles.tournament}>
                <div className={styles.info}>
                    <div className={styles.head}>
                        <div className={styles.title}>
                            <h3>{tournament.MEETINGTYPE_NAME}</h3>
                            <h2 dangerouslySetInnerHTML={{__html: tournament.name}}></h2>
                            <p>Ort: {tournament.meeting_param?.location}</p>
                        </div>

                        <div className={styles.date}>
                            <div className={styles.month}>{moment(tournament.date_from).format('MMM')}</div>
                            <div className={styles.day}>{moment(tournament.date_from).format('DD')}</div>
                            <div className={styles.weekday}>{moment(tournament.date_from).format('dddd')}</div>
                        </div>
                    </div>
                    <hr/>
                    {tournament.OPENFROM && (
                        <Item type={'split'} label="Nennung ab"><DateLabel value={PDS(tournament.OPENFROM)}/></Item>)}
                    {tournament.NENNSCHLUSS && (
                        <Item type={'split'} label="Nennschluss"><DateLabel
                            value={PDS(tournament.NENNSCHLUSS)}/></Item>)}
                    <hr/>
                    {tournament.meeting_param?.contactPerson && (
                        <Item type={'split'} label="Anmeldung"
                              className={styles.spacer}><Label><strong>{tournament.meeting_param?.contactPerson}</strong></Label></Item>)}

                    {tournament.meeting_param?.linkToRegistration && (
                        <Item type={'split'} label="Anmeldungslink" className={styles.spacer}><Label><a
                            href={tournament.meeting_param?.linkToRegistration}>Link <FontAwesomeIcon
                            icon={faExternalLink}/></a></Label></Item>)}

                    {tournament.meeting_param?.email && (
                        <Item type={'split'} label="Email" className={styles.spacer}><Label><a
                            href={"mailto:" + tournament.meeting_param?.email + "?subject=" + tournament.name}>{tournament.meeting_param?.email}</a></Label></Item>)}

                    {tournament.meeting_param?.phone && (
                        <Item type={'split'} label="Telefon" className={styles.spacer}><Label><a
                            href={"tel:" + tournament.meeting_param?.phone}>{tournament.meeting_param?.phone}</a></Label></Item>)}

                    {tournament.AUSSCHREIBUNG && (
                        <Item type={'split'} label="Ausschreibung"><Label>{tournament.AUSSCHREIBUNG}</Label></Item>)}
                    {tournament.NATIONENCHECK && (<>
                        <Item type={'split'} label="Nationencheck"><Label>{tournament.NATIONENCHECK}</Label></Item>
                        <hr/>
                    </>)}
                    {tournament.ages && (<>
                        <Item type={'split'} label="Altersklassen"><Label>{tournament.ages?.join(" ")}</Label></Item>
                        <hr/>
                    </>)}
                    <hr/>
                    {tournament.meeting_param?.files?.length > 0 && <ItemNavGroup label={"Ausschreibung"}>
                        {(tournament.meeting_param?.files || []).map(f => (
                            <div className={styles.item} key={f.url}>
                                <Label>
                                    <a href={CompetitionsService.resolveEventUrl(f.url)} target="_blank" rel="noreferrer"
                                       download>{f.name}</a>
                                </Label>
                                <Button
                                    className={styles.share}
                                    onClick={(evt) => sharePdf(f.name, f.url)}>
                                    <FontAwesomeIcon icon={faShare}/>
                                </Button>
                            </div>
                        ))}
                    </ItemNavGroup>
                    }
{/*RK*/}
                    {/*<ItemNavGroup label={"Anmeldung"}>*/}
                    {/*    /!*<CartItem inventory={inventory||[]} item={{*/}
                    {/*         itemId: inventory?.find(x => x.ordercat === HARDCODED_INVENTORY_ORDERCAT_FOR_ESSEN)?.id || 0*/}

                    {/*    }*/}

                    {/*    } onUpdate={()=>null} limit={3} />*!/*/}
                    {/*    <Button label={"Zum Einkaufswagen hinzufügen"} />*/}
                    {/*</ItemNavGroup>*/}

                    {/*{*/}
                    {/*    tournament.ageClasses?.map((ageClass, index) => {*/}
                    {/*        // Check if this ageClass is in the list of enrolled age classes*/}
                    {/*        const isEnrolled = tournament?.enrolled?.some(item => item.enrolledAgeClass === ageClass);*/}
                    {/*        return (*/}
                    {/*            <Button*/}
                    {/*                key={index}*/}
                    {/*                width="full"*/}
                    {/*                label={isEnrolled ? `Bereits angemeldet zu ${ageClass}` : `Anmelden zu ${ageClass} ${tournament.meeting_param?.fee} EUR`}*/}
                    {/*                className={isEnrolled ? styles.enrolled : styles.fee}*/}
                    {/*                disabled={isEnrolled} // Disable button if enrolled*/}
                    {/*                onClick={() => pay(tournament)}*/}
                    {/*            />*/}
                    {/*        );*/}
                    {/*    })*/}
                    {/*}*/}

{/*rk*/}
                    {/*<Card className={styles.card}>*/}
                    {/*    <h2>Essen</h2>*/}
                    {/*    <div key="0-essen" className={styles.essen}>*/}
                    {/*        <Button*/}
                    {/*            className={`${styles.enrolled}`}*/}
                    {/*            label="1x€15"*/}
                    {/*        />*/}
                    {/*        <Button*/}
                    {/*            className={`${styles.enrolled}`}*/}
                    {/*            label="2x€15"*/}
                    {/*        />*/}
                    {/*        <Button*/}
                    {/*            className={`${styles.enrolled}`}*/}
                    {/*            label="3x€15"*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</Card>*/}
                    {tournament.bowClasses?.map((bowClass, bowClassIndex) => (
                        <Card className={styles.card}>
                            <h2>{bowClass.label}</h2>
                            {tournament?.ageClasses?.map((ageClass, ageClassIndex) => {
                                const isEnrolled = tournament?.enrolled?.some(enrollment =>
                                    enrollment.bowClassId === bowClass.id && enrollment.enrolledAgeClass === ageClass
                                );
                                const buttonLabel = isEnrolled
                                    ? `Angemeldet in: ${ageClass}`
                                    : `Anmelden in ${ageClass} €${tournament?.meeting_param?.fee}`;
                                return (
                                    <div key={`${bowClassIndex}-${ageClassIndex}`}>
                                        {/*rk*/}
                                        {/*<Button*/}
                                        {/*    width="full"*/}
                                        {/*    label={buttonLabel}*/}
                                        {/*    className={isEnrolled ? styles.enrolled : styles.fee}*/}
                                        {/*    disabled={isEnrolled}*/}
                                        {/*    onClick={() => !isEnrolled && pay(tournament, bowClass.id, ageClass)}*/}
                                        {/*/>*/}
                                    </div>
                                );
                            })}
                        </Card>
                    ))}
                </div>

                {tournament.meeting_param?.MAP && (
                    <div className={styles.map}>
                        {tournament.address && (
                            <div className={styles.address}>{tournament.address}</div>
                        )}
                        <MapContainer
                            style={{height: '100%'}}
                            center={[tournament.meeting_param.MAP.lat, tournament.meeting_param.MAP.lon]}
                            zoom={tournament.meeting_param.MAP.z}
                            zoomControl={false}
                            scrollWheelZoom={true}
                        >
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <ZoomControl position="bottomleft"/>
                            <Marker
                                position={[tournament.meeting_param.MAP.lat, tournament.meeting_param.MAP.lon]}
                                icon={L.icon({
                                    iconUrl: marker,
                                    iconSize: [25, 41],
                                    iconAnchor: [13, 41],
                                    popupAnchor: [-5, -41],
                                })}
                            >
                                <Popup className="main-popup">
                                    <h3>{tournament.name}</h3>
                                    {tournament.address}
                                </Popup>
                            </Marker>
                        </MapContainer>
                    </div>
                )}
            </div>
        )}
    </>
}

export default withFilter('Turnier', {}, TournamentDetail, undefined, "/event");