import classNames from "classnames";
import styles from "./JudoCard.module.scss";
import AvatarImage from "./foundation/AvatarImage";
import {IMAGE_URL} from "../api";
import CameraButton from "./foundation/camera_button";
import {FD} from "./form";
import {Barcode} from "../services";
import React from "react";
import {ITrainerJudoCard, ITrainerJudocardFilter} from "../api/models";

interface IJudoCardProps {
    card: ITrainerJudoCard;
    filter: ITrainerJudocardFilter;
    refreshJudoCards: (filter: ITrainerJudocardFilter, force: boolean) => void;
}

const JudoCard: React.FC<IJudoCardProps> = ({card, filter, refreshJudoCards})=> {
    return  <div className={classNames(styles.judocard, !card.lastJudocardYear ? styles.disabled : undefined)}
                 key={card.jamaId}>
        <div className={styles.card}>
            <div className={styles.imageContainer}>
                <AvatarImage className={styles.image}
                             src={IMAGE_URL + card?.imageLink + `?v=${Date.now()}`}/>
                <div className={styles.uploadImage}>
                    <CameraButton
                        className={styles.camera}
                        uuid={card.imageLink||''}
                        onClick={() => refreshJudoCards(filter, true)}/>
                </div>
            </div>

            <div className={styles.label}>
                <p className={styles.title}>{card.firstName} {card.lastName}</p>
                <p className={styles.subtitle}>{FD(card.birthDate)} | {card.gender}</p>
                {card.medical != null && (
                    <div className={styles.medical}/>
                )}
                <p>{card.club}</p>
                <p>{card.nationality}</p>
                <p>{card?.maxGrade_name}</p>
                {filter.register ?
                    (
                        <>
                            {/*{!card.extId && card.lastJudocardYear && (
                                <Button onClick={(evt) => sync(card, evt)} label="Sync"/>
                            )}*/}
                            <div className={styles.svg} dangerouslySetInnerHTML={{
                                __html:
                                    card.extId && Barcode.generate(`${card.extId?.judoregister}`, '#3366ff')
                            }}
                            />
                        </>
                    )
                    :
                    (<div className={styles.svg}
                          dangerouslySetInnerHTML={{__html: Barcode.generate(`JAMA${card.jamaId}`)}}/>)
                }
            </div>
        </div>
    </div>
}

export default JudoCard;