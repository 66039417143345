import React from 'react';

import styles from './SquareSwitcher.module.scss'
import classNames from "classnames";

interface ISquareSwitcherProps {
    onChange: (bool: boolean) => void
    defaultValue?: boolean
    firstElement: string
    secondElement: string
}

interface ISquareSwitcherState {
    value: boolean
}

export class SquareSwitcher extends React.Component<ISquareSwitcherProps, ISquareSwitcherState> {

    constructor(props: ISquareSwitcherProps) {
        super(props);
        this.state = {
            value: props.defaultValue || false
        }
    }

    setValue(value: boolean) {
        const {onChange} = this.props;
        this.setState({value})
        onChange && onChange(value);
    }

    render() {

        const {firstElement, secondElement} = this.props
        const {value} = this.state

        return (
            <div className={styles.container}>
                <div
                    className={classNames(styles.containerActivity, value && styles.active)}
                >

                </div>
                <div onClick={() => this.setValue(false)}
                     className={classNames(styles.containerBlock, !value && styles.active)}>
                    {firstElement}
                </div>
                <div onClick={() => this.setValue(true)}
                     className={classNames(styles.containerBlock, value && styles.active)}>
                    {secondElement}

                </div>
            </div>
        );
    };
}
