import {withFilter} from "../../../../../../api/withFilter";
import React, {useEffect, useMemo, useState} from "react";
import {ItemNavGroup} from "../../../../../../components/form/ItemNavGroup";
import {Button, DateInput, DTS, Input, Item, Segment, Select} from "../../../../../../components/form";
import {addMonths} from "date-fns";
import styles from './AdminOrgMeetingNew.module.scss';
import {IOrgFunction, IUserModel, IKyuChairMember} from "../../../../../../api/models";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import {toaster} from "../../../../../../core/toaster";
import {IUserProfile} from "../../../../../../api/models/AuthModel";
import {SelectMulti} from "../../../../../../components/form/SelectMulti";
import {Upload} from "../../../../../../components/form/Upload";
import {MeetingService} from "../../../../../../api/meeting";
import {IMeeting} from "../../../../../../api/models";

interface TrainerKyuNewProps {
    user: IUserModel;
    profile: IUserProfile;
}

interface IChairMemberResEntry {
    search?: string;
    result: IKyuChairMember[];
}

interface IChairMemberRes {
    main: IChairMemberResEntry;
    member1: IChairMemberResEntry;
    member2: IChairMemberResEntry
}

const AdminOrgMeetingNew: React.FC<TrainerKyuNewProps> = ({user, profile}) => {
    const params = useParams();
    const [eventType, setEventType] = useState<number>(1);
    const [openFrom, setOpenFrom] = useState<Date | null>(null);
    const [openTo, setOpenTo] = useState<Date | null>(null);
    const [from, setFrom] = useState<Date | null>(null);
    const [to, setTo] = useState<Date | null>(null);
    const [name, setName] = useState<string | null>(null);
    const [ort, setOrt] = useState<string>("");
    const [country, setCountry] = useState<string>("AUT");
    const [altersklasse, setAltersklasse] = useState<string[]>([]);
    const [meetingRoles, setMeetingRoles] = useState<number[]>([]);
    const [nationencheck, setNationencheck] = useState<boolean>(false);
    const [bundesweit, setBundesweit] = useState<boolean>(false)
    const [publish, setPublish] = useState(false);
    const [url, setUrl] = useState<string|null>(null);
    const [streamingUrl, setStreamingUrl] = useState<string|null>(null)
    const [text, setText] = useState<string|null>(null)
    const [preis, setPreis] = useState<string|null>(null);
    const [teilnahme, setTeilnahme] = useState<string|null>(null)

    const navigate = useNavigate()

    const roleId = Number(params['role'] || 0);
    const role = user?.rolesOrg.map(x => x?.adminOrg || x?.trainerOrg).flat().find(x => x?.funkMgId === roleId) as IOrgFunction;

    const akOptions = useMemo(() => {
        return profile.weightClasses.map(x => ({label: x.short, value: x.short}))
            .filter((x, i, a) => a.indexOf(a?.find(y => y?.value === x?.value) as { label: string, value: string }) === i)
    }, [profile])


    useEffect(() => {
        const eventTypeObject = profile.meetingTypes.find(x => x.id === eventType)
        if (eventTypeObject) {
            setMeetingRoles(eventTypeObject.param.MEETINGROLES || []);
            setPublish(eventTypeObject.param.PUBLISH_API === 1 || false);
            setBundesweit(eventTypeObject.param.BUNDESWEIT === 1 || false);
            setNationencheck(eventTypeObject.param.NATIONENCHECK === 1 || false);
            //console.log(eventTypeObject)
        }
    }, [profile, eventType]);

    const validate = () => {
        return openFrom != null && openTo != null && from != null && to != null &&
            (name?.length || 0) > 0;
    }

    const create = async (data: React.MouseEvent<HTMLButtonElement>): Promise<boolean> => {
        if (validate()) {
            toaster(async () => {

                const meeting: IMeeting = {
                    meetingtype_id: eventType,
                    name: name as string,
                    open_from: DTS(openFrom as Date),
                    open_to: DTS(openTo as Date),
                    startdate: DTS(from as Date),
                    enddate: DTS(to as Date),
                    org_id: role.orgId,
                    param: {
                        ORT: ort as string,
                        MEETINGROLES_REQ: meetingRoles,
                        URL: url,
                        STREAMING_URL: streamingUrl,
                        TEXT: text,
                        PRICING: preis,
                        TEILNAHME: teilnahme,
                    },
                    ages_nennbar: altersklasse,
                    mutter_id: 0, // create duplicates?
                    //bl_begeg_id: beg


                } as unknown as IMeeting // todo fix
                const newMeeting = await MeetingService.create(role.orgId, meeting);
                navigate(`/more/organization/meeting/${roleId}/${newMeeting}`)

                return
            }, {success: 'Event erfolgreich angelegt', failure: 'Fehler beim Anlegen der Prüfung!'})


        }
        return false;
    }

    return <>
        <ItemNavGroup label="Event anlegen">
            <Item type="full" label="Veranstaltungstyp">
                <Select value={eventType} onChange={setEventType}
                        options={profile.meetingTypes.sort((a, b) => a.id - b.id).map(x => ({
                            label: x.name,
                            value: x.id,
                        }))}/>
            </Item>
            <Item type="full" label="Teilnehmer-Rollen">
                <SelectMulti onChange={setMeetingRoles} value={meetingRoles}
                             options={profile.meetingRoles.map(x => ({label: x.name, value: x.id}))}/>
            </Item>
            <Item type="full" label="Name">
                <Input
                    onChange={(v) => setName(v)}
                />
            </Item>
            <Item type="full" label="Anmeldung Von">
                <DateInput
                    value={openFrom}
                    onChange={setOpenFrom}
                    minDate={new Date()}
                    maxDate={from || addMonths(new Date(), 18)}
                />
            </Item>
            <Item type="full" label="Anmeldung Bis">
                <DateInput
                    value={openTo}
                    onChange={setOpenTo}
                    disabled={!openFrom}
                    minDate={openFrom || undefined}
                    maxDate={from || addMonths(openFrom as Date, 18)}
                />
            </Item>
            <Item type="full" label="Start">
                <DateInput
                    value={from}
                    onChange={setFrom}
                    disabled={!openTo}
                    minDate={openTo || undefined}
                    maxDate={addMonths(openTo as Date, 1)}
                />
            </Item>
            <Item type="full" label="Ende">
                <DateInput
                    value={to}
                    onChange={setTo}
                    disabled={!from}
                    minDate={from || undefined}
                    maxDate={addMonths(from as Date, 1)}
                />
            </Item>
            <Item type="full" label="Ort">
                <Input value={ort}
                       onChange={(v) => setOrt(v)}
                />
            </Item>
            <Item type="full" label="Land IOC">
                <Select
                    value={country}
                    onChange={setCountry}
                    options={profile.countries.map(x => ({label: x.name, value: x.IOC}))}
                />
            </Item>
            <Item type="full" label="Nationencheck">
                <Segment value={nationencheck} onChange={setNationencheck}
                         options={[{label: "Nein", value: false}, {label: "Ja", value: true}]}/>
            </Item>
            <Item type="full" label="Bundesweit">
                <Segment value={bundesweit} onChange={setBundesweit}
                         options={[{label: "Nein", value: false}, {label: "Ja", value: true}]}/>
            </Item>
            <Item type="full" label="Nennbare Altersklassen">
                <SelectMulti onChange={setAltersklasse} value={altersklasse} options={akOptions}/>
            </Item>
            <Item type="full" label="Url">
                <Input
                    value={url||''}
                    onChange={(v) => setUrl(v)}
                />
            </Item>
            <Item type="full" label="Streaming Url">
                <Input
                    value={streamingUrl||''}
                    onChange={(v) => setStreamingUrl(v)}
                />
            </Item>
            {/*<Item type="full" label="Bundesliga">
                <Select onChange={() => null}
                        options={[{label: 'tbd', value: '3'}]}/>
            </Item>*/}
            <Item type="full" label="Text">
                <Input
                    value={text||''}
                    onChange={(v) => setText(v)}
                />
            </Item>
            <Item type="full" label="Preis">
                <Input
                    value={preis||''}
                    onChange={(v) => setPreis(v)}
                />
            </Item>
            <Item type="full" label="Teilnahme">
                <Input
                    value={teilnahme||''}
                    onChange={(v) => setTeilnahme(v)}
                />
            </Item>
            <Item type="full" label="Veröffentlichen">
                <Segment value={publish} onChange={setPublish}
                         options={[{label: "Nein", value: false}, {label: "Ja", value: true}]}/>
            </Item>
            <Item type="full" label="Anhänge">
                <Upload orgId={role.orgId}/>
            </Item>
        </ItemNavGroup>
        <Button label="Speichern" className={styles.save} onClick={create} disabled={!validate()}/>
    </>
}

/*
??
- bei event anzeiget 3 reite (Österr, LV, Verein) -> vereinsmitgliedschaft & suche | ergebnisse
bug bei selection vorsitzer/beisitzter org id 0 ist jetzt blöd
- meetingtype!!
- date -> timestamp
- ende übernimmt immer start
- und anmeldung setzt from
- meeting_type ausfüllen aus meetingtype
- rundungend runder bei segement
- ioc -> default österreich
- streaming url -- done

- fileupload 1:1 wie bei archery
- MAP: mit karte - wie archery

- "PUBLISH_API": true, // Veröffentlichen ja/nein -- done
- "BUNDESWEIT": true, // flag -- done

- "MEETINGROLES_REQ": [9,13,14,17,20], multiselect aus meetingroles mit params --done

  "TEILNAHME": "Nennung|Anmeldung", - ignorieren/ausklammern

   in_bl_begeg_id
    -> bundesliga begegnung -> view v_bl_begeg -> id alle in zukunft und nur alle bl_org_id von der aktuellen org
    -> ort übernehmen?
    -> teamnamen x 2 , ort, kampfbegin uhrzeit, saisson, runde, playoff, veranstalter, liganame

- in_ak_nennbar [ -- done
        "U8F", "U10M", "U16F", "U8M", "U14F", "SENM", "U18M", "U10F", "U12F", "U23M", "U14M", "SENF", "U21M", "U12M", "U23F", "U16M", "U21F", "U18F"
    ] liste der internationalen ak - multiselect table ak / mit view v_akgk

- "ORT": "Neue Mittelschule", --done
- "URL": "https://www.judojama.net", --done
- "TEXT": "ACHTUNG: Offen für alle Vereine!", --done
- "PRICING": "EUR 10,-", --done

    "COUNTRY_IOC": "AUT", // ??? ausklammern // weg

    "NATIONENCHECK": false
 */

export default withFilter('Event', {}, AdminOrgMeetingNew, undefined, -1);
