import React from "react";
import {Card, Content, Header} from "../../components/foundation";
import {Link} from "react-router-dom";
import {withDesktop} from "../../api/withDesktop";
import styles from "./login.module.scss";


const Forbidden: React.FC = () => {
    return (<>
        <Content state={"active"} >
            <Card className={styles.forbidden}>
                <h2>Anmeldung</h2>
                <Link to={"/login"}>
                    <p>Melde dich bitte <b>hier</b> an!</p>
                </Link>
            </Card>
        </Content>
    </>)
}

export default withDesktop(Forbidden);