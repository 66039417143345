import React from 'react';
import classNames from "classnames";
import {Card, Content, Header} from "../../../components/foundation";
import {Link} from "react-router-dom";
import {Email, Form, Pin, Submit} from "../../../components/form";

import styles from './Login.module.scss'
import {toaster} from "../../../core/toaster";
import i18n from "../../../config/i18n";
import {AuthService} from "../../../api";

const LOGIN_SUCCESS = 'Login successful';

interface ILoginProps {

}

interface ILoginState {
    email: string;
    pin: string;
    showToast: boolean;
    toastColor: string;
    toastMessage: string;
    disableButton: boolean;
}

class LoginPage extends React.Component<ILoginProps, ILoginState> {

    constructor(prop: ILoginProps) {
        super(prop);
        this.state = {
            email: localStorage.getItem('email') || '',
            pin: "",
            showToast: false,
            toastColor: 'primary',
            toastMessage: '',
            disableButton: false,
        }
    }

    handlePinChange(e: any) {
        this.setState({
            pin: e.target.value,
        })
    }

    handleEmailChange(e: string) {
        this.setState({
            email: e,
        })
    }

    async login(e: any): Promise<void> {
        const {disableButton, email, pin} = this.state;
        e.preventDefault();
        if (disableButton) {
            return;
        }

        this.setState({disableButton: true})
        localStorage.setItem('email', email);

        await toaster(async() => {
            try {
                await AuthService.authorize(email, pin);
                setTimeout(() => {
                    window.location.href = '/news';
                }, 250)
            } catch (e) {
                setTimeout(() => {
                    this.setState({disableButton: false})
                }, 3000);
                throw e;
            }
        }, {
            success: i18n().login.success,
            failure: i18n().login.failure,
        })

    }

    dismissToast() {        this.setState({
            showToast: false,
        });
    };

    render() {
        const { disableButton, email, pin } = this.state;

        return (
            <>
                <Header label="Login"/>
                <Content state="active">
                    <div className={styles.image} />
                    <Card className={styles.card}>
                        <Form onSubmit={(evt) => this.login(evt)}>

                            <h3>{i18n().pin.message}</h3>
                            <Email
                                label="Email"
                                name="email"
                                //autocomplete="on"
                                placeholder="Email"
                                // autofocus={true}
                                value={email}
                                onChange={evt => this.handleEmailChange(evt)}
                            />
                            <Pin
                                label="Pin"
                                name="pin"
                                keyBoardType="number"
                                placeholder="123456"
                                value={pin}
                                onChange={evt => this.handlePinChange(evt)}
                            />
                            <div className={classNames('ion-text-wrap', styles.info)}>Der PIN ist 2.5 Stunden gültig.</div>
                            <Link className={styles.pinlink} to='/signup'>6-stelligen Pin via Email anfordern.</Link>
                            <Submit label="Log In" name="login" disabled={disableButton} />
                        </Form>
                    </Card>
                </Content>
            </>
        )
    }
}

export default LoginPage;
