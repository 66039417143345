import {HttpService} from "./http";
import {Constants} from "./constants";
import {ITrainerJudoCard} from "./models/TrainerJudoCard";
import {IAge, IOrganization} from "./models/MetaModel";

const GET_META = `${Constants.JAMA_SERVER_API}/meta`;
const META = 'meta'
const FILE_DOWNLOAD = `${Constants.JAMA_SERVER_API_V2}/web/download`;

/**
 * Class representing a MetaService.
 */
export class MetaService {
    /**
     * Refreshes the data by making a GET request to the server and updating the local storage.
     *
     * @return {Promise<undefined>} A promise that resolves to undefined when the refresh is complete.
     */
    public static async refresh(): Promise<undefined> {
        const res = await HttpService.get<ITrainerJudoCard[]>(`${GET_META}`, true);
        if (res != null) {
            localStorage.setItem(META, JSON.stringify(res));
        }
        return;
    }


    /**
     * Retrieves the PDF URL from the 'meta' object stored in the local storage.
     *
     * @returns {string} - The PDF URL from the 'meta' object, or an empty string if not found.
     */
    public static get metaPdfUrl() {
        return JSON.parse(localStorage.getItem('meta') || '{}').pdfUrl;
    }

    /**
     * Retrieves the meta ages from the localStorage.
     *
     * @returns {IAge[]} An array of meta ages.
     */
    public static get metaAges(): IAge[] {
        return JSON.parse(localStorage.getItem('meta') || '{}').ages || [];
    }

    /**
     * Retrieves an array of meta organizations from local storage.
     *
     * @return {IOrganization[]} An array of meta organizations.
     */
    public static get metaOrganizations(): IOrganization[] {
        return JSON.parse(localStorage.getItem('meta') || '').organizations;
    }

    /**
     * Returns the list of subscriptions stored in the local storage.
     *
     * @return {number[]} - The list of subscriptions.
     */
    public static get subscriptions(): number[] {
        return JSON.parse(localStorage.getItem('subscriptions') || '[1,2,3,4,5,6,7,8,9,10]')
    }

    /**
     * Sets the subscriptions in the local storage
     *
     * @param {number[]} value - An array of subscription values to be set in the local storage
     */
    public static set subscriptions(value: number[]) {
        localStorage.setItem('subscriptions', JSON.stringify(value));
    }

    /**
     * Resolves the URL for a competition file based on the meeting ID and UID.
     *
     * @param {string} meetingId - The ID of the meeting.
     * @param {string} uid - The UID of the file.
     * @return {string} - The resolved URL for the competition file.
     */
    public static resolveCompetitionFileUrl(meetingId: string, uid: string) {
        return `${MetaService.metaPdfUrl}/uploads/meeting/${meetingId}/${uid}`;
    }

    public static resolveCompetitionFileUrlAWS(name: string) {
        return `${FILE_DOWNLOAD}/${name}`;
    }
}