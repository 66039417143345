import React, {useState} from "react";
import {IPageProps} from "../../../../../components/page.props";
import {useParamId, useParamRole} from "../../../../../components/useParam";
import {withFilter} from "../../../../../api/withFilter";
import Event from "../../../../../components/admin/event/event";
import {EventDetail} from "../../../../../components/admin/event/event_detail";

export const AppAdminEvent: React.FC<IPageProps> = (props) => {
    const role = useParamRole(props.user);
    const gotoUrl = `/more/admin/${role?.funkMgId}/events`;
    const [refresh, setRefresh] = useState<number>(0);

    const id = useParamId();

    return id == null ? <Event {...props} gotoUrl={gotoUrl} refresh={refresh}  />
        : <EventDetail {...props} gotoUrl={gotoUrl} refresh={refresh} setRefresh={setRefresh} />
};

export default withFilter('Event', {}, AppAdminEvent, undefined, -1);