import React, {useRef, useState} from 'react';
import {Button} from "./Button";
import {UtilService} from "../../api";
import styles from './Upload.module.scss'
import {toaster} from "../../core/toaster";

interface UploadProps {
    orgId: number;
    purpose?: string;
    purposeId?: number;
    onUploadComplete?: (uploadedFile: any) => void;
}

export const Upload: React.FC<UploadProps> = ({orgId, purpose, purposeId, onUploadComplete}) => {
    const [file, setFile] = useState<FileList | null>(null);
    const fileRef = useRef<HTMLInputElement | null>(null)
    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFile(event.target.files);
    };
    const clearFileInput = () => {
        setFile(null);
        if (fileRef.current) {
            fileRef.current.value = '';
        }
    }
    const onFileUpload = () => {
        const formData = new FormData();
        if (file) {
            for (let i = 0; i < (file?.length || 0); i++) {
                // update the formData object
                formData.append(
                    'file[]',
                    file[i],
                    file[i].name,
                );
            }
        }
        if (purpose !== undefined && purposeId !== undefined) {
            formData.append('purpose', purpose.toString());
            formData.append('purposeId', purposeId.toString());
        }

        UtilService.Upload(orgId, formData).then(uploadedFile => {
            setFile(null);
            clearFileInput();
            if (onUploadComplete) {
                onUploadComplete(uploadedFile);  // Notify parent component
            }
        });
        // Request made to the backend API
        // Send formData object
        // axios.post('api/uploadfile', formData);
    };


    return (
        <p>
            <input type="file" onChange={onFileChange} ref={fileRef} className={styles.file} />
            <Button width="full" label="Hochladen" onClick={onFileUpload} />
        </p>
    );
};

