import {ITrainerJudoCardExtId} from "./models/TrainerJudoCard";
import {AuthService} from "./auth";
import {HttpService} from "./http";
import {Constants} from "./constants";


const POST_JUDOREG = `${Constants.JAMA_SERVER_API}/trainer/sync`;


/**
 * Represents a class that handles external service operations.
 */
export class ExternalService {

    /**
     * Synchronizes the judo registration for a specified trainer.
     *
     * @param {number} mg - The trainer's id.
     * @return {Promise<ITrainerJudoCardExtId | null>} - The extended ID of the trainer's judo card, or null if not found.
     */
    public static async syncJudoregister(mg: number): Promise<ITrainerJudoCardExtId | null> {
        await AuthService.refreshJwt();
        return await HttpService.post2<any, ITrainerJudoCardExtId>(`${POST_JUDOREG}`, {id: mg}, true);
    }
}