import {IGalleryCollectionModel, IGalleryModel} from "./models/GalleryModel";
import {HttpService} from "./http";
import {Constants} from "./constants";


const GALLERIES = `${Constants.JAMA_WP_ROOT}galleries`
const COLLECTIONS = `${Constants.JAMA_WP_ROOT}gallery/`

/**
 * GalleryService class provides methods to retrieve galleries and collections from an API.
 * @class
 */
export class GalleryService {


    /**
     * Retrieves galleries from the server.
     * @returns {Promise<IGalleryModel[]>} - A promise that resolves to an array of gallery models.
     */
    public static async getGalleries(): Promise<IGalleryModel[]> {

        let res = await HttpService.get(GALLERIES, false);
        /* if (res == null) return [];
        res = await Promise.all((res || []).map(async x => {
            x.media = await ApiService.get<INewsMediaModel>(`${NEWS_MEDIA_WP}${x.featured_media}`, false);
            return x;
        })); */

        return Array.isArray(res) ? res || [] : [];
    }

    /**
     * Retrieves a collection of gallery models.
     *
     * @param {number} id - The ID of the collection.
     * @returns {Promise<IGalleryCollectionModel[]>} A promise that resolves to an array of gallery collection models.
     */
    public static async getCollection(id: number): Promise<IGalleryCollectionModel[]> {

        let res = await HttpService.get(`${COLLECTIONS}${id}`, false);

        // @ts-ignore
        return Array.isArray(res.images.images) ? res.images.images || [] : [];

    }
}