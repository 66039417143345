import React, {useRef} from "react";
import {Camera, CameraResultType, CameraSource} from '@capacitor/camera';
import {Button} from "../form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCamera} from "@fortawesome/free-solid-svg-icons";
import {ProfileService} from "../../api";
import styles from './camera_button.module.scss'
import classNames from "classnames";

interface ICameraButtonProps {
    uuid: string;
    className?: string;
    onClick?: () => void;
}

export const CameraButton: React.FC<ICameraButtonProps> = ({uuid, className, onClick}) => {

    // const [file, setFile] = useState<FileList | null>(null);
    const fileRef = useRef<HTMLInputElement | null>(null)

    const base64toBlob = (dataURI: string) => {
        // convert base64/URLEncoded data component to raw binary data held in a string
        var byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(dataURI.split(',')[1]);
        else
            byteString = unescape(dataURI.split(',')[1]);

        // separate out the mime component
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        // write the bytes of the string to a typed array
        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], {type: mimeString});
    }

    const takePicture = async() => {

        try {
            console.log(await Camera.requestPermissions({permissions: ['camera']}));

        } catch (e) {
            fileRef.current && fileRef.current.click();
            console.error(e);
        }
        const dataUrl = await Camera.getPhoto({
            resultType: CameraResultType.DataUrl,
            source: CameraSource.Prompt,
            quality: 100,
            width: 350,
            // height: 450,

        })
        if (dataUrl.dataUrl) {
            const blob = base64toBlob(dataUrl.dataUrl);
            const form = new FormData();
            form.append("file", blob);

            // console.log(dataUrl.dataUrl);
            await ProfileService.uploadProfilePicture(uuid, form);
            // ajax.send(form); // The possible data sent.
            if (onClick != null) {
                onClick();
            }
        }
    }

    const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const formData = new FormData();
        const file = event.target.files;
        if (file) {

            //update the formData object
            formData.append(
                'file',
                file[0],
                file[0].name,
            );
            await ProfileService.uploadProfilePicture(uuid, formData);
            // ajax.send(form); // The possible data sent.
            if (onClick != null) {
                onClick();
            }
            return
        }
        //setFile(event.target.files);
    };

    return (
        <div className={classNames(styles.container)}>
            <input type="file" onChange={onFileChange} ref={fileRef} className={styles.file} />
            <Button className={classNames(styles.camera, className)} onClick={() => takePicture()}>
                <FontAwesomeIcon icon={faCamera} />
            </Button>
        </div>
    )
}

export default CameraButton
