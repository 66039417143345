import React, {useState} from 'react';
import {useParamRole} from "../../useParam";
import {useNavigate} from "react-router-dom";
import {useLicenceCategories} from "../../useLicenceCategories";
import {toaster} from "../../../core/toaster";
import {LicenceService} from "../../../api/licence";
import {ItemNavGroup} from "../../form/ItemNavGroup";
import {Button, Item, Select} from "../../form";
import styles from "./licence_detail_order.module.scss";
import {IPageLinkProps} from "../../page.props";


export const LicenceDetailOrder: React.FC<IPageLinkProps> = ({user, profile, gotoUrl, refresh, setRefresh}) => {
    const role = useParamRole(user);
    const navigate = useNavigate();
    const categories = useLicenceCategories(user)
    const [categoryId, setCategoryId] = useState<number>(0);
    const [loading, setLoading] = useState(false);

    const categoryOptions = categories.map(x => ({label: `${x.cat} ${x.foryear||''}`, value:x.id }))

    const doOrder = () => {
        setLoading(true)
        toaster(async() => {

            const orderId = await LicenceService.create(role.orgId, {
                categoryId: categoryId,
            })
            setRefresh && setRefresh((refresh ||0)+1);
            navigate(`/${gotoUrl}/${orderId}?t=order`)
        }, {
            success:"Bestellung erfolgreich abeschickt",
            failure:"Bestellung fehlgeschlagen"
        }, () => {
            setLoading(false)
        })

    }


    return (
        <ItemNavGroup label="Bestellung erstellen">
            <Item type="list" label="Kategorie">
                <Select onChange={(v) => setCategoryId(v.value)} value={categoryId} options={ categoryOptions} />
            </Item>
            {/*<Item type="list" label="Verein?">
                <Select onChange={()=>null} options={[{label: 'Verein 1', value: 2023}, {label: 'Verein 2', value: 2024}]} />
            </Item>*/}

            <Button label="Erstellen" className={styles.save} onClick={doOrder} disabled={loading||!categoryId}/>
        </ItemNavGroup>

    );
};