import React, {useState} from 'react';
import {withDesktop} from "../../api/withDesktop";
import {IPageProps} from "../../components/page.props";
import {useParamRole} from "../../components/useParam";
import {Layout} from "../../components/foundation/layout";

import Event from "../../components/admin/event/event"
import {EventDetail} from "../../components/admin/event/event_detail";


export const DesktopEventsPage: React.FC<IPageProps> = (props) => {
    const role = useParamRole(props.user);
    const [refresh, setRefresh] = useState<number>(0)

    const gotoUrl = `/${role?.orgId}/${role?.funkMgId}/events`
    return (
        <Layout
            list={
                <Event {...props} gotoUrl={gotoUrl} refresh={refresh} setRefresh={setRefresh} />
            }
            page={
                <EventDetail {...props} gotoUrl={gotoUrl} refresh={refresh} setRefresh={setRefresh} />
            }
        />
    );
};


export default withDesktop(DesktopEventsPage)