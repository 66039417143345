import React, {lazy} from 'react';
import {EForms, useParamForm} from "../../components/useParam";
import {IPageProps} from "../../components/page.props";
import {ApplicationDesktop} from "../../components/foundation/application_desktop";

//const Member = lazy(() => require("./member").default);
import Member from './member'
//const Kyu = lazy(() => require("./kyu").default);
import Kyu from './kyu_page'
//const Licences = lazy(() => require("./licences").default);
import Licences from './licences'
//const Events = lazy(() => require("./events").default);
import Events from './events'
//const Organizations = lazy(() => require("./organizations").default);
import Organizations from './organizations'
//const Judocards = lazy(() => require('./judocards').default);
import Judocards from './judocards'
//const Trainer = lazy(() => require('./trainer').default);
import Trainer from './trainer'


export const DesktopForm: React.FC<IPageProps> = (props) => {
    const form = useParamForm();


    switch (form) {
        case EForms.memberships:
            return <Judocards {...props} />
        case EForms.members:
            return <Member {...props} />
        case EForms.kyu:
            return <Kyu {...props} />
        case EForms.licences:
            return <Licences {...props} />
        case EForms.events:
            return <Events {...props} />
        case EForms.organizations:
            return <Organizations {...props} />
        case EForms.trainer:
            return <Trainer {...props} />
        default:
            return <ApplicationDesktop {...props} />
    }

};

