import React from "react";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {useNavigate} from "react-router-dom";
import {ReactChildrenType} from "../../core/children";
import styles from './ItemNav.module.scss'

interface IItemProps {
    type?: 'form' | 'list' // default = form
    label?: string;
    children?: ReactChildrenType;
    onClick?: () => void;
    icon?: IconProp;
    href?: string;
    primary?: boolean;
    className?: string;
    align?: 'left'|'center'|'right'|string;
    selected?: boolean;
    disabled?: boolean;
}


export const ItemNav: React.FC<IItemProps> = ({
    label,
    type,
    children,
    onClick,
    icon,
    href,
    primary,
    className,
    align,
    selected,
    disabled = false
}) => {

    const navigate = useNavigate()

    return (
        <div
            className={classNames(
                styles.item,
                styles[type || 'form'],
                primary && styles.primary,
                selected && styles.selected,
                disabled && styles.disabled,
                className,
            )}
            onClick={!disabled ? (onClick ? onClick : () => href && navigate(href)) : undefined}
        >
            <div className={styles.content}>
                {icon && <FontAwesomeIcon icon={icon} className={styles.icon}/>}
                {label && <label
                    className={classNames(
                        styles.label,
                        align === 'center' && styles.center,
                        align==='right' && styles.right
                    )}>{label}</label>}
                {children}
            </div>
            {onClick && <div className={styles.arrow}>
                <FontAwesomeIcon icon={faChevronRight}/>
            </div>}
        </div>
    )
}