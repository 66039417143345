import React from "react";
import styles from './Application.module.scss'
import {useNavigate} from "react-router-dom";
import {App, BackButtonListenerEvent} from "@capacitor/app";

interface IAppProps {
    children: React.ReactElement[]
}


export const Application: React.FC<IAppProps> = ({children}) => {
    const navigate = useNavigate();

    App.addListener('backButton', (ev: BackButtonListenerEvent) => {
        // todo close app if no more go back
        console.log('go-back')
        /*if (!history..canGoBack()) {
            App.exitApp();
        } else {*/
        navigate(-1);


    });
    return (
        <div className={styles.app}>
            {children}
        </div>
    )
}

