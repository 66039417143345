import React from 'react';
import {IMemberDuplicate} from "../../api/models/MemberModel";
import styles from "./duplicate.module.scss";
import AvatarImage from "../foundation/AvatarImage";
import {MemberService} from "../../api/member";
import {FD, Item} from "../form";
import classNames from "classnames";

interface IDuplicateProps {
    member: IMemberDuplicate;
    selected: boolean
    onClick: (m:IMemberDuplicate) => void
}

export const Duplicate: React.FC<IDuplicateProps> = ({member, selected, onClick}) => {

    return (
        <Item type="list" onClick={() => onClick(member)} className={classNames(selected && styles.selected, !member.assignable && styles.disabled)}>
            <div className={classNames(styles.duplicate)}>
                <AvatarImage src={MemberService.imageUrl(member?.uuid)} className={styles.duplicateImage}/>
                <div className={styles.someContainer} >
                    <div className={styles.someRow} >
                        <div>{member.firstname} {member.surname}</div>
                        <div>{FD(member.birthdate)}</div>
                        <div>{member.gender}</div>
                    </div>
                    {member.orgName}
                </div>

            </div>

        </Item>
    );
};