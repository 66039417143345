import React, {useEffect, useState} from 'react';
import {useParamId, useParamRole} from "../../useParam";
import {useNavigate} from "react-router-dom";
import {ILicenceCandidate} from "../../../api/models";
import {toaster} from "../../../core/toaster";
import {CacheService} from "../../../api";
import {LicenceService} from "../../../api/licence";
import {ItemNavGroup} from "../../form/ItemNavGroup";
import {Button, Item} from "../../form";
import styles from "./licence_detail.module.scss";
import {IPageLinkProps} from "../../page.props";

export const LicenceDetailApprove: React.FC<IPageLinkProps> = ({user, profile, gotoUrl, refresh, setRefresh}) => {
    const role = useParamRole(user)
    const navigate = useNavigate();
    const id = useParamId()
    const [candidates, setCandidates] = useState<ILicenceCandidate[]>()

    useEffect(() => {
        toaster(async () => {
                await CacheService.clear()
                setCandidates(await LicenceService.candidates(role.orgId, id||0))
                return null
            }, {
                success: null, failure: "Fehler beim laden"
            }
        )

    }, [role, id]);
    const submit = () => {
        toaster(async () => {
                await LicenceService.approve(role.orgId, id||0)
                setRefresh && setRefresh((refresh ||0)+1);
                return
            }, {
                success: "Bestellung freigegeben",
                failure: "Fehler beim Freigeben"
            }, () => navigate(`${gotoUrl}/`)

        )
    }
    return (
        <div>
            <ItemNavGroup label={`Bestellung #${id}`}>
                <>
                    {candidates?.filter(x => x.ordered)?.map(c => (
                        <Item key={c.mgId} type={"list"} label={`${c.surname} ${c.firstname}`}></Item>

                    ))}
                </>
            </ItemNavGroup>

            <Button label="Freigeben" className={styles.save}
                    onClick={submit} disabled={false}/>
        </div>
    );
};