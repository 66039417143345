import React from "react";
import styles from './Email.module.scss'

interface IEmailProps {
    label?: string;
    name: string;
    placeholder: string;
    disabled?: boolean;
    value?: string
    onChange?: (v:string) => void
}


export const Email: React.FC<IEmailProps> = ({
    name,
    label,
    placeholder,
    disabled,
    value,
    onChange
}) => {

    return (
        <div className={styles.email}>
            {label && <label>{label}</label>}
            <input
                type="email"
                name={name}
                placeholder={placeholder}
                disabled={disabled}
                value={value}
                onChange={(e) => onChange && onChange(e.target.value)}
            />
        </div>
    )
}