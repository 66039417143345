import React, { useState, useCallback } from "react";
import { IPageProps } from "../../../../../components/page.props";
import { useParamId, useParamRole } from "../../../../../components/useParam";
import { withFilter } from "../../../../../api/withFilter";
import KyuDetail from "../../../../../components/admin/kyu/kyu_detail";
import Kyu from "../../../../../components/admin/kyu/kyu";

const AppAdminKyu: React.FC<IPageProps> = (props) => {
    const role = useParamRole(props.user);
    const gotoUrl = `/more/admin/${role?.funkMgId}/kyu`;
    const [refresh, setRefresh] = useState<number>(0);

    const triggerRefresh = useCallback(() => {
        setRefresh((prev) => prev + 1);
    }, []);

    const id = useParamId();

    return id == null ? (
        <Kyu {...props} gotoUrl={gotoUrl} refresh={refresh} triggerRefresh={triggerRefresh} />
    ) : (
        <KyuDetail
            {...props}
            gotoUrl={gotoUrl}
            refresh={refresh}
            setRefresh={setRefresh}
            triggerRefresh={triggerRefresh}
        />
    );
};

export default withFilter('Kyu', {}, AppAdminKyu, undefined, -1);