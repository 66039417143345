import React, {useEffect, useMemo, useState} from "react";
import {IMember} from "../../../api/models/MemberModel";
import {MemberService} from "../../../api/member";
import {useParamId, useParamRole} from "../../useParam";
import {ItemNavGroup} from "../../form/ItemNavGroup";
import {ItemNav} from "../../form/ItemNav";
import {FD, Input, Item} from "../../form";
import AvatarImage from "../../../components/foundation/AvatarImage";
import styles from './member.module.scss'
import {useNavigate} from "react-router-dom";
import {Loading} from "../../foundation/Loading";
import {faPerson} from "@fortawesome/free-solid-svg-icons";
import {IPageLinkProps} from "../../page.props";
import classNames from "classnames";


export const Member: React.FC<IPageLinkProps> = ({user, profile, gotoUrl, refresh}) => {
    const [loading, setLoading] = useState(true);
    const [members, setMembers] = useState<IMember[]>([]);
    const [search, setSearch] = useState<string>('');

    const navigate = useNavigate();
    const role = useParamRole(user);
    const id = useParamId();

    const filterLength = 200;

    useEffect(() => {
        setLoading(true)
        if (user) {
            MemberService.all(role.orgId).then((m) => {
                setMembers(m);
                setLoading(false);
            })
        }
    }, [user, refresh])

    const goto = (id: number) => {
        // /more/organization/member
        navigate(`${gotoUrl}/${id}`)
    }

    // const filter = useMemo(() => {
    //     return search.length > 0 ? members.filter(x =>
    //             (x.firstname?.toLowerCase()?.includes(search?.toLowerCase()) ||
    //                 x.surname?.toLowerCase()?.includes(search?.toLowerCase())))
    //         : members.filter(x => x.orgId === role.orgId)
    //
    // }, [members, role.orgId, search]);
    const filter = useMemo(() => {
        if (search.length === 0) {
            return members.filter(x => x.orgId === role.orgId);
        }

        const searchLower = search.toLowerCase().trim();

        return members.filter(x => {
            // Check if search is a valid integer (ID)
            if (!isNaN(Number(search)) && x.id !== undefined && x.id.toString().includes(search)) {
                return true;
            }

            const fullName = `${x.surname} ${x.firstname}`.toLowerCase();

            // Check if the search string is included in the full name
            return fullName.includes(searchLower);
        });
    }, [members, role.orgId, search]);

    return <>
        <ItemNavGroup label={role?.orgName}>
            <>
                {loading && <Loading/>}
                <ItemNav
                    align="center"
                    label="Mitglied hinzufügen"
                    icon={faPerson}
                    onClick={() => goto(0)}
                />
                <Item type="full" className={styles.search}>
                    <Input onChange={setSearch} value={search} className={styles.name} placeholder={"Suche nach Name oder Jama-ID"}/>
                </Item>
                {!loading && (filter?.length > 0 ? (
                    <>
                        {filter.slice(0, filterLength).map((m) => (
                            <ItemNav
                                key={`member-${m.id}`}
                                className={classNames(styles.navItem, !m.isActive && styles.itemInactive)}
                                onClick={() => goto(m.id || 0)}
                                selected={id === m.id}
                            >
                                <div className={styles.item}>
                                    <AvatarImage src={MemberService.imageUrl(m.uuid)} className={styles.avatar}/>
                                    <div className={styles.text}>
                                        <div className={styles.nameLicContainer}>
                                            <div className={styles.name}>{m.surname.toUpperCase()} {m.firstname.charAt(0).toUpperCase() + m.firstname.toLowerCase().slice(1)}</div>
                                            <div
                                                className={`${styles.license} ${m?.isActive ? m?.currentLicense ? styles.active : styles.expired : styles.inactive }`}>{m.id}
                                            </div>
                                        </div>
                                        <div className={styles.bottom}>
                                            <div
                                                className={styles.org}>{m.orgId && m.orgId !== role.orgId && profile.organizations.find(x => x.id === m.orgId)?.nameShort}</div>
                                            <div className={styles.birthdate}>{FD(m.birthdate)}</div>
                                        </div>
                                    </div>
                                </div>
                            </ItemNav>
                        ))}
                        {/*{filter.length < members.length && filter.length > filterLength && search.length > 0 && (<Item>Es werden nur die ersten {filterLength} Ergebnise angezeigt</Item>)}*/}
                        <Item>Es werden nur die ersten {filterLength} Ergebnise angezeigt</Item>
                    </>) : <Item type="list" label="Keine Mitglieder gefunden"/>)}
            </>
        </ItemNavGroup>
    </>
}
