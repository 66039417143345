import React, {useState} from "react";
import styles from './Header.module.scss'
import {Button} from "../form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faFilter} from "@fortawesome/free-solid-svg-icons";

interface IHeaderProps {
    label: string;
    back?: () => void;
    filter?: React.ReactElement;
}


export const Header: React.FC<IHeaderProps> = ({label, back, filter}) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <div className={styles.header}>
                <div className={styles.menu}>
                    {back && (<Button onClick={() => back()}><FontAwesomeIcon icon={faArrowLeft}/></Button>)}
                </div>
                <div className='logo-header'/>
                <h1>{label}</h1>
            </div>
            {filter && (<div className={styles.filter}>
                <div  onClick={() => setOpen(!open)} >
                    <FontAwesomeIcon className={styles.filterIcon} icon={faFilter} />
                    <div className={styles.filterText}>Filter</div>
                </div>
                {open && <div className={styles.content}>
                    {filter}
                </div>}
            </div>)}
        </>
    )// todo add some props to filter
}