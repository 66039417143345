import React, { lazy, Suspense }  from 'react';
import preval from 'preval.macro';

import {pdfjs} from 'react-pdf';
import {createRoot} from "react-dom/client";
import './theme/global.scss'

import {Capacitor} from "@capacitor/core";
import {Loading} from "./components/foundation/Loading";

//const AppJudoAustriaMobile = lazy(()=> import("./app_judoaustria_mobile"));
import AppJudoAustriaMobile from './app_judoaustria_mobile'
//const AppJudoAustriaDesktop = lazy(()=> import("./app_judoaustria_desktop"));
import AppJudoAustriaDesktop from './app_judoaustria_desktop'
//const AppArcheryAustriaMobile = lazy(()=> import("./app_archeryaustria_mobile"));
import AppArcheryAustriaMobile from './app_archeryaustria_mobile'
//const AppArcheryAustriaDesktop = lazy(()=> import("./app_archeryaustria_desktop"));
import AppArcheryAustriaDesktop from './app_archeryaustria_desktop'
// import { Socket } from './@api/socket';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// IMPORT EDITION THEME
require(preval`
require('dotenv').config()
module.exports = './theme/editions/' + (process.env.JAMA_EDITION || 'judoaustria') + '/index.scss';
`
);

const edition = preval`
require('dotenv').config();
module.exports = process.env.JAMA_EDITION
`

/*Socket.init(`wss://${process.env.REACT_APP_JAMA_DOMAIN}/api/v2/ws`)
    .then(() => console.log('Socket.io initialized'));*/

const element = document.getElementById("lds-ring");
element?.parentNode?.removeChild(element);

function detectMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}


const container = document.getElementById('root')
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <Suspense fallback={<Loading />}>
      {edition === 'archeryaustria' ?
          (detectMobile() || Capacitor.isNativePlatform() ? <AppArcheryAustriaMobile /> : <AppArcheryAustriaDesktop />)
        :
          (detectMobile() || Capacitor.isNativePlatform() ? <AppJudoAustriaMobile /> : <AppJudoAustriaDesktop />)
      }
    </Suspense>
  </React.StrictMode>
);
