import React, {useEffect, useState} from 'react';

import './AdminTrainerJudoCards.module.scss';

import {IWithFilterProps, withFilter} from "../../../../../api/withFilter";
import {Item, Segment, Select, Input} from '../../../../../components/form';
import {
    ITrainerJudoCard,
    ITrainerJudocardFilter,
    ICompetitionModelV2,
    ICompetitionModel,
    IOrgFunction,
    IUserModel,
    IAge
} from "../../../../../api/models";
import {useParams} from "react-router";
import styles from './AdminTrainerJudoCards.module.scss'
import {
    IFilterOptions,
    MetaService,
    CacheService,
    CompetitionsService,
    JudocardService
} from "../../../../../api";
import {Loading} from "../../../../../components/foundation/Loading";
import PullToRefresh from "react-simple-pull-to-refresh";
import JudoCard from "../../../../../components/JudoCard";


const LOCALSTORAGE_JC_VEREIN = 'trainer_jc_filter_verein';
const LOCALSTORAGE_JC_BARCODE = 'trainer_jc_filter_barcode';
const LOCALSTORAGE_JC_FILTER = 'trainer_jc_filter_filter';

const PAGE_SIZE = 50;


interface ITrainerJudoCardsProps {
    user: IUserModel;
    members: any[];
    competitions: any[];
    filter: ITrainerJudocardFilter;
}

interface ITrainerJudoCardsState {
    user?: IUserModel | null;
    filter: ITrainerJudocardFilter;
    judoCards: ITrainerJudoCard[];
    roleId: number;
    role?: IOrgFunction;
    competitions?: ICompetitionModel[];
    judoregister: string | null,
    ages: IAge[],
    loading: boolean;
}

/*

 */

const TrainerJudoCardsFilter: React.FC<IWithFilterProps<ITrainerJudocardFilter>> = ({filter}) => {
    // console.log(filter)
    // const competitions: ICompetitionModel[] = filter.sub<ICompetitionModel[]>('competitions');
    const [competitions, setCompetitions] = useState<ICompetitionModelV2[]>([])

    useEffect(() => {
        refreshCompetitions()
    }, [filter]);

    const refreshCompetitions = async (): Promise<void> => {
        await MetaService.refresh();
        setCompetitions(await CompetitionsService.getCompetitions());
        // console.log(competitions)
        return;
    }

    return (
        <>
            <Item label="Judocards">
                <Segment
                    onChange={v => filter.useFilter('active', v)}
                    defaultValue={filter.get('active')}
                    options={[{label: 'All', value: false}, {label: 'Active', value: true}]}
                />
            </Item>
            <Item label="Barcode">
                <Segment
                    onChange={v => filter.useFilter('register', v)}
                    defaultValue={filter.get('register')}
                    options={[{label: 'Jama', value: false}, {label: 'Judoreg', value: true}]}
                />
            </Item>
            <Item label="Veranstaltung">
                <Select
                    onChange={v => filter.useFilter('competition', v.value)}
                    defaultValue={filter.get('competition') || 0}
                    options={competitions?.map(c => ({label: c.name, value: c.id}))}
                />
            </Item>
            <Item label="Name">
                <Input
                    onChange={v => filter.useFilter('name', v)}
                    defaultValue={filter.get('name')}
                />
            </Item>
            <Item label="Geschlecht">
                <Segment
                    onChange={v => filter.useFilter('gender', v)}
                    defaultValue={filter.get('gender')}
                    options={[{label: 'Alle', value: null}, {label: 'M', value: 'm'}, {label: 'W', value: 'w'}]}
                />
            </Item>
            <Item label="Alter">
                <Select
                    onChange={v => filter.useFilter('age', v.value)}
                    defaultValue={filter.get('age')}
                    options={MetaService.metaAges.map((x: any) => ({label: x.name, value: x.name}))}
                />
            </Item>
            <Item label="Medical">
                <Segment
                    onChange={v => filter.useFilter('medical', v)}
                    defaultValue={filter.get('medical')}
                    options={[{label: 'Alle', value: null}, {
                        label: 'Ja',
                        value: true
                    }/*, {label: 'Nein', value: false}*/]}
                />
            </Item>
        </>
    );
};

const AdminTrainerJudoCards: React.FC<ITrainerJudoCardsProps> = ({members, user, filter}) => {
    const [judoCards, setJudoCards] = useState<ITrainerJudoCard[]>([])
    const [loading, setLoading] = useState(true)

    const params = useParams()


    const roleId = Number(params['id'] || 0)
    // console.log(roleId, user)
    const role = user?.rolesOrg.map(x => x?.adminOrg || x?.trainerOrg).flat().find(x => x?.funkMgId === roleId) as IOrgFunction;

    // const ages = MetaService.metaAges;

    useEffect(() => {
        refreshJudoCards(filter, true)
    }, [user, filter])

    useEffect(() => {
        setLoading(true)
        refreshJudoCards(filter, true).then(() => {
            setLoading(false);
        }).catch(err => {
            console.error(err);
            setLoading(false);
        })
    }, [filter])

    const sync = (jc: any, evt: any) => {

    }

    // console.log(filter)

    const refreshJudoCards = async (filter: ITrainerJudocardFilter, force: boolean) => {
        console.log("refresh", role, filter)
        setLoading(true);
        if (force) await CacheService.clear();
        if (role != null) {
            setJudoCards(await JudocardService.getTrainerJudocards(role.orgId, filter, 0, 500))
        }
        setLoading(false);
    }


    return (
        <div className={styles.background}>
            {!loading ? <PullToRefresh
                onRefresh={() => refreshJudoCards(filter, true)}
            >
                <>
                    {judoCards?.map(jc => (
                        <JudoCard card={jc} filter={filter} refreshJudoCards={refreshJudoCards}/>
                    ))}
                </>
            </PullToRefresh> : <Loading/>}
        </div>
    );
};

export default withFilter(
    'Judocards',
    {
        sub: [],
        default: {
            active: true,
            age: null,
            medical: null,
            name: null,
            gender: null,
            competition: null,
            register: true,
        }
    } as IFilterOptions<ITrainerJudocardFilter>,
    AdminTrainerJudoCards,
    TrainerJudoCardsFilter,
    -1
);


/*
{showFilter && (
    <IonList className="filter-judocards">
        <IonItem>
            <IonLabel>Judocards</IonLabel>
            <IonSegment
                mode="ios"
                onIonChange={evt => this.selectFilter('active', evt)}
                value={filter.active || 'true'}
            >
                <IonSegmentButton value="false">All</IonSegmentButton>
                <IonSegmentButton value="true">Active</IonSegmentButton>
            </IonSegment>
        </IonItem>
        <IonItem>
            <IonLabel>Barcode </IonLabel>
            <IonSegment
                mode="ios"
                onIonChange={evt => this.selectBarcode(evt)}
                value={judoregister || 'false'}
            >
                <IonSegmentButton value="false">Jama</IonSegmentButton>
                <IonSegmentButton value="true">Judoregister</IonSegmentButton>
            </IonSegment>
        </IonItem>
        <IonItem>
            <IonLabel>Veranstaltung</IonLabel>
            <IonSelect
                onIonChange={(evt) => this.selectFilter('competition', evt)}
                value={filter.competition || 0}
            >
                <IonSelectOption value={0} key={0}>Alle</IonSelectOption>
                {competitions && competitions.map(c => (
                    <IonSelectOption value={c.id} key={c.id}>{c.name}</IonSelectOption>
                ))}
            </IonSelect>
        </IonItem>
        <IonItem>
            <IonLabel>Name</IonLabel>
            <IonInput
                enterkeyhint="search"
                value={filter.name}
                onIonChange={evt => this.selectFilter('name', evt)}
                debounce={1000}
                clearInput={true}
            />
        </IonItem>
        <IonItem>
            <IonLabel>Geschlecht</IonLabel>
            <IonSegment
                mode="ios"
                onIonChange={evt => this.selectFilter('gender', evt)}
                value={filter.gender || 'all'}
            >
                <IonSegmentButton value="all">Alle</IonSegmentButton>
                <IonSegmentButton value="m">M</IonSegmentButton>
                <IonSegmentButton value="w">W</IonSegmentButton>
            </IonSegment>
        </IonItem>
        <IonItem>
            <IonLabel>Medical</IonLabel>
            <IonSegment
                mode="ios"
                onIonChange={evt => this.selectFilter('medical', evt)}
                value={filter.medical || 'all'}
            >
                <IonSegmentButton value="all">Alle</IonSegmentButton>
                {/ *<IonSegmentButton value="false">Nein</IonSegmentButton>* /}
                    <IonSegmentButton value="true">Ja</IonSegmentButton>
                    </IonSegment>
                    </IonItem>
                    <IonItem>
                    <IonLabel>Altersklasse</IonLabel>
                    <IonSelect
                    mode="ios"
                    onIonChange={evt => this.selectFilter('age', evt)}
                    value={filter.age || 'AK'}
                    >
                {ages.map(age => (
                    <IonSelectOption value={age.name} key={age.name}>{age.name}</IonSelectOption>
                    ))}
                    </IonSelect>
                    </IonItem>
                    </IonList>



                    reactivateInfinite: any;

                    constructor(props: ITrainerJudoCardsProps) {
                    super(props);
                    const roleId = Number((props?.match?.params as any)['role'] || 0)
                    if (Number(localStorage.getItem(LOCALSTORAGE_JC_VEREIN)) !== roleId) {
                    localStorage.setItem(LOCALSTORAGE_JC_FILTER, '{"active": "true"}');
                    localStorage.setItem(LOCALSTORAGE_JC_VEREIN, roleId.toString());
                }

                    this.state = {
                    judoCards: [],
                    roleId,
                    judoregister: localStorage.getItem(LOCALSTORAGE_JC_BARCODE),
                    filter: JSON.parse(localStorage.getItem(LOCALSTORAGE_JC_FILTER) || '{}'),
                    showFilter: false,
                    ages: ApiService.metaAges,
                    loading: true,
                }
                }

                    componentDidMount() {
                    // const {filter} = this.state;
                    // this.refreshJudoCards(filter);
                    this.refreshCompetitions();

                }

                    async refreshCompetitions(): Promise<void> {
                    const {filter} = this.state;
                    const competitions = await this.props.api.getCompetitions();
                    const competition = filter.competition;
                    // console.log(competitions)
                    this.setState({
                    competitions: competitions || [],
                });
                    await this.selectFilter('competition', {detail: {value: competition}});
                    await this.refreshJudoCards(filter);

                    return;
                }

                    async refreshJudoCards(filter: ITrainerJudocardFilter) {
                    this.setState({loading: true});
                    const {api} = this.props;
                    const {roleId} = this.state;
                    if (this.reactivateInfinite != null) {
                    this.reactivateInfinite.disabled = false;
                    this.reactivateInfinite = null;
                }
                    const user = await this.props.api.getUser();
                    const role = user?.rolesOrg.map(x => x?.adminOrg || x?.trainerOrg).flat().find(x => x?.funkMgId === roleId) as IOrgFunction;
                    if (role != null) {
                    this.setState({
                    judoCards: await api.getTrainerJudocards(role.orgId, filter),
                    user,
                    role,
                })
                }
                    this.setState({loading: false});
                }

                    async appendJudoCards(evt: any) {
                    console.log('Append Judocards')
                    const {api} = this.props;
                    const {role, judoCards, filter} = this.state;
                    const newCards = await api.getTrainerJudocards(role?.orgId || 0, filter, judoCards.length, PAGE_SIZE);
                    this.setState({
                    judoCards: this.state.judoCards.concat(newCards),
                });
                    if (newCards.length < PAGE_SIZE) {
                    evt.target.disabled = true;
                }
                    evt.target.complete();
                    console.log(evt.target);
                    this.reactivateInfinite = evt.target;
                }

                    async selectFilter(key: string, evt: any) {
                    const {filter} = this.state;

                    // @ts-ignore
                    if (filter[key] !== evt.detail.value) {
                    // @ts-ignore
                    filter[key] = evt.detail.value === 'all' ? undefined : evt.detail.value;
                    localStorage.setItem(LOCALSTORAGE_JC_FILTER, JSON.stringify(filter))
                    this.setState({filter: filter})
                    await this.refreshJudoCards(filter);

                }
                }


                    async selectBarcode(evt: any) {
                    const {judoregister} = this.state;
                    if (judoregister !== evt.detail.value) {
                    localStorage.setItem(LOCALSTORAGE_JC_BARCODE, `${evt.detail.value}`)
                    this.setState({judoregister: evt.detail.value})
                }
                }

                    interface ITrainerJudoCardsState {
                    user?: IUserModel | null;
                    judoCards: ITrainerJudoCard[];
                    roleId: number;
                    role?: IOrgFunction;
                    competitions?: ICompetitionModel[];
                    filter: ITrainerJudocardFilter;
                    showFilter: boolean;
                    judoregister: string | null,
                    ages: IAge[],
                    loading: boolean;
                }
                    async sync(jc: ITrainerJudoCard, evt: any) {
                    const {api} = this.props;
                    const {judoCards} = this.state;
                    this.setState({loading: true});

                    const res = await api.syncJudoregister(jc.jamaId);
                    console.log(res)
                    if (res != null) {
                    jc.extId = res;
                }

                    this.setState({loading: false, judoCards});
                }
                    */