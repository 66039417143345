import React from 'react';
import {IPageProps} from "../../components/page.props";

import {useParamRole} from "../../components/useParam";
import {withDesktop} from "../../api/withDesktop";
import {ProfileTrainer} from "../../components/admin/profile/trainer";
import {Layout} from "../../components/foundation/layout";



const DesktopProfileTrainerPage: React.FC<IPageProps> = (props) => {
    const role = useParamRole(props.user);



    return (
        <Layout page={<ProfileTrainer {...props}  gotoUrl={"/profile"} />} />
    );
};

export default withDesktop(DesktopProfileTrainerPage)