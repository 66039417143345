import React, {useState} from 'react';
import {INewsModel} from '../../../../api/models/NewsModel';

import styles from './PublicNews.module.scss';
import {Card} from '../../../../components/foundation';
import {Thumb} from "../../../../components/form/Thumb";

import {useNavigate} from "react-router-dom";
import {EFileVersion, UtilService} from "../../../../api/util";


interface IPublicNewsProps {
    news?: INewsModel[]
}


const PublicNews: React.FC<IPublicNewsProps> = ({news}) => {
    const navigate = useNavigate()

    const openDetail = (id: number) => {
        navigate(`/news/${id}`)
    }

    return (
        <>
            {news?.map(n => (
                <Card noPadding={true} key={`news-${n.id}`} onClick={() => openDetail(n.id)}>
                    <Thumb
                        title={n.title}
                        subtitle={n.abstract}
                        date={n.publishedAt}
                        image={n.attachedFiles && n.attachedFiles?.length > 0 ? UtilService.getFileUrl(n.attachedFiles[0], EFileVersion.md) : ''}/>
                </Card>
            ))}
        </>
    )
}

export default PublicNews;
