import React, {useEffect, useState} from 'react';
import styles from './Assessment.module.scss';
import {TContentState} from "../../../../components/foundation";

import {Button, Input, Item, Segment, Select} from "../../../../components/form";
import classNames from "classnames";
import moment from "moment";
import {IRouteComponentProps} from "../../../../components/withRouter";
import {IWithFilterProps, withFilter} from "../../../../api/withFilter";

import PullToRefresh from "react-simple-pull-to-refresh";

import {IUserModel} from "../../../../api/models";
import SafetyFirstData from './SafetyFirst.json';
import axios from 'axios';
import {CacheService, GalleryService} from "../../../../api";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareCheck } from '@fortawesome/free-solid-svg-icons';

interface IAssessmentProps extends IRouteComponentProps<void> {
    user: IUserModel;
}

interface IAnswerItem {
    answerItem: string;
    points: number;
}

interface IQuestion {
    id: number;
    question: string;
    questionType: string;
    minPoints: number;
    answerItems: IAnswerItem[];
}

interface ISafetyFirst {
    title: string;
    startdate: string;
    enddate: string;
    status: string;
    minPoints: number;
    questions: IQuestion[];
}

const SafetyFirst: ISafetyFirst = SafetyFirstData;

const AssessmentPage: React.FC<IAssessmentProps> = ({user}) => {
    const [responses, setResponses] = useState<{ [key: string]: string }>({});
    const [loading, setLoading] = useState(true);

    const handleChange = (questionId: string, answerItem: string) => {
        setResponses({
            ...responses,
            [questionId]: answerItem,
        });
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            const response = await axios.post('https://api.example.com/submit', responses);
            console.log('Submission successful:', response.data);
        } catch (error) {
            console.error('Error submitting responses:', error);
        }
    };

    const refresh = async (force: boolean): Promise<void> => {
        setLoading(true);
        if (force) await CacheService.clear();
        //const galleries = await GalleryService.getGalleries();
        //setGalleries(galleries);
        /*this.setState({
            galleries,
            state: 'active',
        });*/
        setLoading(false);
    }

    const renderFormElement = (question: IQuestion) => {
        if (question.questionType === 'singleChoice') {
            return (
                <div key={question.id} className={styles.question}>
                    <p>{question.question}</p>
                    {question.answerItems.map((item, index) => (
                        <div key={index}>
                            <input type="radio" id={`q${question.id}_a${index}`} name={`q${question.id}`}
                                   value={item.answerItem}/>
                            <label htmlFor={`q${question.id}_a${index}`}>{item.answerItem}</label>
                        </div>
                    ))}
                </div>
            );
        } else if (question.questionType === 'multipleChoice') {
            return (
                <div key={question.id} className={styles.question}>
                    <p>{question.id}: {question.question}</p>
                    <select multiple>
                        {question.answerItems.map((item, index) => (
                            <option key={index} value={item.answerItem} className={styles.answerItemMultiSelect}>{item.answerItem}</option>
                        ))}
                    </select>
                </div>
            );
        }
    };

    const onSave = () => {
        console.log('Save');
    };

    return (
        <PullToRefresh onRefresh={() => refresh(true)}>
            <div className={classNames(styles.container, {[styles.loading]: loading})}>
                <h1>{SafetyFirst.title}</h1>
                <form onSubmit={handleSubmit}>
                    {SafetyFirst.questions.map((question: IQuestion) => renderFormElement(question))}
                    <Button type="button" width="full" label="Absenden" onClick={onSave}/>
                </form>
            </div>
        </PullToRefresh>
    );
};
export default withFilter('Assessment', {sub: ['assessment']}, AssessmentPage, /*AssessmentFilter*/ undefined);
