import React, {useEffect, useState} from 'react';

//import {SocialSharing} from '@ionic-native/social-sharing';

import styles from './Tournament.module.scss';


import {TContentState} from "../../../../components/foundation";

import {Input, Item, Segment} from "../../../../components/form";
import classNames from "classnames";
import moment from "moment";
import {IRouteComponentProps} from "../../../../components/withRouter";
import {IWithFilterProps, withFilter} from "../../../../api/withFilter";
import PullToRefresh from "react-simple-pull-to-refresh";

import {useNavigate} from "react-router-dom";
import {IUserModel} from "../../../../api/models";
import {CacheService, CompetitionsService} from "../../../../api";
import {IEvent} from "../../../../api/models/EventArcheryModel";



interface ICompetitions extends IRouteComponentProps<void> {
    user: IUserModel;
    filter: ITournamentFilter;
}

interface ICompetitionsState {
    tournaments?: { [month: string]: ICompetitions[] };
    state: TContentState;
}

interface ITournamentFilter {
    name: string | null;
    range: number;
    type: string | null;
}

const TournamentFilter: React.FC<IWithFilterProps<ITournamentFilter>> = ({filter}) => {
//    console.log(filter)

    return (
        <>
            <Item label="Zeitraum">
                <Segment
                    onChange={v => filter.useFilter('range', v)}
                    defaultValue={filter.get('range')}
                    options={[
                        {label: 'Aktuell', value: 1},
                        {label: 'Ergebnisse', value: 2}
                    ]}
                />
            </Item>
            <Item label="">
                <Segment
                    onChange={v => filter.useFilter('type', v)}
                    defaultValue={filter.get('type')}
                    options={[
                        {label: 'Alle', value: null},
                        {label: 'Breitensp.', value: '1'},
                        {label: 'Leistungssp.', value: '2,3,4,10'}
                    ]}
                />
            </Item>
            <Item label="Suche">
                <Input onChange={v => filter.useFilter('name', v)} defaultValue={filter.get('name')}/>
            </Item>
        </>
    )
}

const TournamentPage: React.FC<ICompetitions> = ({user, filter}) => {
    const navigate = useNavigate();
    const [state, setState] = useState('loading');
    const [tournaments, setTournaments] = useState<{ [mont: string]: IEvent[] } | undefined>()

    useEffect(() => {
        refreshCompetitions(true)
    }, [user, filter])

    const refreshCompetitions = async (force: boolean): Promise<void> => {
        setState('loading');
        if (force) await CacheService.clear();

        const competitions = filter.range === 2 ?
            await CompetitionsService.getCompetitionsV2(1000, filter.name || undefined, filter.type || undefined, moment(Date.now()).subtract(2, 'years').format('YYYY-MM-DD'), moment(Date.now()).format('YYYY-MM-DD')) :
            await CompetitionsService.getCompetitionsV2(1000, filter.name || undefined, filter.type || undefined)

        //console.log(competitions)
        localStorage.setItem('tournaments', JSON.stringify(competitions));
        setTournaments(competitions.reduce((p, c) => {
            const k = moment(c.date_from).format('MMM YYYY');
            if (!p[k]) {
                p[k] = [c]
            } else {
                p[k].push(c);
            }
            return p;
        }, {} as { [mont: string]: IEvent[] }));
        setState('active')
        return;
    }

    const getTournamentState = (c: IEvent): 'open' | 'closed' | 'soon' | 'ending' | 'angem.' | 'wait' => {
        //console.log('enrolled?:', c.enrolled[0])
        const currentDate = moment();
        // TODO if (c.enrolled && (c.enrolled[0]?.enrolledAgeClass?.length ?? 0) > 0) {
        //     return 'angem.';
        // }
        if (new Date() >= new Date(c.NENNSCHLUSS))
            return 'closed';
        if (new Date() <= new Date(c.NENNSCHLUSS) && new Date() > new Date(c.OPENFROM)) // within enrolment reange
        {
            if (currentDate.isBetween(moment(c.NENNSCHLUSS).subtract(7, 'days'), moment(c.NENNSCHLUSS), 'days', '[]')) {
                return 'ending';
            } else {
                return 'open';
            }
        }else {
            if (currentDate.isBetween(moment(c.OPENFROM).subtract(31, 'days'), moment(c.OPENFROM), 'days', '[]'))
                return 'soon';
            else
                return 'wait';
        }
    }

    const enrolledAgeClassesCount = (c: IEvent) => {
        return 0 // TODO c.enrolled?.length || 0;
    }

    const navigateTo = (c: IEvent) => {
        setState('navigate')
        setTimeout(() => navigate(`/event/${c.id}`), 250);
    }

    return (
        <PullToRefresh onRefresh={() => refreshCompetitions(true)}>
            <>

                {tournaments && (filter.range === 2 ?Object.keys(tournaments).reverse() : Object.keys(tournaments)).map(k => (
                    <div className={styles.container} key={`time-${k}`}>
                        <>
                            <div className={styles.datev}>{k}</div>

                            <div className={styles.list}>
                                {( filter.range === 2 ? tournaments[k].sort((a, b) => moment(b.date_from).unix() - moment(a.date_from).unix())  : tournaments[k]).map(c => (
                                    <Item
                                        type="list"
                                        key={`tournament-${c.id}`}
                                        onClick={() => navigateTo(c)}
                                        // TODO className={`${(c.enrolled && (c.enrolled[0]?.enrolledAgeClass?.length ?? 0) > 0) ? styles.meetingEnrolled : ''}`}
                                        >
                                        <div className={`${styles.item}`}>
                                            <div className={styles.date}>
                                                {moment(c.date_from).format('DD')}
                                                <div
                                                    className={classNames(
                                                        styles.state,
                                                        styles[getTournamentState(c).replace(/\.$/, '')],
                                                        // Number(c.meeting_param?.BUNDESWEIT) === 0 ? styles.openBundesweit : undefined
                                                    )}
                                                >
                                                    {getTournamentState(c)}

                                                    {/*{(c?.openFrom && (new Date(c.openFrom) <= new Date())) && (
                                            <span
                                                className={`open${c.meetingParam?.BUNDESWEIT === 0 ? '-bundesweit' : ''}`}>open</span>)}*/}
                                                </div>

                                            </div>

                                            <div className={styles.title}>
                                                <div className={`${styles.meetingTypeName}`}>
                                                    {/*TODO`${c.MEETINGTYPE_NAME} ${enrolledAgeClassesCount(c) ? `[${enrolledAgeClassesCount(c)}x]` : ''}`*/}
                                                </div>
                                                {c.name}<br/><span className={styles.enrolmentdate}>
                                                Anmeldung: {moment(c.NENNSCHLUSS).format('D.M.YY')} &ndash; {moment(c.NENNSCHLUSS).format('D.M.Y')}
                                            </span>
                                            </div>


                                            {/*<div className={styles.arrow}>*/}
                                            {/*    <FontAwesomeIcon icon={faChevronRight}/>*/}
                                            {/*</div>*/}
                                        </div>
                                    </Item>
                                ))}
                            </div>
                        </>
                    </div>
                ))}
            </>
        </PullToRefresh>
    );
}

export default withFilter('Turniere', {
    sub: ['tournaments'], default: {
        range: 1,
        type: null,
        name: ''
    }
}, TournamentPage, TournamentFilter)

