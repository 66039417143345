import {CacheService} from "./cache";
import {LocalService} from "./local";

/**
 * Retrieves the authorization token for the current user.
 *
 * @return {string} The authorization token in the format "Bearer [token]".
 */
export function getAuth() {
    return `Bearer ${LocalService.getAuth()?.jwtToken || 'NONE'}`
}

/**
 * Represents an error that occurs during an API call.
 *
 * @class
 * @extends Error
 */
export class ApiError extends Error {
    constructor(message: string, public result: any) {
        super(message);
    }
}

async function handleError(url: string, ret: any) {
    let result: any;
    try {
        result = await ret.json()
    }
    catch (e) {
        return new ApiError(`Error: GET ${url}`, await ret.text())
    }
    return  new ApiError(`Error: GET ${url}`,result)
}

/**
 * A class that provides methods for making HTTP requests.
 */
export class HttpService {

    /**
     * Sends a GET request to the specified URL and returns the response data.
     *
     * @template T - The type of the response data.
     * @param {string} url - The URL to send the GET request to.
     * @param {boolean} [useAuth=true] - Whether to include authentication headers in the request.
     * @param {boolean} [useCache=true] - Flag to indicate whether to use cached response. Defaults to true.
     * @returns {Promise<T>} - A promise that resolves to the response data.
     * @throws {ApiError} - If the response is not successful.
     */
    public static async get<T>(url: string, useAuth = true, useCache=true): Promise<T> {
        const cache = await CacheService.get(url);
        //console.log(cache, url);
        if (cache != null && useCache) {
            return cache;
        }
        const headers = (useAuth && LocalService.getAuth()?.jwtToken) ? new Headers({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${LocalService.getAuth()?.jwtToken}`,
        }) : new Headers({           'Content-Type': 'application/json',});

        const ret = await fetch(url, {
            method: 'GET',
            headers
        })


        if (ret.ok) {
            const data = ret.json();
            await CacheService.set(url, data);
            return data;
        } else {
            throw await handleError(url, ret);
        }
    }


    /**
     * Sends a POST request to the specified URL with optional request body.
     *
     * @param {string} url - The URL to send the request to.
     * @param {T | null} body - The request body, if any. Defaults to null.
     * @param {boolean} useAuth - Flag to indicate whether to include authentication headers. Defaults to true.
     * @param {boolean} useCache - Flag to indicate whether to use cached response. Defaults to false.
     * @returns {Promise<T>} - A promise that resolves to the response data as type T.
     * @throws {ApiError} - If the POST request fails with an error, an ApiError is thrown with the error response.
     */
    public static async post<T>(url: string, body: T | null = null, useAuth: boolean = true, useCache: boolean = false): Promise<T> {
        if (useCache) {
            const cache = await CacheService.get(url);
            if (cache != null) {
                return cache;
            }
        }
        const headers = (useAuth && LocalService.getAuth()?.jwtToken) ? new Headers({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${LocalService.getAuth()?.jwtToken}`,
        }) : new Headers({           'Content-Type': 'application/json',});


        const ret = await fetch(url, {
            method: 'POST',
            body: body && JSON.stringify(body),
            headers
        })
        if (ret.ok) {

            const data = ret.json();
            if (useCache) await CacheService.set(url, data);
            return data;

        } else {
            throw await handleError(url, ret);
        }
    }

    /**
     * Sends a POST request to the specified URL with optional body, authentication, and caching.
     *
     * @param {string} url - The URL to send the POST request to.
     * @param {T | null} [body] - The optional body to include in the request.
     * @param {boolean} [useAuth=true] - Flag to indicate whether to include authentication headers.
     * @param {boolean} [useCache=false] - Flag to indicate whether to use cached response if available.
     * @returns {Promise<T2>} - A promise that resolves with the response data from the server.
     * @throws {ApiError} - If the server returns an error response.
     */
    public static async post2<T, T2>(url: string, body: T | null = null, useAuth: boolean = true, useCache: boolean = false): Promise<T2> {
        if (useCache) {
            const cache = await CacheService.get(url);
            if (cache != null) {
                return cache;
            }
        }
        const headers = (useAuth && LocalService.getAuth()?.jwtToken) ? new Headers({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${LocalService.getAuth()?.jwtToken}`,
        }) : new Headers({           'Content-Type': 'application/json',});

        const ret = await fetch(url, {
            method: 'POST',
            body: body && JSON.stringify(body),
            headers
        })
        if (ret.ok) {
            const data = ret.json();
            if (useCache) await CacheService.set(url, data);
            return data;
        } else {
            throw await handleError(url, ret);
        }
    }


    /**
     * Sends a PUT request to the specified url with the provided body.
     * @param {string} url - The url to send the PUT request to.
     * @param {T} body - The body of the PUT request.
     * @return {Promise<T>} - A promise that resolves to the result of the PUT request.
     * @throws {ApiError} - If the PUT request fails.
     */
    public static async put<T>(url: string, body: T): Promise<T> {

        const ret = await fetch(url, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: getAuth() === 'NONE' ? new Headers({'Content-Type': 'application/json'}) :  new Headers({
                'Content-Type': 'application/json',
                'Authorization': getAuth(),
            })
        })
        if (ret.ok) {
            return ret.json();
        } else {
            throw await handleError(url, ret);
        }
    }


    /**
     * Sends a PUT request to the specified URL with the provided body and returns the response.
     *
     * @param {string} url - The URL to send the PUT request to.
     * @param {T} body - The body of the request payload.
     * @returns {Promise<T2>} - A promise that resolves to the response of the request.
     * @throws {ApiError} - If the response is not successful.
     */
    static async put2<T, T2>(url: string, body: T): Promise<T2> {
        const ret = await fetch(url, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': getAuth(),
            })
        })
        if (ret.ok) {
            return ret.json();
        } else {
            throw await handleError(url, ret);
        }
    }


    /**
     * Makes a DELETE request to the specified URL with optional authentication headers.
     * @param {string} url - The URL to send the DELETE request to.
     * @returns {Promise<T>} A Promise that resolves to the response body if the request is successful, or throws an ApiError if the request fails.
     * @throws {ApiError} Throws an ApiError if the DELETE request fails.
     */
    public static async delete<T>(url: string): Promise<T> {
        const ret = await fetch(url, {
            method: 'DELETE',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': getAuth(),
            })
        })
        if (ret.ok) {
            return ret.json();
        } else {
            throw await handleError(url, ret);
        }
    }


    /**
     * Sends a binary file to the specified URL using a POST request.
     *
     * @param {string} url - The URL to send the binary file to.
     * @param {FormData} form - The binary file to send.
     * @returns {Promise<Blob>} - A promise that resolves with the response Blob if the request is successful,
     *   otherwise it rejects with an error containing the response JSON.
     * @throws {Error} - If the request fails and returns a response with a non-ok status code, the error will contain the response JSON.
     */
    public static async postBinary(url: string, form: FormData): Promise<Blob> {
        const ret = await fetch(url, {
            method: 'POST',
            body: form,
            headers: new Headers({
                'Authorization': getAuth(),
            })
        })
        if (ret.ok) {
            return ret.blob();
        } else {
            throw await handleError(url, ret);
        }
    }


    /**
     * Sends a binary JSON data to the given URL using the HTTP POST method.
     *
     * @param {string} url - The URL to send the request to.
     * @param {FormData} form - The binary JSON data to send in the request.
     * @return {Promise<T>} - A promise that resolves with the parsed response data if the response is successful,
     *                        or rejects with an error containing the parsed error response data.
     * @throws {Error} - If the response is not successful.
     */
    public static async postBinaryJson<T>(url: string, form: FormData): Promise<T> {
        const ret = await fetch(url, {
            method: 'POST',
            body: form,
            headers: new Headers({
                'Authorization': getAuth(),
            })
        })
        if (ret.ok) {
            return ret.json();
        } else {
            throw await handleError(url, ret)

        }
    }

}