import React from "react";
import {Card, Content, Header} from "../../components/foundation";
import {Link} from "react-router-dom";

export const Forbidden: React.FC = () => {
    return (<>
            <Header label={"Error"}/>
            <Content state={"active"}>
                <Card>

                    <h2>Forbidden</h2>
                   <Link to={"/login"}>
                       <p>Please Log in to continue</p>
                   </Link>
                </Card>
            </Content>
        </>)

}
