import React, {ReactNode} from "react";
import ReactSelect from "react-select";
import {ThemeConfig} from "react-select/dist/declarations/src/theme";


export type SelectRender = (data: { value: any, label: string }) => ReactNode;

interface ISelectProps {
    onChange?: (v: any[]) => any;
    onInputChange?: (v: string) => any;
    defaultValue?: any[];
    value?: any[];
    options: { label: string, value: any }[];
    render?: SelectRender;
    disabled?: boolean;
    input?: string;
    className?: string;
    theme?: ThemeConfig;
    type?: 'number'|'text';
}

export const SelectMulti: React.FC<ISelectProps> = ({
                                                   onChange,
                                                   onInputChange,
                                                   defaultValue,
                                                   input,
                                                   options,
                                                   render,
                                                   disabled,
                                                   value,
                                                   className,
                                                   theme,
                                                   type,
                                               }) => {
    //const fullWidth = true;

    const customStyles = {
        control: (baseStyles: any, {isDisabled}:any) => ({
            ...baseStyles,
            width: '100%',
            minHeight: '3rem',
            height:'auto',
            border: '1px solid var(--gray)',
            backgroundColor: isDisabled ? 'var(--gray-super-light)' : 'var(--primary-color-super-light)',
        }),
        menu: (provided: any) => ({
            ...provided,
            width: 'calc(100vw - 1rem)',  // This line will make the control full width.
            margin: '0',
            //right: '0',
        }),
        multiValue: (provided: any) => ({
            ...provided,
            backgroundColor: 'var(--custom-background-color)', // Custom background color for selected items
            color: 'var(--custom-text-color)', // Custom text color for selected items
        }),
        multiValueLabel: (provided: any) => ({
            ...provided,
            color: 'white',
            backgroundColor: 'var(--primary-color)',// Custom text color for selected items
            fontSize: '1rem',
            padding: '0.25rem 0.25rem',
            minWidth: '4rem',
        }),
        multiValueRemove: (provided: any) => ({
            ...provided,
            color: 'var(--custom-text-color)', // Custom remove icon color for selected items
            ':hover': {
                backgroundColor: 'red', // Custom hover color for the remove icon
                color: 'white', // Custom hover text color for the remove icon
            },
        }),
    }
    //console.log(value)

    return <ReactSelect
        isDisabled={disabled || false}
        options={options}
        defaultValue={defaultValue}
        value={value ? options?.filter(x => type === 'number' ? value.map(y => Number(y)).includes(Number(x.value)) : value.includes(x.value) ) : null}
        onChange={(v) => {
            if (onChange) {
                type === 'number' ? onChange(v.map(x => Number(x.value))) : onChange(v?.map(x => x?.value));
            }
        }}
        onInputChange={onInputChange}
        inputValue={input}
        formatOptionLabel={render}
        styles={customStyles}
        className={className}
        theme={theme}
        isMulti={true}
        //closeMenuOnSelect={false}
    />

}

