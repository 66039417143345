import React from 'react';
import {IMAGE_URL} from "../../../api";
import {useNavigate} from "react-router-dom";
import styles from "./profile.module.scss";
import {Button, DateLabel, Item, Label, PDS} from "../../form";
import CameraButton from "../../foundation/camera_button";
import {ItemNavGroup} from "../../form/ItemNavGroup";
import {IPageLinkProps} from "../../page.props";
import classNames from "classnames";


export const Profile: React.FC<IPageLinkProps> = ({user, profile, gotoUrl, refresh, setRefresh}) => {
    const [localProfile, setLocalProfile] = React.useState(profile || user); // FIXME RK
    //console.log('User:', user);
    //console.log('Profile:', profile);
    //console.log('localProfile:', localProfile);

    const backgroundImage = IMAGE_URL + user?.imageLink + `?v=${Date.now()}`;
    const navigate = useNavigate();

    const checkOpenRequests = localProfile?.trainerLicense.some(license => license.status === "created");

    //console.log('trainerLicense.length', (localProfile?.trainerLicense).length)

    return (
        <div className={styles.headline}>
            <h1 className={styles.name}>{user.title} {user.givenName.charAt(0).toUpperCase() + user.givenName.toLowerCase().slice(1)}  {user.middleName && user.middleName.charAt(0).toUpperCase() + user.middleName.toLowerCase().slice(1)} {user.familyName.toUpperCase()}</h1>
            <div className={styles.profile}>
                <div className={styles.container}>
                <ItemNavGroup label={"Bild"} className={styles.row}>
                    <div className={classNames(styles.picture)}>
                        <div className={styles.frame}>
                            <img src={backgroundImage} alt="Profile"/>
                            {/*<Button className={styles.edit}
                                        onClick={() => navigate('/more/profile/edit')}><FontAwesomeIcon
                                    icon={faEdit}/></Button>*/}
                        </div>
                        <br/>
                        <CameraButton uuid={user.imageLink} className={styles.camera}
                                      onClick={() => window.location.reload()}/>
                        <br/>
                        {
                            ((localProfile?.license?.orgId || 0) > 0 || (user?.license?.orgId || 0) > 0) && !checkOpenRequests
                                ? (
                                    <Button
                                        className={styles.hideOnMobile}
                                        width='full'
                                        label="Trainerlizenz beantragen"
                                        onClick={() => navigate(`${gotoUrl}/trainer`)}
                                    />
                                )
                                : (
                                    <Button
                                        className={styles.hideOnMobile}
                                        width='full'
                                        label="Trainerlizenz in Arbeit"
                                        disabled={true}
                                    />
                                )
                        }
                    </div>
                </ItemNavGroup>
                <ItemNavGroup label={"Stammdaten"} className={styles.row}>
                    <div className={styles.info}>
                        <Item className={styles.infoItem} type="split"
                              label="JAMA-ID."><Label><b>{user.mg_id}</b></Label></Item>
                        <Item className={styles.infoItem} type="split"
                              label="JudoManager-ID."><Label>{user.jr_id ? user.jr_id : '--'}</Label></Item>
                        <Item className={styles.infoItem} type="split"
                              label="Nation."><Label>{user.nationality} / {user.IOC}</Label></Item>
                        <Item className={styles.infoItem} type="split" label="Geb.Dat."><DateLabel
                            value={PDS(user.birthDate)}/></Item>
                        <Item className={styles.infoItem} type="split"
                              label="Adresse"><Label>{user.address}</Label></Item>
                        <Item className={styles.infoItem} type="split" label="PLZ"><Label>{user.zip}</Label></Item>
                        <Item className={styles.infoItem} type="split" label="Ort"><Label>{user.city}</Label></Item>
                        <Item className={styles.infoItem} type="split"
                              label="Land"><Label>{user.countryOfResidence}</Label></Item>
                        <Item className={styles.infoItem} type="split"
                              label="Geschlecht"><Label>{user.gender}</Label></Item>
                        <Item className={styles.infoItem} type="split"
                              label="Mobil"><Label>{user.cellPhone}</Label></Item>
                        {user?.fixedLine && (
                            <Item className={styles.infoItem} type="split"
                                  label="Festnetz"><Label>{user.fixedLine}</Label></Item>)}
                        {user?.companyPhone && (
                            <Item className={styles.infoItem} type="split"
                                  label="Firma"><Label>{user.companyPhone}</Label></Item>)}
                        <Item className={styles.infoItem} type="split" label="Email"><Label>{user.email}</Label></Item>
                        {user?.nationchecked && (
                            <Item className={styles.infoItem} type="split" label="Nat.check"><DateLabel
                                value={PDS(user.nationchecked)}/></Item>)}
                        {user?.medical && (<Item className={styles.infoItem} label="Medical"><DateLabel
                            value={PDS(user.medical)}/></Item>)}
                    </div>
                </ItemNavGroup>
                <ItemNavGroup label={"Prüfungen"} className={`${styles.row} ${styles.pruefungen}`}>
                    <div className={styles.info}>
                        {(user?.allGrades || []).map((item: any) =>
                            (<Item className={styles.infoItem} type="split" key={item?.name} label={item.name}>
                                <Label>
                                    {new Date(item?.datum).toLocaleString('de-DE', {
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit'
                                    })}
                                    {/*<div className={styles.belt}>
                                           <span className={styles.color}
                                                 dangerouslySetInnerHTML={{__html: item?.beltcolor}}/><br/>
                                        <span
                                            className={styles.date}></span>
                                    </div>*/}
                                </Label>
                            </Item>)
                        )}
                    </div>
                </ItemNavGroup>
                {(localProfile?.trainerLicense).length > 0 && (
                    <ItemNavGroup label={"Trainer-Lizenz"} className={`${styles.row} ${styles.trainerlizenz}`}>
                        <div className={styles.info}>
                            {localProfile.trainerLicense.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()).map((license, index) => {
                                //console.log('lizenzstatus:',license.status);
                                let statusText = (license.status === 'rejected' || license.status === 'canceled') ? 'abgelehnt' :
                                    (license.status === 'created' ? 'in Arbeit' :
                                            license.status === 'approved_oejv' ? 'Lizenz eingetragen':
                                                license.status === 'approved_lv' ? 'genehmigt vom Landesverband':
                                                    license.status === 'approved_jv' ? 'genehmigt vom Verein' : 'Fehler!'

                                   );
                                return (
                                    <div key={index} className={`${styles.infoItem} ${index % 2 === 0 ? styles.even : styles.odd} ${license.status === 'rejected' ? styles.rejected : ''}`}>
                                        <Item type="split" label={`Antrag: ${license.orderId}`}>
                                            <Label className={
                                                (license.status === 'rejected' || license.status === 'canceled') ?
                                                    styles.rejected :
                                                    (license.status === 'created' ?
                                                        styles.created : (
                                                            license.status === 'approved_oejv' ? styles.approved :
                                                                license.status.substring(0,8) === 'approved' ? styles.created : ''
                                                        )
                                                    )
                                            }>{statusText}</Label>
                                        </Item>
                                        <Item type="split" label="Organisation">
                                            <Label>{license.orgName}</Label>
                                        </Item>
                                        {license.trainerLicenseName && (<Item type="split" label="LizenzTyp">
                                            <Label>{license.trainerLicenseName}</Label>
                                        </Item>)}
                                        <Item type="split" label="Aktualisiert am">
                                            <Label> {new Date(license.updatedAt).toLocaleString('de-DE')}</Label>
                                        </Item>
                                        <Item type="split" label="Kommentar">
                                            <Label> {license.comment}</Label>
                                        </Item>
                                    </div>
                                )
                            })}
                        </div>
                    </ItemNavGroup>
                )}
                </div>
            </div>
        </div>
    );
};