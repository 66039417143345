import React, {useMemo} from 'react';
import styles from './header_desktop.module.scss'
import {IPageProps} from "../page.props";
import {useNavigate} from "react-router-dom";
import {GroupAdminRoles} from "../../util/roles";
import {Select} from "../form";
import {useParamOrg, useParamRole} from "../useParam";
import {Menu} from "./menu";
import {ProfileService} from "../../api";
import {ReactChildrenType} from "../../core/children";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faIdCard} from "@fortawesome/free-solid-svg-icons";

interface IHeaderDesktopProps extends IPageProps {
    menu: ReactChildrenType;
}


export const HeaderDesktop: React.FC<IHeaderDesktopProps> = ({user, profile, menu}) => {
    const navigate = useNavigate();
    const role = useParamRole(user);
    const org = useParamOrg();
    // const form = useParamForm();
    // console.log(role)
    const adminRoles = useMemo(() => GroupAdminRoles(user), [user]);
    //const trainerRoles = useMemo(() => FlattenTrainerRoles(user), [user]);
    //console.log('adminRoles', adminRoles)
    const orgRoles = useMemo(() => org ? adminRoles[Object.keys(adminRoles).find(x => adminRoles[x].find(y => y.orgId === org)) || ''] : [], [adminRoles, org, user])

    const authorizeRole = async (funkMg: string) => {
        const form = await ProfileService.getForm(org, Number(funkMg));
        navigate(`/${org}/${funkMg}/${form}`);
    }

    //console.log("orgRoles", orgRoles)
    const disabled = orgRoles.length === 0;
    //console.log("disabled", disabled)
    // console.log(org, adminRoles, orgRoles)

    const sortedOptions = Object.values(adminRoles)
        .reduce((acc, roles) => {
            roles.forEach(role => {
                if (!acc.some(r => r.orgId === role.orgId)) {
                    acc.push(role);
                }
            });
            return acc;
        }, [])
        .sort((a, b) => {
            if (a.orgLevel === b.orgLevel) {
                return a.orgName.localeCompare(b.orgName);
            }
            return a.orgLevel - b.orgLevel;
        })
        .map(org => ({
            label: org.orgName + ' / ' + org.orgId ,
            value: org.orgId
        }));

    return (
        <div className={styles.header}>
            {sortedOptions.length > 0 &&
            <div className={styles.item}>
                <Select
                    placeholder={'Wähle deinen Verein/Verband'}
                    type={'number'}
                    value={org}
                    className={styles.select}
                    onChange={(id) => navigate(`/${id}`)}
                    options={sortedOptions}
                />
            </div>
            }
            <div className={styles.item}>
                {!disabled ? (
                <Select
                    placeholder={'wähle Formular'}
                    value={role?.funkMgId}
                    className={styles.select}
                    onChange={authorizeRole}
                    options={orgRoles?.sort((a, b) => {
                        const numA = parseInt(a.functionName.replace(/^F/, ''), 10);
                        const numB = parseInt(b.functionName.replace(/^F/, ''), 10);
                        return numA - numB;
                    }).map(x => ({
                        label: x.functionName,
                        value: x.funkMgId,
                    }))}
                    disabled={disabled}
                />) : null}
            </div>
            {/*{user?.familyName &&*/}
            {/*    <FontAwesomeIcon*/}
            {/*        icon={faIdCard}*/}
            {/*        className={`${styles.judocard} ${user?.lastjudocardyear == moment(new Date()).format('YYYY') ? */}
            {/*            styles.judocardactive : styles.judocardinactive}`}*/}
            {/*    />*/}
            {/*}*/}
             {user?.familyName &&
                 <Menu user={user}>
                    {menu}
                </Menu>
             }
            <div className={styles.logo} />
        </div>
    );
};

/*
[
    {
        label: "Judocards",
        value: EForms.memberships
    }, {
        label: "Mitgliederverwaltung",
            value: EForms.members
    }, {
        label: "Kyu Anmeldungen",
            value: EForms.kyu
    }, {
        label: "JudoCardbestellungen",
            value: EForms.licences
    }, {
        label: "Events",
            value: EForms.events
    }, {
        label: "Organisationen",
            value: EForms.organizations
    }, {
        label: "Trainer",
            value: EForms.trainer
    }
]
*/