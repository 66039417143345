import React from 'react';
import {IPageLinkProps} from "../../page.props";
import {useSearchParams} from "react-router-dom";
import {EventDetailEdit} from "./event_detail_edit";
import {EventDetailCandidates} from "./event_detail_candidates";
import {EventDetailRoles} from "./event_detail_roles";
import styles from './event_detail_candidates.module.scss'
import {useParamRole} from "../../useParam";
import { Link } from 'react-router-dom';

export const EventDetail: React.FC<IPageLinkProps> = (props) => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get('t');
    const role = useParamRole(props.user);
    const gotoUrl = `/${role?.orgId}/${role?.funkMgId}/events`

    switch (page) {
        case 'edit':
            return <EventDetailEdit {...props} />
        case 'roles':
            return <EventDetailRoles {...props} />
        case 'candidates':
            return <EventDetailCandidates {...props} />
        default:
            return <div className={styles.blankpage}>Wähle links einen Event aus oder erstelle <Link to={`${gotoUrl}?t=edit`}> hier einen
                neuen Event</Link>.</div>

    }
};