import {
    IMeeting,
    IMeetingCandidate,
    IMeetingCandidateParams,
    IOrgFunction,
    IUserModel
} from "../../../../../../api/models";
import {useParams} from "react-router";
import React, {useEffect, useMemo, useState} from "react";
import {CacheService} from "../../../../../../api";
import {MeetingService} from "../../../../../../api/meeting";
import {toaster, toastError} from "../../../../../../core/toaster";
import {ApiError} from "../../../../../../api/http";
import { ItemNavGroup } from "../../../../../../components/form/ItemNavGroup";
import {withFilter} from "../../../../../../api/withFilter";
import PullToRefresh from "react-simple-pull-to-refresh";
import styles from './AdminOrgMeetingCandidates.module.scss'
import {Loading} from "../../../../../../components/foundation/Loading";
import {FD, Input, Item, Segment} from "../../../../../../components/form";
import Toggle from "../../../../../../components/form/Toggle";
import classNames from "classnames";
import {tr} from "date-fns/locale";


interface IOrgMeetingCandidatesProps {
    user: IUserModel;
}


const OrganizationMetingCandidates: React.FC<IOrgMeetingCandidatesProps> = ({user}) => {
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [candidates, setCandidates] = useState<IMeetingCandidate[]>([])
    const [meeting, setMeeting] = useState<IMeeting|null>(null)
    const [search, setSearch] = useState<string>('')
    const [filterState, setFilterState] = useState<number>(1)

    const roleId = Number(params['role'] || 0);
    const meetingId = Number(params['id'] || 0);
    const typeId = Number(params['type'] || 0);
    const role = user?.rolesOrg.map(x => x?.adminOrg || x?.trainerOrg).flat().find(x => x?.funkMgId === roleId) as IOrgFunction;

    const refresh = async (force: boolean) => {
        if (role != null) {
            if (force) await CacheService.clear();
            try {
                const candidates = await MeetingService.candidates(role?.orgId, meetingId, typeId);
                setCandidates(candidates);
            } catch (e) {
                console.log(e)
                toastError((e as ApiError)?.result?.error)
            }
        }
    }

    useEffect(() => {
        setLoading(true);
        refresh(true).then(() => setLoading(false));
    }, [user]);

    useEffect(() => {
        if ( role?.orgId) {
            MeetingService.get(role.orgId, meetingId).then(setMeeting)
        }
    }, [role, meetingId]);


    const toggleCandidate = async (candidate: IMeetingCandidate) => {
        setLoading(true);
        const hasChangeParams = (candidate.params as IMeetingCandidateParams[]).find(x =>true);
        const person = `${candidate.surname} ${candidate.firstname}`
        toaster(async () => {
            await MeetingService.updateCandidate(
                role?.orgId,
                Number(meetingId),
                hasChangeParams?.meeting_mg_id || 0,
                candidate.mgId,
                typeId,
                !!hasChangeParams
            );
            await refresh(true);
        }, {success: !!hasChangeParams ? `${person} entfernt` : `${person} hinzugefügt` , failure: "Fehlgeschlagen"}, () => {
            setLoading(false)
        })
    }

    const filter = useMemo(() => {
        return candidates.filter(x =>
            (x.firstname?.toLowerCase()?.includes(search?.toLowerCase()) ||
            x.surname?.toLowerCase()?.includes(search?.toLowerCase()) ||
            `${x.mgId}`.includes(search)) && (filterState === 0 || (filterState === 1 ? (x.params as IMeetingCandidateParams[])?.length > 0  : filterState ===2 ? (x.params as IMeetingCandidateParams[])?.length  === 0 && (x.outError?.length || 0) ===0 : (x.outError?.length || 0) ===0  )) )
    }, [candidates, filterState, search]);

    return <>

        {loading && <div className={styles.loading}><Loading/></div> }

        <ItemNavGroup label={`${meeting?.name||'Ohne Namen'}`}>
            <PullToRefresh onRefresh={() => refresh(true)} onFetchMore={async () => {
                console.log('more')
            }}>
                <>
                    <Item type="form" className={styles.search}>
                        <Input onChange={setSearch} className={styles.name} />
                        {/*<Toggle className={styles.toggle} options={[ 'alle', 'zugeordnet']} value={filterState} onChange={setFilterState} />*/}
                    </Item>

                   <Segment onChange={()=>null} options={[{label:'Selektion', value: 1}, {label: 'Einladung', value: 2}, {label: 'Anwesenheit', value: 3}]} />

                    {candidates?.length > 0 ? filter.map(c => (
                        <Item
                            className={classNames(
                                styles.candidate,
                                (c.params as IMeetingCandidateParams[])?.length > 0 && styles.active,
                                c.judocard == null && styles.inactive,
                            )}
                            key={`candidate-${c.mgId}`}
                            onClick={ () => toggleCandidate(c)}
                        >
                            <>
                                <div className={styles.header}>
                                    {c.surname} {c.firstname} ({c.mgId})
                                </div>
                                <div className={styles.info}>
                                    <div className={styles.name}>
                                        <strong>Geb.: {FD(c.birthdate)}</strong><br/>
                                        {c.role}
                                    </div>


                                    <div className={styles.belt}>

                                        <div dangerouslySetInnerHTML={{__html: c.beltsvg}}/>
                                        <div dangerouslySetInnerHTML={{__html: c.nextBeltSvg || ''}}/>
                                        {/*c.trainingBelt} {c.traindingGrade} { (exam?.reservation || 0) === 1 ? <>-&gt; {c.nextName}</> : ''*/}
                                        <div className={styles.licence}>{c.judocard || 'Keine JC'}</div>
                                    </div>


                                </div>
                                {/*<div className={styles.select}>
                                         <Segment onChange={() => null} defaultValue={false} options={[
                                         {value: false, label: 'Frei'},
                                         {value: true, label: 'Angemeldet'},
                                       ]} />
                                    </div>*/}
                                {((c.outError?.length || 0) > 0) && ( <div className={styles.error} >
                                    {c?.outError?.map(x => x.message).join(' | ')}
                                </div>)}
                            </>
                        </Item>


                    )) : <p>Keine Kandidaten verfügbar</p>}
                </>
            </PullToRefresh>
        </ItemNavGroup>

    </>
}

export default withFilter('Event', {}, OrganizationMetingCandidates,undefined, -1);