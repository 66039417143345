import React from "react";
import styles from './Card.module.scss'
import classNames from "classnames";
import {ReactChildrenType} from "../../core/children";
interface ICardProps {
    children: ReactChildrenType;
    onClick?: () => void;
    noPadding?: boolean;
    className?: string
}


export const Card: React.FC<ICardProps> = ({children, noPadding, onClick, className}) => {

    return (
        <div className={classNames(styles.card, noPadding ? undefined : styles.padding, className)} onClick={onClick}>
            {children}
        </div>
    )
}