// JUDOCARD FOR OEJV

import React, {useState} from 'react';
import styles from './Card.module.scss';
import {withFilter} from "../../../api/withFilter";
import {IUserModel} from "../../../api/models/UserModel";
import classNames from "classnames";
import {IMAGE_URL} from "../../../api";
import PullToRefresh from "react-simple-pull-to-refresh";
import {FD} from "../../../components/form";

interface IJudoCard {
    user: IUserModel;
    refresh: () => Promise<void>;
}


const JudoCard: React.FC<IJudoCard> = ({user, refresh}) => {
    const [showJama, setShowJama] = useState(false);
    const backgroundImage = IMAGE_URL + user?.imageLink + `?v=${Date.now()}`;
    // const showJama = this.state.showJama;

    const lastJudocardHexColor = {
        color: user?.lastjudocardyearcolor
    };

    return (
        <PullToRefresh onRefresh={() => refresh && refresh()}>
            <div className={styles.judo}>
                <div className={styles.judoCard}>
                    {user &&
                        <div className={styles.container}>
                            <div className={styles.title}>
                                <h1>
                                    JUDOCARD <span style={lastJudocardHexColor}>{user.lastjudocardyear}</span>
                                </h1>
                                <span className={styles.logo}/>
                            </div>
                            <div>
                                <h1 className={styles.name}>{user.title} {user.familyName} {user.givenName}</h1>
                            </div>
                            <div>
                                <label className={styles.birthdate}>{FD(user.birthDate)}<span
                                    className={classNames(styles.gender, user.gender === 'm' ? styles.male : (user.gender === 'w') ? styles.femal : styles.genderx)}>{user.gender === 'm' ? '\u2642' : (user.gender === 'w') ? '\u2640' : '\u2613'}</span></label>
                            </div>
                            {user.maxgrade && (
                                <div className={styles.beltContainer}>
                                    <label>{user.maxgrade} <span className={styles.belt}
                                                                 dangerouslySetInnerHTML={{__html: user.belt}}/></label>
                                </div>
                            )}
                            <div>
                                {(user.membershiplicenses?.length || 0) === 0 && (<label>{user.clubname}</label>)}
                                {user.membershiplicenses?.map((membership: any, i: number) => (
                                    <span
                                        key={`ms_${membership.orgId}`}>{membership.orgName}{i !== user?.membershiplicenses?.length - 1 && (" | ")}</span>
                                ))}
                            </div>
                            <div>
                                <label>Nationalität: {user.nationality}</label>
                            </div>
                            <div className={styles.barcodeContainer} onClick={() => setShowJama(!showJama)}>
                                <div className={classNames(user.medical ? styles.plus : styles.plusPlaceholder)}/>
                                {showJama &&
                                    <div className={styles.barcode} dangerouslySetInnerHTML={{__html: user.jama_id}}/>
                                }
                                {
                                    !showJama &&
                                    <div className={styles.barcode} dangerouslySetInnerHTML={{__html: user.ext_id}}/>
                                }
                            </div>
                            <div className={styles.barcode}/>
                            <div className={styles.profile} style={{backgroundImage: `url('${backgroundImage}')`}}/>
                            <div className={styles.sponsor}/>
                        </div>
                    }
                </div>
            </div>
        </PullToRefresh>
    );
}

export default withFilter('Judocard', {}, JudoCard);

/*
<div className="grades">
    {
        user.gradecolor.map((grade, i) => {
            return <span key={"grade" + i} className="grade" style={{backgroundColor: grade}}/>
        })
    }

</div>
 */
