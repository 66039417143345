import {Constants} from "./constants";
import {HttpService} from "./http";
import {AuthService} from "./auth";
import {IKyuCandidate, IMeeting, IMeetingCandidate, IMeetingCandidateParams} from "./models";
import {CacheService} from "./cache";


const GET_MEETINGS = `${Constants.JAMA_SERVER_API_V2}/admin/:org/meetings`;
const GET_MEETING = `${Constants.JAMA_SERVER_API_V2}/admin/:org/meeting/`;
const GET_MEETING_CANDIDATES = `${Constants.JAMA_SERVER_API_V2}/admin/:org/meeting/candidates/`;

const POST_MEETING = `${Constants.JAMA_SERVER_API_V2}/admin/:org/meeting`;
const PUT_MEETING = `${Constants.JAMA_SERVER_API_V2}/admin/:org/meeting`;
const PUT_MEETING_CANDIDATE = `${Constants.JAMA_SERVER_API_V2}/admin/:org/meeting/candidates`;


/**
 * A class that provides methods for interacting with Kyu exams and candidates.
 */
export class MeetingService {
    /**
     * Retrieves the kyu exams for a given organization.
     *
     * @param org - The organization number.
     *
     * @returns A Promise that resolves to an array of IKyuExam objects.
     */
    public static async all(org: number): Promise<IMeeting[]> {
        await AuthService.refreshJwt();
        const res = await HttpService.get<IMeeting[]>(`${GET_MEETINGS}`.replace('/:org/', `/${org}/`), true);
        return res || [];
    }


    public static async get(org: number, id: number): Promise<IMeeting> {
        await AuthService.refreshJwt();
        const res = await HttpService.get<IMeeting>(`${GET_MEETING}${id}`.replace('/:org/', `/${org}/`), true);
        return res || []
    }

    public static async candidates(org: number, id: number, type: number, forceRefresh: boolean = false): Promise<IMeetingCandidate[]> {
        console.log('candidates function called with:', { org, id, type, forceRefresh });

        let url = `${GET_MEETING_CANDIDATES}${id}/${type}`.replace('/:org/', `/${org}/`);
        if (forceRefresh) {
            url += `${url.includes('?') ? '&' : '?'}refresh=${Date.now()}`;
        }

        const res = await HttpService.get<IMeetingCandidate[]>(url, true);
        return res || [];
    }

    public static async updateCandidate(
        org: number,
        meetingId: number,
        assignedMemberId: number,
        mgId: number,
        meetingRole: number,
        remove: boolean,
        status?: string,
        params?: IMeetingCandidateParams
    ): Promise<boolean> {
        await AuthService.refreshJwt();
        const res = await HttpService.put2<{
            id: number,
            mgId: number,
            meetingRole: number
            assignedMgId: number,
            remove: boolean,
            status?: string,
            params?: IMeetingCandidateParams
        }, boolean>(PUT_MEETING_CANDIDATE.replace('/:org/', `/${org}/`), {
            id: meetingId,
            mgId,
            meetingRole,
            assignedMgId: assignedMemberId,
            remove,
            status,
            params
        });
        await CacheService.clear()
        return res || false;
    }

    public static async create(org: number, data: IMeeting) {
        await AuthService.refreshJwt();
        await CacheService.clear()
        return await HttpService.post2<IMeeting, number>(POST_MEETING.replace('/:org/', `/${org}/`), data);
    }

    public static async update(org: number, data: IMeeting) {
        await AuthService.refreshJwt();
        await CacheService.clear()
        return await HttpService.put2<IMeeting, number>(PUT_MEETING.replace('/:org/', `/${org}/`), data);
    }
    // /**
    //  * Updates the specified data using the POST_KYU_EXAM endpoint.
    //  *
    //  * @param {number} org - The organization number.
    //  * @param {IHandleAdminKyuUpdateReq} data - The data to be updated.
    //  *
    //  * @return {Promise<boolean>} - A Promise that resolves to a boolean indicating the success of the update operation.
    //  */
    // public static async update(org: number, data: IHandleAdminKyuUpdateReq) {
    //     await AuthService.refreshJwt();
    //     await HttpService.put2<IHandleAdminKyuCreateReq, boolean>(PUT_KYU_EXAM.replace('/:org/', `/${org}/`), data);
    //     return data.id
    // }
    //
    // /**
    //  * Retrieves a list of chair members for a Kyu exam.
    //  *
    //  * @param {number} org - The organization ID.
    //  * @param {IKyuListChairMembersReq} data - The request data.
    //  * @property {string} data.orgId - The organization ID.
    //  * @property {string} data.date - The exam date.
    //  * @property {string} data.search - The search value.
    //  *
    //  * @returns {Promise<IKyuCandidate[]>} A promise that resolves with an array of Kyu candidates.
    //  */
    // public static async listChairMembers(org: number, data: IKyuListChairMembersReq) {
    //     await AuthService.refreshJwt();
    //     return await HttpService.get<IKyuChairMember[]>(
    //         `${GET_KYU_EXAM_CHAIR_MEMBERS.replace('/:org/', `/${data.orgId}/`)}/${data.date}${(data.search?.length || 0) > 0 ? `/${data.search}` : ''}`);
    // }
    //
    // public static async getChairMember(orgId: number, date: string,  id: number) {
    //     await AuthService.refreshJwt();
    //     return await HttpService.get<IKyuChairMember>(
    //         `${GET_KYU_EXAM_CHAIR_MEMBER.replace('/:org/', `/${orgId}/`)}/${date}/${id}`);
    // }
    //
    //
    // public static async upgrade(org: number, id: number) {
    //     await AuthService.refreshJwt();
    //     return await HttpService.put<{ }>(
    //         PUT_KYU_EXAM_UPGRADE.replace('/:org/', `/${org}/`).replace('/:id/', `/${id}/`),{}
    //     )
    // }
}