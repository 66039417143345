import React from "react";
import styles from './Pin.module.scss'

interface IPinProps {
    label: string;
    name: string;
    placeholder: string;
    disabled?: boolean;
    value?: string;
    onChange?: (e:any) => void;
    keyBoardType: string;
}


export const Pin: React.FC<IPinProps> = ({
    name,
    label,
    placeholder,
    disabled,
    value,
    onChange,
    keyBoardType,
}) => {

    return (
        <div className={styles.pin}>
            <label>{label}</label>
            <input
                type={keyBoardType || "text"}
                name={name}
                placeholder={placeholder}
                disabled={disabled}
                value={value}
                onChange={onChange}
                inputMode="numeric"
            />
        </div>
    )
}