import React, {useState} from 'react';
import {IPageProps} from "../../components/page.props";

import {useParamRole} from "../../components/useParam";
import {withDesktop} from "../../api/withDesktop";
import {Profile} from "../../components/admin/profile/profile";
import {Layout} from "../../components/foundation/layout";


const DesktopProfilePage: React.FC<IPageProps> = (props) => {
    const role = useParamRole(props.user);
    const gotoUrl = `/profile`;
    const [refresh, setRefresh] = useState<number>(0)

    return (
        <Layout page={<Profile {...props} gotoUrl={gotoUrl} refresh={refresh} setRefresh={setRefresh} />} />
    );
};

export default withDesktop(DesktopProfilePage);