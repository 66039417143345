import React, {useEffect, useState} from 'react';
import {IAdminOrganization, IUserModel, IUserProfile} from "../../../../../../api/models";
import {withFilter} from "../../../../../../api/withFilter";
import {ItemNavGroup} from "../../../../../../components/form/ItemNavGroup";
import {Button, DateInput, DTS, Input, Item, Label, PDS, Segment} from "../../../../../../components/form";
import {useParamId, useParamRole} from "../../../../../../components/useParam";
import {toaster} from "../../../../../../core/toaster";
import {OrganizationService} from "../../../../../../api/organization";
import {Loading} from "../../../../../../components/foundation/Loading";
import {useNavigate} from "react-router-dom";

interface IAdminOrgOrganizationEditProps {
    user: IUserModel;
    profile: IUserProfile;
}

const AdminOrgOrganizationEdit: React.FC<IAdminOrgOrganizationEditProps> = ({user, profile}) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [start, setStart] = useState<Date | null>(null);
    const [end, setEnd] = useState<Date | null>(null);
    const [zvr, setZvr] = useState<number | null>(null);
    const [dvr, setDvr] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [short, setShort] = useState<string>("");
    const [street, setStreet] = useState<string>("");
    const [houseNumber, setHouseNumber] = useState<string>("");
    const [zip, setZip] = useState<number | null>(null);
    const [city, setCity] = useState<string>("");
    const [dachverband, setDachverband] = useState<string>("");
    const [altAddress, setAltAddress] = useState<string>("");
    const [altStreet, setAltStreet] = useState<string>("");
    const [altHouseNumber, setAltHouseNumber] = useState<string>("");
    const [altZip, setAltZip] = useState<number>();
    const [altCity, setAltCity] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [homepage, setHomepage] = useState<string>("");
    const [phoneOffice, setPhoneOffice] = useState<string>("");
    const [faxOffice, setFaxOffice] = useState<string>("");
    const [mbnZeile1, setMbnZeile1] = useState<string>("");
    const [mbnZeile2, setMbnZeile2] = useState<string>("");
    const [judopass, setJudopass] = useState<boolean>(false);
    const role = useParamRole(user);
    const id = useParamId();

    useEffect(() => {
        if (id) {
            toaster(async () => {
                setLoading(true)
                const org = await OrganizationService.get(role.orgId, id || role.orgId);
                setStart(PDS(org.start));
                setEnd(PDS(org.end));
                setZvr(org.zvr);
                setDvr(org.dvr || '');
                setName(org.name);
                setShort(org.short);
                setStreet(org.street);
                setHouseNumber(org.houseNumber);
                setZip(org.zip);
                setCity(org.city);
                setDachverband(org.dachverband);
                setAltAddress(org.altAddress || '');
                setAltStreet(org.altStreet || '');
                setAltHouseNumber(org.altHouseNumber || '');
                setAltZip(org.altZip);
                setAltCity(org.altCity || '');
                setEmail(org.email);
                setHomepage(org.homepage || '');
                setPhoneOffice(org.phoneOffice || '');
                setFaxOffice(org.faxOffice || '');
                setMbnZeile1(org.mbnZeile1 || '');
                setMbnZeile2(org.mbnZeile2 || '');
                setJudopass(org.judopass);
            }, {}, () => {
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, [role, id]);

    const onSave = () => {
        toaster(async () => {
            setLoading(true);
            const data =  {
                id,
                start: DTS(start || undefined),
                end: DTS(end || undefined),
                zvr,
                dvr,
                name,
                short,
                street,
                houseNumber,
                zip,
                city,
                dachverband,
                altAddress,
                altStreet,
                altHouseNumber,
                altZip,
                altCity,
                email,
                homepage,
                phoneOffice,
                faxOffice,
                mbnZeile1: mbnZeile1,
                mbnZeile2: mbnZeile2,
                judopass
            } as IAdminOrganization


            if (id) {
                await OrganizationService.update(role.orgId, id || role.orgId,data);
            } else {
                const newOrg = await OrganizationService.create(role.orgId, data);
                navigate(`more/organization/edit/${role.funkMgId}/${newOrg}`)
            }

        }, {}, () => {
            setLoading(false)
        })
    }

    return (
        <>
            {loading ? <Loading/> : (<>
                <ItemNavGroup label={`Stammdaten | ${short}`}>
                    <Item type="list" label="Organizations Id">
                        <Label>{id||''}</Label>
                    </Item>
                    <Item type="full" label="Gruendungsdatum">
                        <DateInput value={start} onChange={setStart} required/>
                    </Item>
                    <Item type="full" label="Vereinsende">
                        <DateInput value={end} onChange={setEnd}/>
                    </Item>
                    <Item type="full" label="ZVR Nummer">
                        <Input type="number" value={zvr} onChange={setZvr} required/>
                    </Item>
                    <Item type="full" label="DVR Nummer">
                        <Input value={dvr} onChange={setDvr}/>
                    </Item>
                    <Item type="full" label="Name">
                        <Input value={name} onChange={setName} required/>
                    </Item>
                    <Item type="full" label="Kurzbezeichnung">
                        <Input value={short} onChange={setShort}/>
                    </Item>
                    <Item type="full" label="Strasse">
                        <Input value={street} onChange={setStreet}/>
                    </Item>
                    <Item type="full" label="Hausnummer">
                        <Input value={houseNumber} onChange={setHouseNumber} required/>
                    </Item>
                    <Item type="full" label="PLZ">
                        <Input type="number" value={zip} onChange={setZip} required/>
                    </Item>
                    <Item type="full" label="Ort">
                        <Input value={city} onChange={setCity}/>
                    </Item>
                    <Item type="full" label="Dachverband">
                        <Input value={dachverband} onChange={setDachverband}/>
                    </Item>
                </ItemNavGroup>
                <ItemNavGroup label={`Optionale Zustelladresse`}>
                    <Item type="full" label="Name Postempfaenger (c/o)">
                        <Input value={altAddress} onChange={setAltAddress}/>
                    </Item>
                    <Item type="full" label="Strasse">
                        <Input value={altStreet} onChange={setAltStreet}/>
                    </Item>
                    <Item type="full" label="Hausnummer">
                        <Input value={altHouseNumber} onChange={setAltHouseNumber}/>
                    </Item>
                    <Item type="full" label="PLZ">
                        <Input type="number" value={altZip} onChange={setAltZip}/>
                    </Item>
                    <Item type="full" label="Ort">
                        <Input value={altCity} onChange={setAltCity}/>
                    </Item>
                </ItemNavGroup>
                <ItemNavGroup label={`Kontaktdaten`}>
                    <Item type="full" label="Email">
                        <Input value={email} onChange={setEmail}/>
                    </Item>
                    <Item type="full" label="Homepage">
                        <Input value={homepage} onChange={setHomepage}/>
                    </Item>
                    <Item type="full" label="Telefon(Buero)">
                        <Input value={phoneOffice} onChange={setPhoneOffice}/>
                    </Item>
                    <Item type="full" label="Fax(Buero)">
                        <Input value={faxOffice} onChange={setFaxOffice}/>
                    </Item>
                </ItemNavGroup>
                <ItemNavGroup label={`Rueckennummer`}>
                    <Item type="full" label="1.Zeile (max 12 Zeichen, gibt der LV ein)">
                        <Input value={mbnZeile1} onChange={setMbnZeile1}/>
                    </Item>
                    <Item type="full" label="2.Zeile (max 12 Zeichen, gibt der LV ein)">
                        <Input value={mbnZeile2} onChange={setMbnZeile2}/>
                    </Item>
                </ItemNavGroup>
                <ItemNavGroup label={`Einstellungen`}>
                    <Item type="full" label="Judopass">
                        <Segment value={judopass} onChange={setJudopass}
                                 options={[{label: 'Nein', value: false}, {label: 'Ja', value: true}]}/>
                    </Item>
                </ItemNavGroup>
                <Button type="button" width="full" label="Speichern" onClick={onSave}/>
            </>)}
        </>
    )
};

export default withFilter('Org', {}, AdminOrgOrganizationEdit, undefined, -1);