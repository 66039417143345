import React, {MouseEvent} from "react";
import styles from './menuItem.module.scss'
import {Link} from "react-router-dom";
import {Button} from "../form";

interface IMenuItemProps {
    label?: string;
    to?: string;
    onClick?: (evt: MouseEvent<HTMLButtonElement>) => void;
    version?: string;
    user?: string;
    children?: React.ReactNode
    judocard?: string;
}


export const MenuItem: React.FC<IMenuItemProps> = ({label, to, onClick, version, user, judocard}) => {

    return (
        <div className={styles.menuitem}>
            <div>{user && <div className={styles.user}>{user}</div>}</div>
            {onClick ?
                <Button onClick={onClick} label={label} className={styles.button}/>
                :
                <Link to={to || '/'} className={styles.link}>{label}</Link>
            }
            <div>{judocard && <div className={styles.judocard}>{judocard}</div>}</div>
            <div>{version && <div className={styles.version}>{version}</div>}</div>
        </div>

    )
}