import React, {useState} from 'react';
import {useParamId, useParamRole} from "../../../../../components/useParam";
import {LicenceDetail} from "../../../../../components/admin/licences";
import {IPageProps} from "../../../../../components/page.props";
import Licence from "../../../../../components/admin/licences/licence";
import {withFilter} from "../../../../../api/withFilter";


const AppAdminLicences: React.FC<IPageProps> = (props) => {
    const role = useParamRole(props.user);
    const gotoUrl = `/more/admin/${role?.funkMgId}/licences`;
    const [refresh, setRefresh] = useState<number>(0);

    const id = useParamId();

    return id == null ? <Licence {...props} gotoUrl={gotoUrl} refresh={refresh}  />
        : <LicenceDetail {...props} gotoUrl={gotoUrl} refresh={refresh} setRefresh={setRefresh} />
};

export default withFilter('Judocard', {}, AppAdminLicences, undefined, -1);