import React, {MouseEvent} from "react";
import styles from './Button.module.scss'
import classNames from "classnames";

interface IButtonProps {
    label?: string;
    children?: React.ReactElement;
    width?: 'standard' | 'full';
    disabled?: boolean;
    onClick?: (evt: MouseEvent<HTMLButtonElement>) => void;
    className?: string;
    type?: 'button' | 'submit'
    color?: 'primary' | 'success' | 'light'
}

export const Button: React.FC<IButtonProps> = ({
                                                   label,
                                                   children,
                                                   disabled,
                                                   onClick,
                                                   className,
                                                   type,
                                                   width,
                                                   color,
                                               }) => {

    return (
        <>
            <button
                name={'type'}
                onClick={(evt) => onClick && onClick(evt)}
                type={type || 'button'}
                className={classNames(styles.input, className, width === 'full' && styles.full, styles[color || 'primary'])}
                disabled={disabled}
            >
                {children || label}
            </button>
        </>
    )
}