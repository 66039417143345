import React, {useEffect, useState} from "react";
import {Label, Item, DateLabel, Button, PDS} from '../../../../components/form';
import {useParams} from "react-router";
import {MapContainer, Marker, Popup, TileLayer, ZoomControl} from 'react-leaflet';
import L from 'leaflet';
import styles from './TournamentDetail.module.scss';
import marker from '../../../../theme/images/marker.svg';
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShare} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import {Share} from '@capacitor/share'
import {withFilter} from "../../../../api/withFilter";
import {CompetitionsService, MetaService} from "../../../../api";
import {IEventDetail, IEventMetaJudo} from "../../../../api/models/EventModel";


interface ITournamentDetailProps {

}

const TournamentDetail: React.FC<ITournamentDetailProps> = ({}) => {
    const {id} = useParams<{ id: string }>();
    const [event, setEvent] = useState<IEventDetail<IEventMetaJudo>>();
    const navigate = useNavigate();

    // const tournaments: ICompetitionModelV2[] = JSON.parse(localStorage.getItem('tournaments') || '{}') as unknown as ICompetitionModelV2[];
    // const tournament: ICompetitionModelV2 | undefined = preview || tournaments.find(x => x.id === Number(route.id));
    useEffect(() => {
        CompetitionsService.getEvent<IEventMetaJudo>(Number(id) || 0).then((event) => {
            setEvent(event)
        })
    }, []);


    const openPdf = (id: number, uuid: string) => {
        navigate(`/event/pdf/${id}/${uuid}`);
        //console.log('argh')
        /*this.setState({
            pdf: {meetingId, uid},
        });*/
    }

    const sharePdf = async (name: string, meetingId: string, uid: string): Promise<void> => {
        await Share.share({
            title: name,
            url: MetaService.resolveCompetitionFileUrl(meetingId, uid)
        });
    }

    const sharePdf2 = async (name: string, url: string): Promise<void> => {
        await Share.share({
            title: name,
            url: url
        });
    }

    /*tournament.countryIoc*/
    /* <div className={styles.share}> // add share cal
         <FontAwesomeIcon icon={faShare}/>
       </div>
    */

    console.log('event: ', event)

    return <>
        {event && (
            <div className={styles.tournament}>
                <div className={styles.info}>
                    <div className={styles.head}>
                        <div className={styles.title}>
                            <h3>{event.meetingTypeName}</h3>
                            <h2 dangerouslySetInnerHTML={{__html: event.name}}></h2>
                        </div>
                        <div className={styles.date}>
                            <div className={styles.month}>{moment(event.dateFrom).format('MMM')}</div>
                            <div className={styles.day}>{moment(event.dateFrom).format('DD')}</div>
                            <div className={styles.weekday}>{moment(event.dateFrom).format('dddd')}</div>
                        </div>
                    </div>
                    <hr/>
                    {event.openFrom && (
                        <Item type={"split"} label="Nennung ab"><DateLabel value={PDS(event.openFrom)}/></Item>)}
                    {event.openTo && (
                        <Item type={"split"} label="Nennschluss"><DateLabel value={PDS(event.openTo)}/></Item>)}
                    <hr/>
                    {event.meta.participation && (
                        <Item type={"split"} label="Anmeldung">{event.meta.participation}</Item>)}
                    {/*event.meta.part && (
                        <Item label="Ausschreibung"><Label>{event.meta.invitation}</Label></Item>)*/}
                    {event.meta.nationCheck && (<>
                        <Item type={"split"} label="Nationencheck">{event.meta.nationCheck?'Ja':'Nein'}</Item>
                        <hr/>
                    </>)}
                    {event.meta.agesNennbar && (<>
                        <Item label="Altersklassen"><Label>{event.meta.agesNennbar?.join(" ")}</Label></Item>
                        <hr/>
                    </>)}
                    {event.meta?.city && (<>
                        <Item type={"split"} label="Adresse">{event.meta.city}</Item>
                        <hr/></>)}
                    {event?.organizer && (<>
                        <Item type={"split"} label="Veranstalter">{event.organizer}</Item>
                        <hr/></>)}
                    {event.meta?.url && (<>
                        <Item type={"split"} label="URL">
                            <a href={`https://${event.meta.url}`} target="_blank" rel="noopener noreferrer">
                                {event.meta.url}
                            </a>
                        </Item>
                        <hr/></>)}
                    {/*{event.meta?.streamingurl && (<>*/}
                    {/*    <Item type={"split"} label="Adresse">{event.meta.city}</Item>*/}
                    {/*    <hr/></>)}*/}
                    <h3>Ausschreibung</h3>
                    {event?.fileUploads?.map(file =>
                        <div className={styles.item} key={file.name}>
                            <Label>
                                <a href={MetaService.resolveCompetitionFileUrlAWS( file.name)}
                                   target="_blank" download>{file.original}</a>
                            </Label>
                            <Button
                                className={styles.share}
                                onClick={(evt) => sharePdf2(file.original, MetaService.resolveCompetitionFileUrlAWS(file.name))}>
                                <FontAwesomeIcon icon={faShare}/>
                            </Button>
                        </div>
                    )}

                    {event?.meta?.canRegister &&
                        <Button width={"full"} label={"Anmelden"} /*disabled={tournament.meetingParam.}*/ ></Button>
                    }
                </div>
                {event.meta.map && (
                    <div className={styles.map}>
                        {event.address && (
                            <div className={styles.address}>{event.address}</div>
                        )}
                        <MapContainer
                            style={{height: '100%'}}
                            center={[event.meta.map.lat, event.meta.map.lon]}
                            zoom={event.meta.map.z}
                            zoomControl={false}
                            scrollWheelZoom={true}
                        >
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <ZoomControl position="bottomleft"/>
                            <Marker
                                position={[event.meta.map.lat, event.meta.map.lon]}
                                icon={L.icon({
                                    iconUrl: marker,
                                    iconSize: [25, 41],
                                    iconAnchor: [13, 41],
                                    popupAnchor: [-5, -41],
                                })}
                            >
                                <Popup className="main-popup">
                                    <h3>{event.name}</h3>
                                    {event.address}
                                </Popup>
                            </Marker>
                        </MapContainer>
                    </div>
                )}
            </div>
        )}
    </>
}

export default withFilter('Event', {}, TournamentDetail, undefined, "/event");