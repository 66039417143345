import React from "react";
import styles from './Form.module.scss'
import {ReactChildrenType} from "../../core/children";
import classNames from "classnames";

interface IFormProps {
    children: ReactChildrenType;
    onSubmit?: (evt: any) => void;
    className?: string;
}


export const Form: React.FC<IFormProps> = ({children, onSubmit, className}) => {

    return (
        <form className={classNames(styles.form, className)} onSubmit={onSubmit}>
            {Array.isArray(children) ? children?.filter((x:any) => x) : children}
        </form>
    )
}