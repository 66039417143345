import React, {useState} from 'react';
import {Button, Item} from "../form";
import styles from "./cart.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {IOrderInventory, IOrderInventoryCartItem} from "../../api/models";
import {EFileVersion, UtilService} from "../../api";

interface ICartItemProps {
    inventory: IOrderInventory[];
    item: IOrderInventoryCartItem;
    onUpdate: (item: IOrderInventoryCartItem, del: boolean) => void;
    limit: number
    remove?: boolean
}

export const CartItem: React.FC<ICartItemProps> = ({inventory, item, onUpdate, limit, remove}) => {

    const trash = (item: IOrderInventoryCartItem) => {
        onUpdate(item, true);
    }

    const plus = (item: IOrderInventoryCartItem) => {
        item.quantity += 1;
        if (limit != null && item.quantity > limit) {
            item.quantity = limit
        }
        onUpdate(item, false);
    }

    const minus = (item: IOrderInventoryCartItem) => {
        item.quantity -= 1;
        if (item.quantity < 0) {
            item.quantity = 0;
        }
        onUpdate(item, false);
    }

    const getImage = (item: IOrderInventoryCartItem) => {
        const n = inventory?.find(x => x.id === item.itemId)
        console.log('n:', n)
        return n?.attachedFiles && n?.attachedFiles?.length > 0 ? UtilService.getFileUrl(n.attachedFiles[0], EFileVersion.md) : ''
    }

    const [userId] = useState<any | null>(localStorage.getItem('userId'));

    //console.log('inventory:', inventory)
    //console.log('item:', item)

    const inventoryItem = inventory?.find(x => x.id === item.itemId)
    return (
        <Item type={"full"} key={`item${item.itemId}`}>
            <div className={styles.item}>
                <img src={getImage((item))}/>
                <div className={styles.quantity}>
                    {item.quantity} x
                </div>
                <div className={styles.quantity}>
                    {userId && inventoryItem?.categoryParams?.memberNetPrice !== undefined && inventoryItem?.categoryParams?.memberNetPrice > 0
                        ? `${inventoryItem?.categoryParams.memberNetPrice}`
                        : `${inventoryItem?.categoryParams.netPrice}`}
                </div>
                <div className={styles.info}>
                    <div className={styles.title}>
                        {inventoryItem?.title}
                        {inventoryItem?.inventoryParams.gender ? ` | ${inventoryItem?.inventoryParams.gender}` : ''}
                        {inventoryItem?.inventoryParams.size ? ` | ${inventoryItem?.inventoryParams.size}` : ''}
                        {inventoryItem?.inventoryParams.color ? ` | ${inventoryItem?.inventoryParams.color}` : ''}
                    </div>
                    <div className={styles.buttons}>
                        {(remove == null || remove) &&
                            <Button><FontAwesomeIcon icon={faTrash} onClick={() => trash(item)}/></Button>}
                        <Button><FontAwesomeIcon icon={faMinus} onClick={() => minus(item)}/></Button>
                        <Button><FontAwesomeIcon icon={faPlus} onClick={() => plus(item)}/></Button>
                    </div>
                </div>
            </div>
        </Item>);
};