import {useEffect, useMemo, useState} from "react";
import {toaster} from "../core/toaster";
import {LicenceService} from "../api/licence";
import {ILicenceCategory, IUserModel} from "../api/models";
import {useParamRole} from "./useParam";


export function useLicenceCategories(user: IUserModel): ILicenceCategory[] {
    const [categories, setCategories] = useState<ILicenceCategory[]>([])
    const role = useParamRole(user);

    useEffect(() => {
        //toaster(async () =>  {
         LicenceService.categories(role.orgId).then(setCategories)
         //   return true;
        //}, {
         //   success: null,
         //   failure: "Fehler beim Laden der Kategorien"
        //});
    }, [role]);

    return useMemo(() => {
        return categories
    } , [categories])
}