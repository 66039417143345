import React, {useEffect, useState} from 'react';
import {IPageLinkProps} from "../../page.props";
import {Button, DateLabel, Item, Label, PDS, FD, FDX, Input, Select} from "../../form";
import {ItemNavGroup} from "../../form/ItemNavGroup";
import {OrderService} from "../../../api";
import {IOrderMember, orderTranslations} from "../../../api/models";
import {useParamId, useParamRole} from "../../useParam";
//import trainerStyles from "./trainer_detail.module.scss";

import {MemberService} from "../../../api/member";
import {ITraining} from "../../../api/models/MemberModel";
import classNames from "classnames";
import {v4 as uuidv4} from "uuid";
import styles from "./trainer_detail.module.scss";
import {InputArea} from "../../form/InputArea";
import {Row} from "../../form/row";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {toaster} from "../../../core/toaster";


export const TrainerDetail: React.FC<IPageLinkProps> = ({user, profile, refresh, setRefresh}) => {
    const [loading, setLoading] = useState(true);
    const [members, setMember] = useState<IOrderMember[] | null>(null);
    const [comment, setComment] = useState("")
    const [trainings, setTrainings] = useState<ITraining[]>([]);
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = useState<string | undefined>();


    const role = useParamRole(user);
    const id = useParamId();

    useEffect(() => {
        // console.log(id)
        if (role && user && id) {
            setLoading(true);
            OrderService.trainerById(role.orgId, id).then((m) => {
                setMember(m);
                setLoading(false);
            })
        }
    }, [user, id, role, refresh])
    //console.log(refresh);
    useEffect(() => {
        MemberService.trainings(role?.orgId, members?.[0]?.mgId || 0).then((m) => {
            setTrainings(m);
        });
    }, [id, role, refresh, members]);

    // console.log('members:',members)
    // console.log('user:',user)

    const accept = async () => {
        toaster(async () => {
            await OrderService.trainerUpdate(role?.orgId, id || 0, "accept", comment, Number(selectedValue));
            setRefresh && setRefresh((refresh || 0) + 1);
        });
    }

    const reject = async () => {
        toaster(async () => {
            await OrderService.trainerUpdate(role?.orgId, id || 0, "reject", comment);
            setRefresh && setRefresh((refresh || 0) + 1);
        });
    }

    const isDisabledApprove = (member: IOrderMember) => {
        switch (member.status) {
            case "created":
                return role.orgLevel !== 3;
            case "approved_jv":
                return role.orgLevel !== 2 || !isChecked;
            case "approved_lv":
                return role.orgLevel !== 1 ||!selectedValue
            case "approved_oejv":
            case "canceled":
                return true;
        }
    }
    const isDisabledReject = (member: IOrderMember) => {
        switch (member.status) {
            case "created":
                return role.orgLevel !== 3;
            case "approved_jv":
                return role.orgLevel !== 2;
            case "approved_lv":
                return role.orgLevel !== 1;
            case "approved_oejv":
            case "canceled":
                return true;
        }
    }


    return (
        <div className={styles.rightside}>
            {members?.map(((member, index) => {
                //console.log('member.updates:',member.updates);
                return (
                <>
                    <ItemNavGroup key={`member-${index}`} label={`Trainerlizenz Antrag.Nr ${member.id} (${orderTranslations[member.status]})`}
                                  className={classNames(styles.row)}
                    >
                        <div className={styles.info}>
                            <Item className={styles.infoItem} type="split"
                                  label="Vorname"><Label>{member?.firstname} </Label></Item>
                            <Item className={styles.infoItem} type="split"
                                  label="Nachname"><Label>{member?.surname} </Label></Item>
                            <Item className={styles.infoItem} type="split"
                                  label="Nation."><Label>{user?.nationality} </Label></Item>
                            <Item className={styles.infoItem} type="split" label="Geb.Dat."><DateLabel
                                value={PDS(member?.birthdate)}/></Item>
                            <Item className={styles.infoItem} type="split"
                                  label="Wohnort"><Label>{user.zip} {user.city}</Label></Item>
                            <Item className={styles.infoItem} type="split"
                                label="Judocard"><Label>{user?.license?.funcTo ? user?.license?.funcTo.substring(0,4) : 'fehlt'}</Label></Item>
                            {member.updates && member.updates.map((update, index) => (

                                <React.Fragment key={index}>
                                    <Item className={`${styles.infoItem} ${styles.processingStatus}`} type="split"
                                          label={update?.trainerLicenseName ? update.trainerLicenseName : "Status des Antrags:"}>
                                        <Label>{`${orderTranslations[update.state]}`}</Label>
                                    </Item>
                                    <Item className={`${styles.infoItem} ${styles.processing}`} type="split" label="Datum">
                                        <Label>{FD(update.date)}</Label>
                                    </Item>
                                    <Item className={`${styles.infoItem} ${styles.processing}`} type="split"
                                          label={update?.state == 'created' || 'rejected' ? "Antrag bei" : "Freigabe durch Organisation / Person"}>
                                        <Label>{update.orgName} {update.userName ? " / "+update.userName : ''}</Label>
                                    </Item>

                                    <Item className={`${styles.infoItem} ${styles.processing}`} type="split" label="Kommentar">
                                        <Label>{update.comment}</Label>
                                    </Item>
                                </React.Fragment>
                            ))}
                        </div>
                    </ItemNavGroup>
                    <ItemNavGroup key={uuidv4()} label={"Ausbildungshistorie"}
                                  className={classNames(styles.row)}
                    >
                        <div className={styles.info}>
                            {trainings?.map((training, index) => (
                                <Item key={index} className={styles.infoItem} type="split"
                                      label={training.name}><Label>{FD(training.dateFrom)} </Label></Item>
                            ))}
                        </div>
                    </ItemNavGroup>
                    <Row>
                        <Item size={6} type={'full'} flat={true}>
                            <Button
                                width={"full"}
                                label={"Freigabe"}
                                onClick={accept}
                                color={'success'}
                                disabled={isDisabledApprove(member)}
                            />
                        </Item>
                        <Item size={6} type={'full'} flat={true}>
                            <Button
                                width={"full"}
                                label={"Ablehnung"}
                                onClick={reject}
                                disabled={isDisabledReject(member)}
                            />
                        </Item>
                        {member.status !== 'canceled' ?
                            <><Item size={6} type={'full'} flat={true} className={styles.lastitem}>
                                {member.status === 'approved_lv' && role.orgLevel === 1 ?
                                    <Select className={styles.trainerLizenz}
                                        options={[
                                            {label: "TrainerLizenz A", value: "300"},
                                            {label: "TrainerLizenz B", value: "301"},
                                            {label: "TrainerLizenz C", value: "302"}
                                        ]}
                                        value={selectedValue}
                                        onChange={setSelectedValue}
                                    /> : ''}
                                {member.status === 'approved_jv' ? <div className={styles.straf}>
                                    <Button className={styles.trainerLizenz}
                                        color={isChecked ? "success" : "light"}
                                        onClick={() => setIsChecked(!isChecked)}
                                        disabled={ role.orgLevel !== 2}
                                    >
                                        <><FontAwesomeIcon icon={faCheck}/></>
                                    </Button>&nbsp;
                                    Strafregisterauszug überprüft
                                </div> : ''}
                            </Item>
                                <Item size={6} type={'full'} flat={true}  className={styles.lastitem}>
                                    <InputArea
                                        placeholder={"Optionaler Ablehnungstext"}
                                        value={comment}
                                        onChange={setComment}
                                        disabled={isDisabledReject(member)}
                                    />
                                </Item></>
                            : ''}
                    </Row>
                </>
            );
            }))}

        </div>
    );
};