
/**
 * Linearly interpolates between two numbers.
 *
 * @param {number} start - The start value.
 * @param {number} end - The end value.
 * @param {number} amt - The amount to interpolate between the start and end values.
 *                      Should be a value between 0 and 1.
 * @return {number} The interpolated value.
 */
export function lerp(start: number, end: number, amt: number) {
    return (1 - clamp01(amt)) * start + clamp01(amt) * end
}

/**
 * Clamps a value between 0 and 1.
 *
 * @param {number} v - The value to be clamped.
 *
 * @return {number} - The clamped value. If the value is less than 0, it returns 0. If the value is greater than 1, it returns 1. Otherwise, it returns the original value.
 */
export function clamp01(v: number): number {
    return Math.min(Math.max(v, 0), 1);
}

/**
 * Clamps a value between a minimum and maximum range.
 *
 * @param {number} value - The value to be clamped.
 * @param {number} min - The minimum range of the clamp.
 * @param {number} max - The maximum range of the clamp.
 *
 * @returns {number} - The clamped value.
 */
export function clamp(value: number, min: number, max: number): number {
    return Math.min(Math.max(value, min), max);
}