import React, {useEffect, useMemo, useState} from "react";
import {KyuService} from "../../api";
import {Button, DS, FD, Select, SelectRender} from "../form";
import {IKyuChairMember} from "../../api/models";
import styles from "./SelectKyuChairMembers.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";


interface ISelectKyuBordMembersProps {
    orgId: number;
    id: number | null;
    date: Date | null;
    disabled?: boolean;
    exclude: number[];
    orgMembers: IKyuChairMember[];
    setId: (v: number | null) => void;
}

const SelectKyuChairMembers: React.FC<ISelectKyuBordMembersProps> = ({
                                                                         orgId,
                                                                         disabled,
                                                                         id,
                                                                         setId,
                                                                         date,
                                                                         exclude,
                                                                         orgMembers
                                                                     }) => {
    const [search, setSearch] = useState<string | null>(null)
    const [chairMembers, setChairMembers] = useState<IKyuChairMember[]>([])
    const [searchMembers, setSearchMembers] = useState<IKyuChairMember[] | null>(null)
    //console.log(chairMembers.filter(x => !(exclude || []).includes(x.mgId)));

    const remap = (x: IKyuChairMember[]) => x.filter(x => !(exclude || []).includes(x.mgId)).map(x => ({
            label: x.officialName, value: x.mgId
        }
    ));

    useEffect(() => {
        const m: IKyuChairMember[] = ([] as IKyuChairMember[]).concat(orgMembers);
        if (id) {
            if (!m.map(x => x.mgId).includes(id)) {
                KyuService.getChairMember(orgId, DS(date as Date), id).then(chairMember => {
                    if (chairMember) {
                        m.push(chairMember)
                    }
                    setChairMembers( m.filter((x,i, a) => a.indexOf(a.find(y => y.mgId === x.mgId) as IKyuChairMember) === i));
                });
            }
        }
        setChairMembers(m.filter((x, i, a) => a.indexOf(a.find(y => y.mgId === x.mgId) as IKyuChairMember) === i));

    }, [date,  id, orgId, orgMembers]);

    useEffect(() => {
        if ((search?.length || 0) > 0) {
            KyuService.listChairMembers(orgId, {
                date: DS(date as Date),
                search: search || '',
            }).then(setSearchMembers)
        } else {
            setSearchMembers(null)
        }
    }, [date, orgId, search]);

    const member = useMemo(() =>  chairMembers?.find(x => x.mgId === id), [id, chairMembers]);

    const formatOptionLabel: SelectRender = ({
                                                 value,
                                                 label
                                             }) => {
        //const member = chairMembers?.find(x => x.mgId === value);

        return <div className={styles.judges}>
            <div className={styles.header}>
                <span className={styles.text}>{label}</span>
                {member?.judoCard &&<span className={styles.judoCard}>{member?.mgId +' / '+ member?.judoCard}</span>}
            </div>
            <div className={styles.content}>
                {member?.homeBase} {FD(member?.birthDate)}
                <span className={styles.age}>  / {member?.age} J</span>
            </div>
        </div>;
    }
    //console.log(remap(chairMembers || []), exclude)
    return <div className={styles.select}>
        {disabled ? (id ? formatOptionLabel({value: id, label: member?.officialName||''}) : <p>--</p>) : (<>
            <Select
                disabled={disabled}
                className={styles.dropdown}
                onChange={(v) => {
                    setId(v);
                }}
                onInputChange={(v) => {
                    setSearch(v)
                }}
                input={search || ''}
                value={id}
                options={remap(searchMembers || chairMembers || [])}
                render={formatOptionLabel}
                theme={(theme) => ({
                    ...theme,
                    colors: {
                        ...theme.colors,
                        primary: 'var(--primary-color)',
                    },
                })}
                freeHeight={true}
            />
            <Button
                disabled={id == null || disabled}
                className={styles.clear}
                onClick={() => setId(null)}
            >
                <FontAwesomeIcon icon={faXmark}/>
            </Button>
        </>)}
    </div>
}

export default React.memo(SelectKyuChairMembers);
