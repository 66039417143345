import React from 'react';

// import styles from "./NewsSwitcherPage.module.scss";
import {TContentState} from '../../../components/foundation';
import {INewsModel} from "../../../api/models";
import {IRouteComponentProps} from "../../../components/withRouter";
import {withFilter} from "../../../api/withFilter";
import PublicNews from "./PublicNews/PublicNews";
import PullToRefresh from "react-simple-pull-to-refresh";
import {CacheService, NewsService} from "../../../api";


interface IPublicNewsPageProps  extends IRouteComponentProps<void> {

}

interface IPublicNewsPageState {
  news:  INewsModel[];
  state: TContentState;
}

class PublicNewsPage extends React.Component<IPublicNewsPageProps, IPublicNewsPageState> {

  constructor(props: IPublicNewsPageProps) {
    super(props);
    this.state = {
      state: 'loading',
      news: []
    }
  }

  componentDidMount() {
    this.refreshNews(false)
  }

  async refreshNews(force: boolean): Promise<void> {
    this.setState({state: 'loading'})
    if (force) await CacheService.clear();
    const news = await NewsService.getNews();
    this.setState({
      news,
      state: 'active'
    });
  }

  render() {

    const {news} = this.state;

    return (
      <>
        <PullToRefresh onRefresh={() => this.refreshNews(true)}>
          <PublicNews news={news} />
        </PullToRefresh>
      </>
    )
  };
}

export default withFilter('News', {sub: ['news']}, PublicNewsPage);

//`/api/v2/mobile/judoaustria/file/md/${n.attachedFiles[0].fileId}`