import React from 'react';
import classNames from "classnames";
import { Email, Submit, Item, Input } from '../../../components/form';
import {Card, Content, Header } from '../../../components/foundation';
import styles from './Login.module.scss'

import {AuthService} from "../../../api";


interface ISignupState {
    email: string;
    showToast: boolean;
    toastColor: string;
    toastMessage: string;
    disableButton: boolean;
}

class SignupPage extends React.Component<any, ISignupState> {

    constructor(prop: any) {
        super(prop);
        this.state = {
            email: localStorage.getItem('email') || '',
            showToast: false,
            toastColor: 'primary',
            toastMessage: '',
            disableButton: false,
        }
    }

    handleEmailChange(e: string) {
        this.setState({
            email: e,
        })
    };

    async requestPin(e: any): Promise<void> {

        const {disableButton, email} = this.state;
        if (disableButton) {
            e.preventDefault();
            return;
        }
        this.setState({disableButton: true})

        try {
            const signup = await AuthService.signup(email);
            localStorage.setItem('email', email);
            if (signup) {
                this.setState({
                    showToast: true,
                    toastColor: 'success',
                    toastMessage: 'Pin erfolgreich angefordert', // err.message,
                })
                setTimeout(() => {
                    window.location.href = '/login';
                }, 3000)
            } else {
                this.setState({
                    showToast: true,
                    toastColor: 'danger',
                    toastMessage: 'Fehler', // err.message,
                })
                setTimeout(() => {
                    this.setState({disableButton: false})
                }, 2000);
            }
        } catch (e) {
            this.setState({
                showToast: true,
                toastColor: 'danger',
                toastMessage: 'Fehler', // err.message,
            })
            setTimeout(() => {
                this.setState({disableButton: false})
            }, 3000);
        }


        /*this.props.api.authorize(this.state.email, this.state.pin).then((res) => {
            this.setState({
                showToast: true,
                toastColor: 'success',
                toastMessage: LOGIN_SUCCESS,
            });
            setTimeout(() => {
                window.location.href = '/news';
            }, 250)


        }, (err) => {
            console.log(err)
            this.setState({
                showToast: true,
                toastColor: 'danger',
                toastMessage: 'Loginfehler', // err.message,
            })
            setTimeout(() => {
                this.setState({disableButton: false})
            }, 3000);

        });*/
    }

    dismissToast() {
        this.setState({
            showToast: false,
        });
    }

    render() {
        const {disableButton} = this.state;
        return (
            <>
                <Header label="Signup"/>

                <Content state="active">
                    <div className={styles.image}/>

                    <Card className={styles.card}>
                        <form onSubmit={async (evt) => {
                            evt.preventDefault()
                            await this.requestPin(evt);
                        }}>
                            <h3>Pin anfordern</h3>
                            <div className={classNames('ion-text-wrap', styles.info)}>Den 6-stelligen Pin kannst Du hier
                                anfordern. <br/>Er wird an deine vom <u>Verein registrierte Emailadresse</u> versandt und ist 2.5 Stunden gültig.
                            </div>

                            {/*<IonToast
                                isOpen={showToast}
                                onDidDismiss={() => this.dismissToast()}
                                position="top"
                                color={toastColor}
                                message={toastMessage}
                                duration={1000}
                            />*/}
                            {/*<Input*/}
                            {/*    // type="email"*/}
                            {/*    name="email"*/}
                            {/*    placeholder="Email"*/}
                            {/*    // autofocus={true}*/}
                            {/*    defaultValue={this.state.email}*/}
                            {/*    onChange={evt => this.handleEmailChange(evt)}*/}
                            {/*/>*/}
                            <Email
                                label="Email"
                                name="email"
                                placeholder="Email"
                                // autofocus={true}
                                value={this.state.email}
                                onChange={evt => this.handleEmailChange(evt)}
                            />
                            <Submit label="Pin anfordern" name="submit" disabled={disableButton}/>
                        </form>
                    </Card>


            </Content>
    </>
    )
    }
}

export default SignupPage;
