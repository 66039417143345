import React, {useEffect, useState} from 'react';
import {IPublicPageLinkProps} from "../page.props";
import {IOrderInventory, IUserModel, IUserProfile} from "../../api/models";
import {EFileVersion, OrderService, UtilService} from "../../api";
import {Row} from "../form/row";
import {Card} from "../foundation";
import {Thumb} from "../form/Thumb";
import styles from './shop.module.scss'
import {useNavigate} from "react-router-dom";
import {ItemNav} from "../form/ItemNav";
import {faCartShopping} from "@fortawesome/free-solid-svg-icons";

interface IShopPrices {
    newsId: number;
    minPrice: number;
    inventory: IOrderInventory[];
}

export const Shop: React.FC<IPublicPageLinkProps> = ({gotoUrl, setRefresh, refresh}) => {
    const [inventory, setInventory] = useState<IShopPrices[]>();
    const navigate = useNavigate();
    // by RK
    // Fetch the JWT token and update the state
    const [userId] = useState<any | null>(localStorage.getItem('userId'));
    //console.log('userId:', userId ? userId :  'missing')
    useEffect(() => {
        //setJwtToken(setJwtToken);
        console.log('userId:', userId ? userId :  'missing')

        OrderService.inventory().then((inv) => {
            setInventory(inv.filter(x => x.inventoryParams.visibility).reduce((p, c) => {
                const selected = p.find(x => x.newsId == c.newsId);
                if (selected) {
                    if (c.categoryParams.netPrice < selected.minPrice) {
                        selected.minPrice = c.categoryParams.netPrice;
                    }
                    selected.inventory.push(c)
                } else {
                    p.push({
                        newsId: c.newsId,
                        minPrice: c.categoryParams.netPrice,
                        inventory: [c]
                    })
                }
                return p;
            }, [] as IShopPrices[]));
        })
    }, [])
    return (
        <>
            <ItemNav
                label={`Einkaufswagen (${OrderService.getCart().items.length})`}
                icon={faCartShopping}
                primary={true}
                href={`${gotoUrl}/cart`}
            />
            <Row className={styles.spacing}>
                {inventory?.map(n => (
                    <Card className={styles.inventory}  noPadding={true} key={`news-${n.newsId}`} onClick={() => null}>
                        <Thumb
                            onClick={() => navigate(`${gotoUrl}/${n.newsId}`)}
                            className={styles.box}
                            title={`${n.inventory[0].title} | ${n.inventory.length > 1 ? 'ab' : ''} ${n.inventory[0].categoryParams.netPrice} EUR
        ${userId && n.inventory[0].categoryParams.memberNetPrice ? `\n(Mitgliedspreis: ${n.inventory[0].categoryParams.memberNetPrice} EUR)` : ''}
        `}
                            subtitle={n.inventory[0].abstract}
                            image={n.inventory[0].attachedFiles && n.inventory[0].attachedFiles?.length > 0 ? UtilService.getFileUrl(n.inventory[0].attachedFiles[0], EFileVersion.md) : ''}/>
                    </Card>
                ))}
            </Row>
        </>
    );
};