import React from 'react';
import styles from './layout.module.scss'


interface ILayoutProps {
    list?: React.ReactElement;
    page: React.ReactElement;
}

export const Layout: React.FC<ILayoutProps> = ({list, page}) => {
    return (
        <div className={styles.layout}>
            {list && <div className={styles.list}>
                {list}
            </div>}
            <div className={styles.page}>
                {page}
            </div>
        </div>
    );
};