import React, {useState} from 'react';
import {IPageProps} from "../../components/page.props";

import {useParamId, useParamRole} from "../../components/useParam";
import {withDesktop} from "../../api/withDesktop";
import {Layout} from "../../components/foundation/layout";
import {Shop} from "../../components/public/shop";
import {ShopDetail} from "../../components/public/shop_detail";


const DesktopShopPage: React.FC<IPageProps> = (props) => {
    const role = useParamRole(props.user);
    const id = useParamId();
    const gotoUrl = `/shop`;
    const [refresh, setRefresh] = useState<number>(0)

    return (
        id == null ? <Layout page={<Shop {...props} gotoUrl={gotoUrl} refresh={refresh} setRefresh={setRefresh} />} /> :
            <Layout page={<ShopDetail {...props} gotoUrl={gotoUrl} refresh={refresh} setRefresh={setRefresh} />} />
    );
};

export default withDesktop(DesktopShopPage);