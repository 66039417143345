import React from "react";
import styles from './row.module.scss'
import {ReactChildrenType} from "../../core/children";
import classNames from "classnames";

interface IRowProps {
    children: ReactChildrenType;
    type?: 'header'|'content'
    onSubmit?: (evt: any) => void;
    className?: string;
}


export const Row: React.FC<IRowProps> = ({children, onSubmit, className, type}) => {
    return (
        <div className={classNames(styles.form, type === 'header' ? styles.header : styles.content , className)} onSubmit={onSubmit}>
            {Array.isArray(children) ? children?.filter((x:any) => x) : children}
        </div>
    )
}