import React, {useEffect, useState} from 'react';
import {IPublicPageLinkProps} from "../page.props";
import {OrderService} from "../../api";
import {Button, Form, Input, Item} from "../form";
import {IOrderInventory, IOrderInventoryCartItem} from "../../api/models";
// import {loadStripe} from '@stripe/stripe-js';
// import {
//     EmbeddedCheckoutProvider,
//     EmbeddedCheckout
// } from '@stripe/react-stripe-js';
import {ItemNavGroup} from "../form/ItemNavGroup";
import {CartItem} from "./cart_item";
import preval from "preval.macro";

const useStripe = preval`
require('dotenv').config();
module.exports = process.env.USE_STRIPE
`
console.log(`useStripe: ${useStripe}`);

//const stripePromise = loadStripe("pk_live_51OME3KKSSkSimOV4uncIUchskDxbz7aTfekhFu38UYTBby0H6gvhRdAu9PX0H4DGLzjNWDbqYr4j7KnNHebIyydC00L1S9w3Qt");
//const stripePromise = loadStripe("pk_test_51OME3KKSSkSimOV46MPA0z3oAzn3Bvo9cEKtzyN3V7TpMr6Omg8T3wt8XQFc99T6Zt5xzjKMOnmbkuOCPzSMAodd00e87G6MYw");



export const Cart: React.FC<IPublicPageLinkProps> = ({gotoUrl}) => {
    const [inventory, setInventory] = useState<IOrderInventory[]>()
    const [cart, setCart] = useState(OrderService.getCart())
    const [clientSecret, setClientSecret] = useState('');
    const [checkout, setCheckout] = useState(false);
    const [address, setAddress] = useState("");
    const [street, setStreet] = useState("");
    const [zip, setZip] = useState<number|null>(null)
    const [city, setCity] = useState("");

    const [userId] = useState<any | null>(localStorage.getItem('userId'));
    //const [profile] = useState<IUserProfile>(JSON.parse(localStorage.getItem('profile') || '{}'));
    const [stripePromise, setStripePromise] = useState<any>(null);
    const [EmbeddedCheckoutProvider, setEmbeddedCheckoutProvider] = useState<any>(null);
    const [EmbeddedCheckout, setEmbeddedCheckout] = useState<any>(null);

    useEffect(() => {
        const loadStripeModules = async () => {
            if (useStripe !== '0') {
                console.log('Stripe enabled');
                const { loadStripe } = await import('@stripe/stripe-js');
                const { EmbeddedCheckoutProvider, EmbeddedCheckout } = await import('@stripe/react-stripe-js');
                setStripePromise(loadStripe('pk_test_51OME3KKSSkSimOV46MPA0z3oAzn3Bvo9cEKtzyN3V7TpMr6Omg8T3wt8XQFc99T6Zt5xzjKMOnmbkuOCPzSMAodd00e87G6MYw'));
                setEmbeddedCheckoutProvider(() => EmbeddedCheckoutProvider);
                setEmbeddedCheckout(() => EmbeddedCheckout);
            } else {
                console.log("Stripe disabled");
                setStripePromise(null);
            }
        };
        OrderService.inventory().then(setInventory)
        loadStripeModules();
    }, []);

    const pay = async () => {
        try {
            setCheckout(true)
            const cart = OrderService.getCart();
            cart.params = {
                address, street, zip, city
            }
            const secret = await OrderService.inventoryPurchase(cart);
            setClientSecret(secret);
            //item?.params.netPreis
            // console.log("success", session);
            //await stripe.redirectToCheckout({sessionId: session, ui_mode: 'embedded'});

        } catch (error) {
            console.error(error);
        }
    }

    const updateItem = (item: IOrderInventoryCartItem, del = false) => {
        if (del) {
            cart.items = cart.items.filter(x => x.itemId !== item.itemId);
        }
        OrderService.setCart(cart);
        setCart(OrderService.getCart);
    }

    const onComplete = () => {
        cart.items = [];
        OrderService.setCart(cart);
        setCart(OrderService.getCart);
    }

    return (
        <div>
            {!checkout ?
                <>
                    <ItemNavGroup label={"Einkaufswagen"}>
                        {cart.items.map(item => (
                            <CartItem inventory={inventory||[]} item={item} onUpdate={updateItem} limit={999} />
                        ))}
                    </ItemNavGroup>
                    <ItemNavGroup label={"Versandadresse"}>
                        <Form>
                            <Item type="full" label="Name Postempfaenger (c/o)">
                                <Input value={address} onChange={setAddress}/>
                            </Item>
                            <Item type="full" label="Strasse">
                                <Input value={street} onChange={setStreet}/>
                            </Item>
                            <Item type="full" label="PLZ" size={4}>
                                <Input type="number" value={zip} onChange={setZip}/>
                            </Item>
                            <Item type="full" label="Ort" size={8}>
                                <Input value={city} onChange={setCity}/>
                            </Item>
                        </Form>
                    </ItemNavGroup>
                    <Button width={"full"} label={"Jetzt zahlungspflichtig bestellen"} onClick={pay} disabled={
                        (cart?.items?.length === 0) || (address.length === 0) || (street.length === 0) || (!zip) || (city.length === 0)
                    }/>
                </>
                :
                <div id="checkout">
                    {clientSecret && EmbeddedCheckoutProvider && EmbeddedCheckout && (
                        <EmbeddedCheckoutProvider
                            stripe={stripePromise}
                            options={{clientSecret, onComplete}}
                        >
                            <EmbeddedCheckout  />
                        </EmbeddedCheckoutProvider>)
                    }
                </div>
            }
        </div>
    );
};
