import React from "react";
import styles from "./application_desktop.module.scss";
import {HeaderDesktop} from "./header_desktop";
import {IPageProps} from "../page.props";
import {ReactChildrenType} from "../../core/children";
import {NavigateFunction, useNavigate} from "react-router-dom";
import {Button} from "../../components/form";

interface IAppDesktopProps extends IPageProps {
    children?: ReactChildrenType;
    menu?: (navigate: NavigateFunction) => ReactChildrenType;
}


export const ApplicationDesktop: React.FC<IAppDesktopProps> = ({children, user, profile, menu}) => {
    const navigate = useNavigate();
    return (
        <React.Fragment>
            <HeaderDesktop user={user} profile={profile} menu={menu && menu(navigate)} />
            <div className={styles.app}>
                {!children ? (
                    <div className={styles.center}>
                        JUDOJAMA 2024
                        <div className={styles.image}></div>
                        <Button className={styles.helplink} width={"full"} label={"Hilfe für Vereine"} />
                        <Button className={styles.helplink} width={"full"} label={"Hilfe für Landesverbände"} />
                    </div>
                ) : children}
            </div>
        </React.Fragment>
    )
}

