import React from "react";
import styles from './Submit.module.scss'
import classNames from "classnames";

interface ISubmitProps {
    label: string;
    name: string;
    disabled?: boolean;
}


export const Submit: React.FC<ISubmitProps> = ({name, label, disabled}) => {

    return (
        <>
            <button
                type="submit"
                className={classNames(styles.button, disabled && styles.disabled)}
                name={name}
                disabled={disabled}
            >
                {label}
            </button>
        </>
    )
}