import {toast, ToastContainer} from "react-toastify";
import i18n from "../config/i18n";
import React from "react";
import 'react-toastify/dist/ReactToastify.css';
import {ApiError} from "../api/http";
import {ms} from "date-fns/locale";


export function toastError(msg: string) {
    toast.error(msg);/*{
        render: msg,
        type: "error",
        isLoading: false,
        autoClose: 3000,
    })*/
}

export async function toaster<T>(p: () => Promise<T | null>, msg?: {
    success?: string | null,
    failure?: string
}, final?: () => void) {
    const tid = toast.loading(i18n().global.processing);
    let res: T | null = null;
    try {
        res = await p();
        if (msg?.success === null) {
            toast.done(tid)
        } else {
            toast.update(tid, {
                render: msg?.success || i18n().global.success,
                type: "success",
                isLoading: false,
                autoClose: 3000,
            });
        }
    } catch (e) {
        console.error(e);
        if ((e as ApiError)?.result) {
            if (typeof (e as ApiError)?.result === 'object') {
                const err = (e as ApiError)?.result?.error
                console.log(err)
                let message: string | any = msg?.failure || i18n().global.error
                console.log('AUS01', err)
                if (err.Routine === '_bt_check_unique' && err.ConstraintName === 'uq_email_key') {
                    message = (<><h5>Fehler</h5><p>Die Email <strong>{err.value}</strong> ist bereits in Verwendung!
                    </p></>);
                } else if (err.Routine === 'ri_ReportViolation' && err.ConstraintName === 'fk_mg_id') {
                    message = (<><h5>Fehler</h5><p>Ist bereits Mitglied im Verein</p></>)
                } else if (err.Routine === 'check_exclusion_or_unique_constraint' && err.ConstraintName == 'funk_mg_funk_id_org_id_mg_id_daterange_excl') {
                    message = <><h5>Fehler</h5><p>Ist bereits Mitglied</p></>;
                } else if (err.Routine === 'ExecConstraints' && err.ConstraintName === 'chk_mg_gebdatum') {
                    message = <><h5>Fehler</h5><p>Geburtsdatum ungültig</p></>;
                } else if (err.Code === 'SPMR2') {
                    message = <><h3>HINWEIS:</h3><p><strong>Mehrfachnennung</strong> in gleicher Altersklasse nicht erlaubt! Bitte vorher die ausgewählte <strong>Gewichtsklasse abwählen</strong>.</p></>;
                } else if (err.Code === 'AUS01') {
                    message = <><h3>FEHLER:</h3><p><strong>{err.Hint}</strong>.</p></>;
                } else if (err.ConstraintName == 'uq_meeting_function_mg_ak_gk') {
                    message = <><h3>HINWEIS:</h3><p><strong>Person ist schon genannt.</strong><br/>
                        <strong>Mehrfachnennung</strong> in gleicher Rolle nicht erlaubt!.</p></>;
                }
                else {
                    message = <><h5>Fehler</h5><p>{err.Code} {err.Message}</p></>;
                }

                toast.update(tid, {
                    render: <>{message}</>,
                    type: "error",
                    isLoading: false,
                    autoClose: 5000,
                });
            } else {
                toast.update(tid, {
                    render: <>{`E002: ${(e as ApiError)?.result?.error}`}</>,
                    type: "error",
                    isLoading: false,
                    autoClose: 3000,
                });
            }

        } else {
            toast.update(tid, {
                render: msg?.failure || i18n().global.error,
                type: "error",
                isLoading: false,
                autoClose: 3000,
            });
        }
    } finally {
        final && final()
    }
    return res;
}

export const Toaster: React.FC = () => {
    return (<ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
    />);
}