import React from "react";
import styles from './Loading.module.scss'

export const Loading: React.FC = () => {
    return (
        <div className={styles.background}>
            <div className={styles.ring}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
}