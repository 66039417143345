import React from 'react';
import {LicenceDetailOrder} from "./licence_detail_order";
import {IPageLinkProps} from "../../page.props";
import {LicenceDetailApprove} from "./licence_detail_approve";
import {LicenceDetailCandidates} from "./licence_detail_candidates";
import {LicenceDetailNew} from "./licence_detail_new";
import {LicenceDetailDelete} from "./licence_detail_delete";
import {LicenceDetailSubmit} from "./licence_detail_submit";
import styles from "./licence.module.scss";
import { useSearchParams } from 'react-router-dom';


export const LicenceDetail: React.FC<IPageLinkProps> = (props) => {
    const [searchParams] = useSearchParams();
    const page = searchParams.get('t')
    switch (page) {
        case 'order':
            return <LicenceDetailOrder {...props} />
        case 'approve':
            return <LicenceDetailApprove {...props} />
        case 'delete':
            return <LicenceDetailDelete {...props} />
        case 'new':
            return <LicenceDetailNew {...props} />
        case 'candidates':
            return <LicenceDetailCandidates {...props} />
        case 'submit':
            return <LicenceDetailSubmit {...props} />
        default:
            return <div className={styles.headerempty}><p>JUDOCARD-Bestellung</p><p>Bitte wähle links</p><p> <strong>+ Neue JUDOCARDs bestellen</strong></p></div>
    }

};
