import React, {useState} from 'react';
import styles from "./login.module.scss";
import {Card} from "../../components/foundation";
import {Email, Form, Item, Submit} from "../../components/form";
import i18n from "../../config/i18n";
import {toaster} from "../../core/toaster";
import {AuthService} from "../../api";
import {Link} from "react-router-dom";

interface IPasswordRequestProps {

}

export const PasswordRequest: React.FC<IPasswordRequestProps> = ({}) => {
    const [email, setEmail] = useState<string | null>(localStorage.getItem('email'));
    const [disableButton, setDisableButton] = useState<boolean>(false);

    const reset = (e: any) => {
        e.preventDefault();
        if (disableButton) {
            return;
        }
        setDisableButton(true);
        localStorage.setItem('email', email||'');
        toaster(async() => {
            await AuthService.requestPassword(email||'')
        }, {
            success: i18n().login.success,
            failure: i18n().login.failure,
        }, () => {
            setDisableButton( false);
        })
    }

    return (
        <div className={styles.center}>
            <div className={styles.image}/>
            <Card>
                <Form onSubmit={(evt) => reset(evt)}>
                    <Item>
                        <h3>{i18n().login.message}</h3>
                    </Item>

                    <Item type={'full'} label="Email">
                        <Email
                            name="email"
                            //autocomplete="on"
                            placeholder="Email"
                            // autofocus={true}
                            value={email || ''}
                            onChange={setEmail}
                        />
                    </Item>


                    <Submit label="Password anfordern" name="reset" disabled={disableButton}/>
                    <Link to={"/login"}>Zum Login</Link>

                </Form>
            </Card>
        </div>
    );
};