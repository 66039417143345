import React, {useState} from 'react';
import {useParams} from "react-router";
import {Content, Header} from "../components/foundation";
import {useNavigate} from "react-router-dom";
import {Filter, IFilterOptions} from "./fiter";
import {ITrainerJudocardFilter} from "./models/TrainerJudoCard";

export interface IWithFilterProps<T> {
    filter: Filter<T>;
}


/**
 * Creates a higher-order component that filters and renders a component.
 * @param {string} label - The label for the header of the component.
 * @param {IFilterOptions<any>} options - The options for the filter.
 * @param {any} Component - The component to render.
 * @param {any} [FilterComponent] - The optional component to use for filtering.
 * @param {string|number} [back] - The optional route to navigate back to.
 */
export const withFilter = (
    label: string,
    options: IFilterOptions<any>,
    Component: any,
    FilterComponent?: any,
    back?: string|number
) => (props: any) => {
    const params = useParams()
    const navigate = useNavigate()
    const [filter, setFilter] = useState(new Filter(options))
    const [filterParams, setFilterParams] = useState(filter.getParams())

    filter.onUpdate((f) => setFilterParams(f.getParams()))

    // console.log('xxx', filter, options);

    //const application=useApplication()
    // filter

    // animations

    // route
    // label = 'Testdaten';
    return <>
        <Header
            label={label}
            filter={FilterComponent ? <FilterComponent {...props} filter={filter} /> : undefined}
            back={back ? () => navigate(back as any) : undefined}
        />
        <Content state={'active'}>
            <Component {...props} navigate={navigate} params={params} filter={filterParams}/>
        </Content>
    </>
};
