import React, {useState} from "react";
import styles from './Thumb.module.scss'
import classNames from "classnames";
import moment from "moment";



interface IGalleryItemProps {
    title: string;
    subtitle?: string;
    date?: string;
    image: string;
    onClick?: () => void;
    className?: string;
}

export const Thumb: React.FC<IGalleryItemProps> = ({
    title,
    subtitle,
    date,
    image,
    onClick,
    className
}) => {
    const [active, setActive] = useState(false);

    return (
        <div className={classNames(styles.thumb, active ? styles.active : undefined, className)} onClick={() => onClick && onClick()}>
            <img src={image} onLoad={(evt) => setActive(true)} loading="lazy"/>
            <div className={styles.bar}>
                <h2>{title}</h2>
                {subtitle && (<p dangerouslySetInnerHTML={{__html: subtitle}} />)}
                {date && <span className={styles.date}>{moment(date).format('DD. MM. yyyy')}</span>}
            </div>
        </div>
    )
}