import React, {useEffect, useMemo, useState} from 'react';
import {IPageLinkProps} from "../../page.props";
import {MemberService} from "../../../api/member";
import {useParamId, useParamRole} from "../../useParam";
import {IMember, ITraining} from "../../../api/models/MemberModel";
import {Button, DateInput, DTS, FD, FDX, Form, Item, Select} from "../../form";
import {Row} from "../../form/row";
import {ItemNavGroup} from "../../form/ItemNavGroup";
import {toaster} from "../../../core/toaster";
import styles from "./member_detail.module.scss";

export const MemberDetailTraining: React.FC<IPageLinkProps> = ({user, profile, refresh, setRefresh}) => {
    const role = useParamRole(user);
    const id = useParamId();
    const [member, setMember] = useState<IMember | null>();
    const [trainings, setTrainings] = useState<ITraining[]>([])

    const [newDateFrom, setNewDateFrom] = useState<Date | null>(new Date(Date.now()));
    const [newDateTo, setNewDateTo] = useState<Date | null>(null);
    const [newType, setNewType] = useState();

    useEffect(() => {
        MemberService.get(role.orgId, id||0).then((m) => {
            setMember(m);
        });
    }, [id, role, refresh]);


    useEffect(() => {
        MemberService.trainings(role.orgId, id||0).then((m) => {
            setTrainings(m);
        });
    }, [id, role, refresh]);

    const trainingGroups= useMemo(() =>
            profile.trainingGroups
                .sort((a, b) => Number(a) - Number(b))
                .map(x => (x.trainings
                    .sort((a, b) => Number(a.sort) - Number(b.sort))
                    .map(y => ({...y, group: x.groupName})))).flat().filter(x => x.editAtOrgLevel ? x.editAtOrgLevel.includes(role.orgId) : x.editAtOrgLevel == null)
                .map(x => ({
                    label: `${x.group} | ${x.name} | ${x.description}`,
                    value: x.id
                }))
        , [profile]);

    const assign = () => {
        //if (meeting)
        if (newType) {
            toaster(async () => {
                await MemberService.assignTraining(
                    role.orgId, id||0, role.orgId, newType, DTS(newDateFrom as Date), newDateTo ? DTS(newDateTo): undefined
                )
                setRefresh && setRefresh((refresh || 0) + 1);
                return
            }, {success: 'Ausbildung hinzugefǔgt', failure: 'Fehler beim hinzufügen!'})
        }

    }
    if (member?.id) {
        return (
            <>
            {member?.isActive == true ?(
                <ItemNavGroup label={"Neue Ausbildung"}>
                    <Form>
                        <Item size={2} type={"full"} label={"Von"}  className={styles.formheader}>
                            <DateInput value={newDateFrom} onChange={setNewDateFrom}/>
                        </Item>
                        <Item size={2} type={"full"} label={"Bis"} className={styles.formheader}>
                            <DateInput value={newDateTo} onChange={setNewDateTo} minDate={newDateFrom as Date}/>
                        </Item>
                        <Item size={8} type={"full"} label={"Type"} className={styles.formheader}>
                            <Select value={newType} options={trainingGroups} onChange={setNewType}/>
                        </Item>

                            <Button label={"Hinzufügen"} width={"full"} onClick={assign}/>
                    </Form>
                </ItemNavGroup>
            ):<ItemNavGroup label={"Ausbildungen"}>&nbsp;</ItemNavGroup>
            }
                <Row type="header">
                    <Item size={2} label="Von"/>
                    <Item size={2} label="Bis"/>
                    <Item size={5} label="Name"/>
                    <Item size={3} label="Prüfer"/>
                </Row>
                {trainings?.map((training) => (
                    <Row key={`training-${training.id}`} className={styles.row}>
                        <Item size={2}>
                            {FD(training.dateFrom)}
                        </Item>
                        <Item size={2}>
                            {FDX(training.dateTo) || '--'}
                        </Item>
                        <Item size={5}>
                            {training.name}
                        </Item>
                        <Item size={3}>
                            {training.controller}
                        </Item>
                    </Row>
                ))}
            </>
        );
    } else {
        return <p>Noch nicht verfǔgber</p>
    }
};