import React, {useEffect, useState} from 'react';

//import {SocialSharing} from '@ionic-native/social-sharing';

import styles from './Tournament.module.scss';
import {Item} from "../../../../components/form";
import classNames from "classnames";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {withFilter} from "../../../../api/withFilter";

import PullToRefresh from "react-simple-pull-to-refresh";
import {CompetitionsService} from "../../../../api";
import {IEventSummary} from "../../../../api/models/EventModel";
import {Loading} from "../../../../components/foundation/Loading";
import {useNavigate} from "react-router-dom";



const PublicEvents: React.FC<{}> = ({}) => {
    const [loading, setLoading] = useState(true);
    const [events, setEvents] = useState<{ [month: string]: IEventSummary[] }>({})
    const [refresh, setRefresh] = useState(0);
    const navigate = useNavigate();
    useEffect(() => {
        setLoading(true);
        CompetitionsService.getEvents().then((events) => {

            setEvents(events.reduce((p, c) => {
                const k = moment(c.dateFrom).format('MMM YYYY');
                if (!p[k]) {
                    p[k] = [c]
                } else {
                    p[k].push(c);
                }
                return p;
            }, {} as { [month: string]: IEventSummary[] }));
            setLoading(false);
        })

    }, [refresh])


    const getTournamentState = (c: IEventSummary): 'open' | 'closed' | 'soon' => {
        if (new Date(c.dateFrom) < new Date()) return 'closed';
        if (new Date(c.dateTo) < new Date()) return 'open';
        return 'soon';
    }

    const navigateTo = (c: IEventSummary) => {
        setTimeout(() => navigate(`/event/${c.id}`), 250);

    }

    return (
        <PullToRefresh onRefresh={async () => setRefresh((refresh+1) || 0)}>
            <>
                {loading && <Loading /> }
                {events && Object.keys(events).map(k => (
                    <div className={styles.container} key={`time-${k}`}>
                        <>
                            <div className={styles.datev}>{k}</div>
                            <div className={styles.list}>
                                {events[k].map(c => (
                                    <Item type="full" key={`tournament-${c.id}`} onClick={() => navigateTo(c)}>
                                        <div className={styles.item}>
                                            <div className={styles.date}>
                                                {moment(c.dateFrom).format('DD')}
                                                <div
                                                    className={classNames(
                                                        styles.state,
                                                        styles[getTournamentState(c)],
                                                        c.national ? styles.openBundesweit : undefined
                                                    )}
                                                >

                                                    {getTournamentState(c)}

                                                    {/*{(c?.openFrom && (new Date(c.openFrom) <= new Date())) && (
                                            <span
                                                className={`open${c.meetingParam?.BUNDESWEIT === 0 ? '-bundesweit' : ''}`}>open</span>)}*/}
                                                </div>

                                            </div>
                                            <div className={styles.title}>{c.name}</div>
                                            <div className={styles.arrow}>
                                                <FontAwesomeIcon icon={faChevronRight}/>
                                            </div>
                                        </div>
                                    </Item>
                                ))}
                            </div>
                        </>
                    </div>
                ))}
            </>
        </PullToRefresh>
    );
}

export default withFilter('Events', {sub: ['tournaments']}, PublicEvents)
