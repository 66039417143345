import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {IGalleryCollectionModel} from "../../../api/models/GalleryModel";
import styles from './GalleryDetail.module.scss'
import classNames from "classnames";
import {IWithFilterProps, withFilter} from "../../../api/withFilter";
import {useParams} from "react-router";
import {IUserModel} from "../../../api/models/UserModel";
import {Loading} from "../../../components/foundation/Loading";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import {Navigation, Pagination} from "swiper";
import {Swiper as SwiperClass} from "swiper/types";
import {ImageView} from "../../../components/foundation/ImageView";
import {GalleryService} from "../../../api";
import {ITrainerJudocardFilter} from "../../../api/models";


export type CollectionFiltersType = {
    judokas: IGalleryCollectionModel['tags']
    weight: IGalleryCollectionModel['tags']
    other: IGalleryCollectionModel['tags']
}

interface IGalleryDetailProps { //extends IApp, RouteComponentProps<{id: string}> {
    user: IUserModel;
    //collection: any[];
    //filter: any;
}

interface ICollectionModalState {
    filters: IGalleryCollectionModel['tags']
    collection: IGalleryCollectionModel[];
    nativeCollection: IGalleryCollectionModel[]
}

const GalleryFilter: React.FC<IWithFilterProps<ITrainerJudocardFilter>> = ({filter}) => {
    return <>tbd</>
}


const GalleryDetail: React.FC<IGalleryDetailProps> = ({user}) => {
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [gallery, setGallery] = useState<IGalleryCollectionModel[]>();
    const [prevImage, setPrevImage] = useState<IGalleryCollectionModel | null>(null);
    const [selectedImage, setSelectedImage] = useState<IGalleryCollectionModel | null>(null);
    const [nextImage, setNextImage] = useState<IGalleryCollectionModel | null>(null);
    const [swiperRef, setSwiperRef] = useState<SwiperClass|null>(null);

    const galleryId = useMemo<number>( () => Number(params['id'] || 0), [params]);

    const refresh = useCallback( async () => {
        setLoading(true);
        const g = await GalleryService.getCollection(galleryId);
        setGallery(g);
        setSelectedImage(g?.find(() => true) || null);
        setLoading(false);
    }, [galleryId])

    useEffect(() => {
        refresh();
    }, [refresh, user])


    // console.log(selectedImage)

    const next = () => {
        if (swiperRef) {
            swiperRef.slideNext();
        }
    }

    const prev = () => {
        if (swiperRef) {

            swiperRef.slidePrev();
        }
    }

    return (
        <>
            {!loading ? gallery && (
                <>
                    <div
                        className={classNames(styles.content)}
                    >

                        <div className={styles.preview}>
                            {/*<TransformWrapper
                                centerOnInit={true}
                                // minScale={0.15}
                                centerZoomedOut={true}
                            >
                                <TransformComponent>
                                    <img className={styles.mainImg} src={selectedImage?.urls.bigLarge}/>
                                </TransformComponent>
                            </TransformWrapper>*/}
                            <ImageView
                                src={selectedImage?.urls.bigLarge}
                                srcPrev={prevImage?.urls.bigLarge}
                                srcNext={nextImage?.urls.bigLarge}
                                onNext={next}
                                onPrev={prev}
                            />
                        </div>
                        <div className={styles.list}>
                            <Swiper
                                onSwiper={(s) => {
                                    setSwiperRef(s)
                                    setNextImage(0 < gallery?.length - 1 ? gallery[1] : null);

                                }}
                                slidesPerView={5}
                                spaceBetween={5}
                                centeredSlides={true}
                                modules={[ Navigation]}
                                onActiveIndexChange={(s) => {
                                    const i = s.activeIndex;
                                    if (gallery) {
                                        setPrevImage(i > 0 ? gallery[i - 1] : null);
                                        setSelectedImage(gallery[i])
                                        setNextImage(i < gallery?.length - 1 ? gallery[i + 1] : null);
                                    }
                                    //setPrevImage(i > 0 ? gallery[i-1] : null);
                                    //setSelectedImage(gallery[s.activeIndex])
                                    //setPrevImage(i < gallery?.length - 1 ? gallery[i+1] : null);
                                }}
                            >
                            {gallery.map((image, i) => (
                                <SwiperSlide key={`image-${i}`}>
                                    <img
                                        className={classNames(styles.img, selectedImage?.id === image.id ? styles.active : null )}
                                        src={image.urls.thumbnail}
                                        onClick={() => {
                                            /*setPrevImage(i > 0 ? gallery[i-1] : null);
                                            setSelectedImage(image)
                                            setPrevImage(i < gallery?.length - 1 ? gallery[i+1] : null);*/
                                            swiperRef?.slideTo(i)
                                        }}
                                        key={image.id}
                                    />
                                </SwiperSlide>

                            ))}
                            </Swiper>
                        </div>
                    </div>
                </>
            ) : <Loading />}

        </>
    );
}
export default withFilter('Gallery', {sub: []}, GalleryDetail, undefined /* GalleryFilter */, -1);
/*

             {/ *gallery.map(image => (

                            <img src={image.urls.medium} />

                        ))* /}
{/ *<CollectionImages
                            resetFilters={this.resetFilters}
                            tags={this.getFiltersTags()} images={collection}
                        />* /}


    applyFilter(nativeCollection: IGalleryCollectionModel[], filters: IGalleryCollectionModel['tags']) {
        if (filters.length) {
            return nativeCollection.filter((el) => containsEvery(el.tags, filters));
        }
        return [...nativeCollection];
    }

     getFiltersTags(): string[] | null {
        const {filters} = this.state
        return (filters.length > 0) ? filters.map((f) => f.tag) : null
    }

    resetFilters() {
        const {nativeCollection} = this.state;
        this.setState({
            filters: [],
            collection: this.applyFilter(nativeCollection, []),
        })
    }
            {/*<IonCardTitle className="title">
                                      <span
                                          className="header"
                                          dangerouslySetInnerHTML={{__html: 'TBD' || ''}}
                                      />
                                </IonCardTitle> * /}

            {/*
                                    <CollectionFilters
                                        tagsLength={filters.length}
                                        setFilters={(filters) => {
                                            this.setState({
                                                filters,
                                                collection: this.applyFilter(collection, filters)
                                            })
                                        }}
                                        collection={collection}
                                    />
                                * /}

 */