import React, {useRef, useState} from 'react';
import {withDesktop} from "../../api/withDesktop";
import Licence from "../../components/admin/licences/licence";
import {IPageProps} from "../../components/page.props";
import {Layout} from "../../components/foundation/layout";
import {LicenceDetail} from "../../components/admin/licences/licence_detail";
import {useParamRole} from "../../components/useParam";

export const DesktopLicencesPage: React.FC<IPageProps> = (props) => {
    const role = useParamRole(props.user);
    const gotoUrl = `/${role?.orgId}/${role?.funkMgId}/licences`;
    const [refresh, setRefresh] = useState<number>(0);

    return (
        <Layout list={
            <Licence {...props} gotoUrl={gotoUrl} refresh={refresh}  />
        } page={
            <LicenceDetail {...props} gotoUrl={gotoUrl} refresh={refresh} setRefresh={setRefresh} />
        }/>
    );
};

export default withDesktop(DesktopLicencesPage)