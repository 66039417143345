import {ICompetitionModelV2, ICompetitionModel} from "./models/CompetitionModel";
import {AuthService} from "./auth";
import {Constants} from "./constants";
import {HttpService} from "./http";
import {MetaService} from "./meta";
import {IEvent} from "./models/EventArcheryModel";
import {IEventDetail, IEventSummary} from "./models/EventModel";


const GET_COMPETITIONS = `${Constants.JAMA_SERVER_API}/meetings`;
const GET_COMPETITION = `${Constants.JAMA_SERVER_API}/meetings/`;
const GET_COMPETITIONSV2 = `${Constants.JAMA_SERVER_API_V2}/web/events`;
const GET_EVENT = `${Constants.JAMA_SERVER_API_V2}/web/event/`;

/**
 * CompetitionsService class provides methods to retrieve competition data from the server.
 */
export class CompetitionsService {

    /**
     * Retrieves a list of competitions from the server.
     *
     * This method sends a request to the server to fetch the competitions. If the request is successful,
     * it returns an array of competition objects. Otherwise, it logs the user out and returns an empty array.
     *
     * @returns {Promise<ICompetitionModelV2[]>} - A promise that resolves with an array of competition objects.
     * @deprecated
     */
    public static async getCompetitions(): Promise<ICompetitionModelV2[]> {
        await AuthService.refreshJwt();

        const res = await HttpService.get<{ rows: ICompetitionModel[] }>(`${GET_COMPETITIONS}?orgs=${MetaService.subscriptions.join(',')}`, true);
        if (res == null || res.rows == null) setTimeout(() => AuthService.logout(), 0);
        return ((res || {}).rows || []).map (r => ({
            id: r.id,
            name: r.name,
            ages: r.ages,
            countryIso: r.country_iso,
            dateFrom: r.date_from,
            dateTo: r.date_to,
            openTo: r.NENNSCHLUSS,
            registration: r.ANMELDUNG,
            invitation: r.AUSSCHREIBUNG,
            checkNationality: r.NATIONENCHECK,
            meetingTypeName: r.MEETINGTYPE_NAME,
            openFrom: r.OPENFROM,
            address: r.address,
            meetingParam: r.meeting_param,
            createdAt: r.created_at,
            updatedAt: r.updated_at,
        }));
    }
    /**
     * @deprecated
     */
    public static async getCompetitionsV2(limit: number = 200, search: string|undefined = undefined, type: string|undefined = undefined, dateFrom: string|undefined = undefined, dateTo: string|undefined = undefined) {
        await AuthService.refreshJwt();
        // const res = await ApiService.get<{ rows: ICompetitionModel[] }>(`${GET_COMPETITIONS}?orgs=${ApiService.subscriptions.join(',')}`, true);

        const s = search ? `&s=${search}` : ''
        const t = type ? `&t=${type}` : ''
        const df = dateFrom ? `&from=${dateFrom}` : ""
        const dt = dateTo ? `&to=${dateTo}` : ""


        const res = await HttpService.get<IEvent[]>(`${GET_COMPETITIONSV2}?limit=${limit}${s}${t}${df}${dt}`);
        //if (res == null) setTimeout(() => ApiService.logout(), 0);
        return res || [];
    }

    public static async getEvents(limit: number = 200, search: string|undefined = undefined, type: string|undefined = undefined, dateFrom: string|undefined = undefined, dateTo: string|undefined = undefined) {
        await AuthService.refreshJwt();
        const s = search ? `&s=${search}` : ''
        const t = type ? `&t=${type}` : ''
        const df = dateFrom ? `&from=${dateFrom}` : ""
        const dt = dateTo ? `&to=${dateTo}` : ""
        const res = await HttpService.get<IEventSummary[]>(`${GET_COMPETITIONSV2}?limit=${limit}${s}${t}${df}${dt}`);
        return res || [];
    }

    public static async getEvent<T>(eventId: number): Promise<IEventDetail<T>> {
        return await HttpService.get<IEventDetail<T>>(`${GET_EVENT}${eventId}`);
    }


    /**
     * Retrieve competition data by ID.
     *
     * @param {number} id - The ID of the competition.
     * @returns {Promise<ICompetitionModel | null>} The retrieved competition model data or null if not found.
     */
    public static async getCompetition(id: number): Promise<ICompetitionModel | null> {
        await AuthService.refreshJwt();
        const res = await HttpService.get<ICompetitionModel>(`${GET_COMPETITION}${id}`, true);
        // if (res == null || res.rows == null) setTimeout(() => ApiService.logout(), 0);
        return (res);
    }


    public static resolveEventUrl(url: string) {
        return `${url}`
    }

}