import React, {useEffect, useMemo, useState} from 'react';
import {IUserModel, ITrainerJudocardFilter, ILicenceCandidate, ILicenceOrder} from "../../../../../../api/models";
import {useParamId, useParamRole} from "../../../../../../components/useParam";
import {withFilter} from "../../../../../../api/withFilter";
import {ItemNavGroup} from "../../../../../../components/form/ItemNavGroup";
import classNames from "classnames";
import styles from "./AdminOrgLicenceOrder.module.scss";
import AvatarImage from "../../../../../../components/foundation/AvatarImage";
import {CacheService, IMAGE_URL} from "../../../../../../api";
import {ItemNav} from "../../../../../../components/form/ItemNav";
import {faAdd, faCircleCheck, faPaperPlane, faRemove} from "@fortawesome/free-solid-svg-icons";
import {LicenceService} from "../../../../../../api/licence";
import {DS, FD, Input, Item} from "../../../../../../components/form";
import {toastError} from "../../../../../../core/toaster";
import Toggle from "../../../../../../components/form/Toggle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface IAdminOrgLicenceOrderProps {
    user: IUserModel

}

const AdminOrgLicenceOrder: React.FC<IAdminOrgLicenceOrderProps> = ({user}) => {
    const role = useParamRole(user)
    const [loading, setLoading] = useState(true)
    const [candidates, setCandidates] = useState<ILicenceCandidate[]>([])
    const [search, setSearch] = useState<string>('')
    const [filterState, setFilterState] = useState<number>(0)
    const [order, setOrder] = useState<ILicenceOrder>()



    const id = useParamId()

    useEffect(() => {
        LicenceService.get(role?.orgId, id||0).then((o) => {
            if (order == null) {
                if (o.status !== 'created') {
                    setFilterState(1)
                } else {
                    setFilterState(0)
                }
            }
            setOrder(o)
        })

    }, [role, id, candidates]);

    useEffect(() => {
        refreshJudoCards(true)
    }, [user])


    const filter = useMemo(() => {
        return candidates.filter(x =>
            (x.firstname?.toLowerCase()?.includes(search?.toLowerCase()) ||
                x.surname?.toLowerCase()?.includes(search?.toLowerCase()) ||
                `${x.mgId}`.includes(search)) && (filterState === 0 || (filterState === 1 ? x.ordered : filterState === 2 ? !x.ordered && !x.errorCode : !x.errorCode)))
    }, [candidates, filterState, search]);

    const refreshJudoCards = async (force: boolean) => {
        setLoading(true);
        if (force) await CacheService.clear();
        if (role != null) {
            setCandidates(await LicenceService.candidates(role.orgId, id||0))
        }
        setLoading(false);
    }


    const locked = !(order?.status === 'created' || order?.status === 'submitted')
    const select = async (candidate: ILicenceCandidate) => {
        if ((!candidate.errorCode || candidate.ordered) && !locked) {
            await LicenceService.update(role.orgId, id||0, candidate.mgId, candidate.ordered)
            await refreshJudoCards(true);
        }
    }


    return (
        <div>
            <ItemNavGroup label={`${order?.categories?.join(" ")} ${order?.years?.join(" ")}`}>
                {(!locked && (
                    <>
                        {order?.canSubmit && order.items > 0 && order?.status === 'created' && <ItemNav
                            align="center"
                            label={`Bestellung #${order.id} abschicken`}
                            icon={faPaperPlane}
                            href={`/more/organization/licence/${role.funkMgId}/${id}/submit`}
                        />}
                        {order?.canApprove && order.items > 0 && order?.status === 'submitted' && <ItemNav
                            align="center"
                            label={`Bestellung #${order.id} freigeben`}
                            icon={faPaperPlane}
                            href={`/more/organization/licence/${role.funkMgId}/${id}/approve`}
                        />}

                        {order?.canSubmit && order.items === 0 && order?.status === 'created' && <ItemNav
                            align="center"
                            label={`Bestellung #${order.id} Löschen`}
                            icon={faRemove}
                            href={`/more/organization/licence/${role.funkMgId}/${id}/delete`}
                        />}
                    </>

                )) || ""}

                <Item type="form" className={styles.search}>
                    <Input onChange={setSearch} className={styles.name}/>
                    <Toggle className={styles.toggle} options={['alle', 'bestellt', 'verfügbar']} value={filterState}
                            onChange={setFilterState}/>
                </Item>
                {filter?.map(jc => (
                    <div
                        className={classNames(styles.judocard, jc.ordered && styles.active, jc.errorCode ? styles.disabled : undefined)}
                        key={jc.mgId}>
                        <div className={styles.card} onClick={() => select(jc)}>
                            <div className={styles.imageContainer}>
                                <AvatarImage className={styles.image}
                                             src={IMAGE_URL + jc?.uuid + `?v=${(Date.now() / 1000000).toFixed(0)}`}
                                    //onError={() => toastError(`Fehler in der Datenbank - Bild ${jc?.uuid} für Mitglied ${jc.mgId} nicht verfügbar!`)}
                                />
                            </div>
                            <div className={styles.content}>
                                <div className={styles.text}>
                                    <p className={styles.title}>{jc.firstname} {jc.surname}</p>
                                    <p className={styles.subtitle}>{FD(jc.birthdate)}</p>
                                </div>
                                {jc.errorMessage && (<div className={styles.error}>
                                    {jc?.errorMessage}
                                </div>)}
                                {jc.ordered && <div className={styles.selected}><FontAwesomeIcon icon={faCircleCheck} /></div>}
                            </div>

                        </div>
                    </div>
                ))}
            </ItemNavGroup>
        </div>
    );
};

export default withFilter('JudoCard', {}, AdminOrgLicenceOrder, undefined, -1);

/*



<p className={styles.subtitle}>{jc.birthdate} | {jc.gender}</p>
                                <p>{jc.club}</p>
                                <p>{jc.nationality}</p>
                                <p>{jc?.maxGrade_name}</p>
 */