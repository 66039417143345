import React, {useState} from 'react';
import {IPageProps} from "../../../components/page.props";
import {useParamId, useParamRole} from "../../../components/useParam";
import {withFilter} from "../../../api/withFilter";
import {Cart} from "../../../components/public/cart";


const MobileCartPage: React.FC<IPageProps> = (props) => {
    const role = useParamRole(props.user);
    const id = useParamId();
    const gotoUrl = `/shop`;
    const [refresh, setRefresh] = useState<number>(0)

    return (
        <Cart {...props} gotoUrl={gotoUrl} refresh={refresh} setRefresh={setRefresh} />
    );
};

export default withFilter('Cart', {}, MobileCartPage);
