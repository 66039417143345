import React from "react";
import styles from './Input.module.scss';
import classNames from "classnames";

interface IInputProps {
    onChange?: (v: any) => any;
    defaultValue?: string;
    width?: 'standard' | 'full';
    label?: string;
    name?: string;
    placeholder?: string;
    disabled?: boolean;
    maxLength?: number;
    type?: 'text'|'number';
    value?:string|number|null;
    className?: string;
    required?: boolean;
}

export const Input: React.FC<IInputProps> = ({
    name,
    label,
    placeholder,
    disabled,
    onChange,
    defaultValue,
    maxLength,
    type,
    value,
    className,
    required,
}) => {
    return (
        <>
            {label && <label className={styles.label}>{label}</label>}
            <input
                className={classNames(styles.input, className)}
                name={name}
                placeholder={placeholder}
                disabled={disabled}
                defaultValue={defaultValue}
                value={value||(defaultValue?undefined:"")}
                onChange={(e) => onChange && onChange(type === 'number' ? Number(e.target.value) : e.target.value)}
                maxLength={maxLength}
                type={type || 'text'}
                required={required}
            />
        </>
    )
}