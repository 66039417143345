import React from 'react';

import {Item, Label} from '../../../../../components/form';
import {useNavigate} from "react-router-dom";
import {withFilter} from "../../../../../api/withFilter";


interface IAdminTournamentProps {
    roles: any[]; // ISystemRole[];
    filter: any
}

const AdminTournamentFilter: React.FC<IAdminTournamentProps> = ({roles, filter}) => {
    return (<p>Todo: filter roles by organizations</p>)
}

const AdminTournament: React.FC<IAdminTournamentProps> = ({roles, filter}) => {
    const navigate = useNavigate()

    return (
        <>
            {roles?.filter(x => true).map(role => (
                <Item key={role.funkMgId} label={role.orgName}
                      onClick={() => navigate(`/admin/tournament/detail/${role.funkMgId}`)}>
                    <Label>{role.functionName}</Label>
                </Item>
            ))}
        </>
    )
}

export default withFilter('Turnier Admin', {sub: ['roles']}, AdminTournament, AdminTournamentFilter, -1);
