import React, {useEffect, useState} from 'react';
import {useParamId, useParamRole} from "../../useParam";
import {useNavigate} from "react-router-dom";
import {ILicenceCandidate} from "../../../api/models";
import {toaster} from "../../../core/toaster";
import {CacheService} from "../../../api";
import {LicenceService} from "../../../api/licence";
import {ItemNavGroup} from "../../form/ItemNavGroup";
import {Button, Item} from "../../form";
import styles from "./licence_detail.module.scss";
import {IPageLinkProps} from "../../page.props";

export const LicenceDetailDelete: React.FC<IPageLinkProps> = ({user, profile, gotoUrl, refresh, setRefresh}) => {
    const role = useParamRole(user)
    const navigate = useNavigate();
    const id = useParamId()
    const [candidates, setCandidates] = useState<ILicenceCandidate[]>()

    useEffect(() => {
        toaster(async () => {
                await CacheService.clear()
                setCandidates(await LicenceService.candidates(role.orgId, id||0))
                return null
            }, {
                success: null, failure: "Fehler beim Laden"
            }
        )
    }, [role, id]);

    const submit = () => {
        toaster(async () => {
                await LicenceService.delete(role.orgId, id||0);
                setRefresh && setRefresh((refresh ||0)+1);

                return
            }, {
                success: "Bestellung gelöscht",
                failure: "Fehler beim Löschen"
            }, () => navigate(`${gotoUrl}/`)
        )
    }

    return (
        <div>
            <ItemNavGroup label={`Bestellung #${id}`} >
                <Item className={styles.item}>Soll die Bestellung wirklich gelöscht werden?</Item>
            </ItemNavGroup>

            <Button label="Löschen" className={styles.save}
                    onClick={submit} disabled={false}/>
        </div>
    );
};