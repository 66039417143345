import { useNavigate } from "react-router-dom";
import React from "react";
import styles from './BackButton.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";

interface IBackButtonProps {
    label: string;
    navigateTo?: string;

}

export const BackButton: React.FC<IBackButtonProps> = (
    {
        label = 'go back',
        navigateTo
    }) => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        if (navigateTo) {
            navigate(navigateTo);
        } else {
            navigate(-1);
        }
    };

    return (
        <button className={styles.backButton} onClick={handleGoBack}>
            <FontAwesomeIcon icon={faChevronLeft} />
            <span> {label}</span>
        </button>
    );
};