import React, {useEffect, useMemo, useState} from 'react';
import {IPageLinkProps} from "../../page.props";
import {useNavigate} from "react-router-dom";
import {useParamId, useParamRole} from "../../useParam";
import {MemberService} from "../../../api/member";
import {ItemNavGroup} from "../../form/ItemNavGroup";
import {Loading} from "../../foundation/Loading";
import {ItemNav} from "../../form/ItemNav";
import {FD, Input, Item} from "../../form";
import styles from "../member/member.module.scss";
import AvatarImage from "../../foundation/AvatarImage";
import {IOrderMember, orderTranslations} from "../../../api/models";
import {OrderService} from "../../../api";
import classNames from "classnames";


export const Trainer: React.FC<IPageLinkProps> = ({user, profile, gotoUrl, refresh}) => {
    const [loading, setLoading] = useState(true);
    const [members, setMembers] = useState<IOrderMember[]>([]);
    const [search, setSearch] = useState<string>('');

    const navigate = useNavigate();
    const role = useParamRole(user);
    const id = useParamId();

    useEffect(() => {
        if (user) {
            OrderService.trainer(role.orgId).then((m) => {
                setMembers(m);
                setLoading(false);
            })
        }
    }, [user, refresh])

    const goto = (id: number) => {
        // /more/organization/member
        navigate(`${gotoUrl}/${id}`)
    }

    const filter = useMemo(() => {
        return members.filter(x =>
            (x.firstname?.toLowerCase()?.includes(search?.toLowerCase()) ||
                x.surname?.toLowerCase()?.includes(search?.toLowerCase()))).splice(0, 10)

    }, [members, search]);

    const isDisabled = (member: IOrderMember) => {
        switch (member.status) {
            case "created":
                return role.orgLevel !== 3;
            case "approved_jv":
                return role.orgLevel !== 2;
            case "approved_lv":
                return role.orgLevel !== 1;
            case "approved_oejv":
            case "canceled":
                return true;
        }
    }

    members.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

    return <>
        <ItemNavGroup label={role?.orgName}>
            <>
                {loading && <Loading />}

                <Item type="full" className={styles.search}>
                    <Input

                        placeholder={"Suche nach Name"}
                        onChange={setSearch}
                        value={search}
                        className={styles.name}
                    />
                </Item>
                {!loading && (filter?.length > 0 ? filter.map((m) => (
                    //console.log('m:',m),
                    <ItemNav
                        key={`member-${m.id}`}
                        className={styles.navItem}
                        onClick={() => goto(m.id || 0)}
                        selected={id === m.id}
                    >
                        {/*<div className={styles.item}>
                            <AvatarImage src={MemberService.imageUrl(m.uuid)} className={styles.avatar}/>
                            <div className={styles.text}>
                                <div>{m.surname} {m.firstname}</div>
                                <div className={styles.bottom}>
                                    <div className={styles.org}>{m.orgId && m.orgId !== role.orgId && profile.organizations.find(x => x.id ===m.orgId)?.nameShort}</div>
                                    <div className={styles.birthdate}>{FD(m.birthdate)}</div>
                                </div>
                            </div>
                        </div>*/}

                        <div className={classNames(styles.item, isDisabled(m) && styles.disabled)}>
                            <AvatarImage src={MemberService.imageUrl(m.uuid)} className={classNames(styles.avatar)}/>
                            <div className={styles.text}>
                                <div className={styles.nameLicContainer}>
                                    <div className={classNames(styles.name)}>{m.surname} {m.firstname}</div>
                                    <div
                                        className={classNames(styles.license, m?.licence === true ? styles.active : styles.expired, isDisabled(m) && styles.disabled)}>
                                        {m.mgId}
                                    </div>
                                </div>
                                <div className={styles.bottom}>
                                    <div
                                        className={styles.org}>{m.orgId && m.orgId !== role.orgId && profile.organizations.find(x => x.id === m.orgId)?.nameShort}</div>

                                    <div className={styles.birthdate}>AntragNr. {m.id} <br/>vom: {FD(m.createdAt)}</div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.birthdate}>{FD(m.birthdate)}</div>
                        <div className={classNames(styles.info, isDisabled(m) && styles.disabled)}>
                            <div
                                className={classNames(styles.state, styles[m.status])}>{orderTranslations[m.status]}</div>

                        </div>

                    </ItemNav>
                )) : <Item type="list" label="Keine Mitglieder gefunden"/>)}
            </>
        </ItemNavGroup>
    </>
};