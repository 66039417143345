import {useEffect, useState} from "react";
import {AuthService, CacheService} from "./index";
import {IUserModel} from "./models/UserModel";
import {Loading} from "../components/foundation/Loading";
import {IUserProfile} from "./models/AuthModel";

export interface IAppAuth {
    auth: boolean;
}

let dontRunAuthenticateTwice = false

/**
 * HOC (Higher Order Component) that provides authentication functionality to the wrapped component.
 *
 * @param {React.Component} Component - The component to be wrapped with authentication functionality
 */
export const withAuth = (Component: any) => (props: any) => {
    const [state, setState] = useState<IAppAuth|null>(null);
    const [user, setUser] = useState<IUserModel|null>(null);
    const [profile, setProfile] = useState<IUserProfile|null>(null)

    useEffect(() => {
        const authenticate = async() => {
            try {
                /*await AuthService.refresh('global', {
                    refresh: localStorage.getItem('refresh') || '',
                    token: localStorage.getItem('token') || '',
                })*/
                await AuthService.refreshJwt(true)
                const usr = await refresh();
                // console.log(usr)
                setState({
                    auth: !!usr,
                })
            } catch (e) {
                // console.error(e);
                setState({
                    auth: false,
                })
            }
        }

        if (!dontRunAuthenticateTwice) {
            authenticate()
        }
        dontRunAuthenticateTwice=true
    }, [])

    const refresh = async () => {
        CacheService.clear();
        const [usr, profile] = await Promise.all([AuthService.getUser(), AuthService.getProfile()]);

        setUser(usr);
        setProfile(profile);
        return usr;
    }

    return state ? <Component {...props} {...state} user={user} profile={profile} refresh={refresh}/> : <Loading />;
};
