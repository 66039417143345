
const PROFILE_STORAGE = 'profiles';
const CURRENT_USER = 'userId';

/**
 * Represents the result of verifying a PIN.
 *
 * @interface IVerifyPinResult
 */
export interface IVerifyPinResult {
    jwtToken: string;
    refreshToken: string;
    exp: number;
    date: string;
    message: string;
}

/**
 * Represents an authentication profile.
 *
 * @interface IAuthProfile
 */
export interface IAuthProfile {
    name: string,
    userId: string,
    auth: IVerifyPinResult,
}

/**
 * Represents a local service for managing user profiles and authentication.
 */
export class LocalService {
    /**
     * Retrieves the authentication profiles stored in the localStorage.
     *
     * @returns {IAuthProfile[]} The sorted array of authentication profiles retrieved from localStorage.
     */
    public static getProfiles(): IAuthProfile[] {
        return JSON.parse(localStorage.getItem(PROFILE_STORAGE) || '[]')
            .sort((a: IAuthProfile,b:  IAuthProfile) => a?.userId?.localeCompare(b?.userId));
    }

    /**
     * Retrieves the user profile from localStorage
     *
     * @return {IAuthProfile | undefined} The authenticated user's profile if found, otherwise undefined
     */
    public static getProfile(): IAuthProfile | undefined {
        const profiles: IAuthProfile[] = JSON.parse(localStorage.getItem(PROFILE_STORAGE) || '[]');
        const user = localStorage.getItem(CURRENT_USER);
        return profiles.find(x => x.userId === user);
    }

    /**
     * Sets the user profile in local storage.
     *
     * @param {IAuthProfile} profile - The user profile to be set.
     * @return {IAuthProfile[]} - The updated array of user profiles.
     */
    public static setProfile(profile: IAuthProfile): IAuthProfile[] {
        const profiles = JSON.parse(localStorage.getItem(PROFILE_STORAGE) || '[]')
            .filter((x: IAuthProfile) => x.userId !== profile.userId);

        profiles.push(profile);

        localStorage.setItem(CURRENT_USER, profile.userId);
        localStorage.setItem(PROFILE_STORAGE, JSON.stringify(profiles));
        return profiles;
    }

    /**
     * Sets the current user profile by storing the user ID in the local storage.
     *
     * @param {string} userId - The ID of the user profile to be selected.
     * @return {void}
     */
    public static selectProfile(userId: string) {
        localStorage.setItem(CURRENT_USER, userId);
    }

    /**
     * Removes a profile from local storage based on the provided userId.
     * If the current user is removed, it updates the current user to the next available profile.
     *
     * @param {string} userId - The userId of the profile to be removed.
     *
     * @return {void}
     */
    public static removeProfile(userId: string) {
        const profiles: IAuthProfile[] = JSON.parse(localStorage.getItem(PROFILE_STORAGE) || '[]')
            .filter((x: IAuthProfile) => x.userId !== userId);
        localStorage.setItem(PROFILE_STORAGE, JSON.stringify(profiles));
        const user = localStorage.getItem(CURRENT_USER);

        if (user === userId) {
            const nextUserId = profiles.find(x => true)?.userId;
            console.log(nextUserId)
            if (nextUserId)
                localStorage.setItem(CURRENT_USER, nextUserId)
            else {
                localStorage.removeItem(CURRENT_USER)
            }
        }
    }

    /**
     * Retrieves the authentication information from the user's profile.
     * @returns {IVerifyPinResult | undefined} - The authentication information if available, otherwise undefined.
     */
    public static getAuth(): IVerifyPinResult | undefined {
        return LocalService.getProfile()?.auth;
    }

    /**
     * Returns the current user ID.
     *
     * @return {string | null} The current user ID, or null if it is not available.
     */
    public static getCurrentUserId(): string | null {
        return localStorage.getItem(CURRENT_USER);
    }
}