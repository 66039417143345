import React from 'react';
import styles from './Profile.module.scss';
import {withFilter} from '../../../../api/withFilter';
import {IMAGE_URL} from "../../../../api";
import {useNavigate} from "react-router-dom";
import {Profile} from "../../../../components/admin/profile/profile";
import {IPageLinkProps} from "../../../../components/page.props";




const MoreProfile: React.FC<IPageLinkProps> = ({user, profile, refresh}) => {
    const backgroundImage = IMAGE_URL + user?.imageLink + `?v=${Date.now()}`;
    const navigate = useNavigate();

    return <Profile user={user}  gotoUrl={'/more/profile'} profile={profile}/>

    /*return (
        <PullToRefresh onRefresh={() => refresh && refresh()}>
            <div className={styles.profile}>
                <div className={styles.picture}>
                    <div className={styles.frame}>
                        <img src={backgroundImage} alt="Profile"/>
                        <Button className={styles.edit} onClick={() => navigate('/more/profile/edit')}><FontAwesomeIcon icon={faEdit} /></Button>
                        <CameraButton member={user.mg_id} className={styles.camera} onClick={() => refresh && refresh()}/>
                        <div className={styles.name}>
                            <h1>{user.title} {user.givenName} {user.middleName} {user.familyName}</h1>
                        </div>
                    </div>
                </div>
                <ItemNavGroup label={"Info"}>
                    <div className={styles.info}>
                        <Item label="Nation."><Label>{user.nationality} / {user.IOC}</Label></Item>
                        <Item label="Geb.Dat."><DateLabel value={PDS(user.birthDate)}/></Item>
                        <Item label="Adresse"><Label>{user.address}</Label></Item>
                        <Item label="PLZ"><Label>{user.zip}</Label></Item>
                        <Item label="Ort"><Label>{user.city}</Label></Item>
                        <Item label="Land"><Label>{user.countryOfResidence}</Label></Item>
                        <Item label="Geschlecht"><Label>{user.gender}</Label></Item>
                        <Item label="Mobil"><Label>{user.cellPhone}</Label></Item>
                        {user?.fixedLine && (<Item label="Festnetz"><Label>{user.fixedLine}</Label></Item>)}
                        {user?.companyPhone && (<Item label="Firma"><Label>{user.companyPhone}</Label></Item>)}
                        <Item label="Email"><Label>{user.email}</Label></Item>
                        {user?.nationchecked && (<Item label="Nat.check"><DateLabel value={PDS(user.nationchecked)}/></Item>)}
                        {user?.medical && (<Item label="Medical"><DateLabel value={PDS(user.medical)}/></Item>)}
                    </div>
                </ItemNavGroup>
                <ItemNavGroup label={"Prüfungen"}>
                    <div className={styles.grades}>
                        {(user?.allGrades || []).map((item: any) =>
                            (<Item key={item?.name} label={item.name}>
                                <Label>
                                    <div className={styles.belt}>
                                       <span className={styles.color}
                                             dangerouslySetInnerHTML={{__html: item?.beltcolor}}/><br/>
                                        <span
                                            className={styles.date}>{new Date(item?.datum).toLocaleString('de-DE', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit'
                                        })}</span>
                                    </div>
                                </Label>
                            </Item>)
                        )}
                    </div>
                </ItemNavGroup>
            </div>
        </PullToRefresh>
    );*/
}


export default withFilter('Profile', {}, MoreProfile, undefined, '/more');
