import React from 'react';
import {withDesktop} from "../../api/withDesktop";
import {IPageProps} from "../../components/page.props";
import {Layout} from "../../components/foundation/layout";
import Organization from "../../components/admin/organization/organization";
import OrganizationDetail from "../../components/admin/organization/organization_detail";
import {useParamRole} from "../../components/useParam";


export const DesktopOrganizationsPage: React.FC<IPageProps> = (props) => {
    const role = useParamRole(props.user);
    const gotoUrl = `/${role?.orgId}/${role?.funkMgId}/organizations/`;
    return (
        <Layout list={
            <Organization {...props} gotoUrl={gotoUrl} />
        } page = {
            <OrganizationDetail {...props} gotoUrl={gotoUrl} />
        }
        />
    );
};

export default withDesktop(DesktopOrganizationsPage)