import React from "react";
import classNames from "classnames";
import {ReactChildrenType} from "../../core/children";
import styles from './Item.module.scss'

interface IItemProps {
    type?: 'form' | 'list' | 'full'|'split' // default = form
    label?: string;
    size?: number;
    children?: ReactChildrenType;
    onClick?: () => void;
    className?: string;
    selected?: boolean;
    flat?: boolean;
    required?:boolean;
}

export const Item: React.FC<IItemProps> = ({
                                               label,
                                               type,
                                               children,
                                               onClick,
                                               className,
                                               size,
                                               selected,
                                               flat,
                                               required,
                                           }) => {
    return (
        <div
            className={classNames(
                styles.item,
                styles[type || 'form'],
                styles[`s${size || 12}`],
                selected && styles.selected,
                onClick ? styles.clickable : styles.notAllowed,
                className
            )}
            onClick={onClick}
        >
            <div className={styles.padding}>
                {label ? (
                    <label className={styles.label}>
                        {label} {required && <span style={{ color: 'red' }}>*</span>}
                    </label>
                ) : flat ? (
                    ''
                ) : (
                    <div className={styles.spacer} />
                )}
                {children && <div className={styles.children}>{children}</div>}
            </div>
        </div>
    );
}