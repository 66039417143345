import React, {useState} from 'react';
import {useParamId, useParamRole} from "../../../../../components/useParam";
import {IPageProps} from "../../../../../components/page.props";
import {withFilter} from "../../../../../api/withFilter";
import Organization from "../../../../../components/admin/organization/organization";
import OrganizationDetail from "../../../../../components/admin/organization/organization_detail";


const AppAdminOrganizations: React.FC<IPageProps> = (props) => {
    const role = useParamRole(props.user);
    const gotoUrl = `/more/admin/${role?.funkMgId}/organizations`;
    const [refresh, setRefresh] = useState<number>(0);

    const id = useParamId();

    return id == null ? <Organization {...props} gotoUrl={gotoUrl} refresh={refresh}  />
        : <OrganizationDetail {...props} gotoUrl={gotoUrl} refresh={refresh} setRefresh={setRefresh} />
};

export default withFilter('Org', {}, AppAdminOrganizations, undefined, -1);