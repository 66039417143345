import React, {useState} from "react";
import {IPageProps} from "../../../../../components/page.props";
import {useParamId, useParamRole} from "../../../../../components/useParam";
import {withFilter} from "../../../../../api/withFilter";
import {Member} from "../../../../../components/admin/member/member";
import MemberDetail from "../../../../../components/admin/member/member_detail";

const AppAdminMember: React.FC<IPageProps> = (props) => {
    const role = useParamRole(props.user);
    const gotoUrl = `/more/admin/${role?.funkMgId}/members`;
    const [refresh, setRefresh] = useState<number>(0);

    const id = useParamId();

    return id == null ? <Member {...props} gotoUrl={gotoUrl} refresh={refresh}  />
        : <MemberDetail {...props} gotoUrl={gotoUrl} refresh={refresh} setRefresh={setRefresh} />
};

export default withFilter('Member', {}, AppAdminMember, undefined, -1);