
/**
 * TTL (Time to Live) represents the duration in milliseconds before a resource or data is considered expired or no longer valid.
 *
 * @type {number}
 */
const TTL = 15 * 60 * 1000;

/**
 * Represents a cache.
 *
 * @interface
 */
interface ICache {
    expires: number;
    data: any;
}

/**
 * Class representing a CacheService.
 * Provides methods for getting, setting, and clearing cache.
 */
export class CacheService {
    private static _cache: { [url: string]: ICache } = {};

    /**
     * Retrieves data from a given URL.
     *
     * @param {string} url - The URL from which to retrieve data.
     * @returns {Promise<any>} - A Promise that resolves to the retrieved data, or null if the data is not cached.
     */
    static async get(url: string): Promise<any> {
        if (CacheService._cache[url] != null) {
            if (Date.now() < CacheService._cache[url].expires)
                return CacheService._cache[url].data;
        }
        return null;
    }

    /**
     * Sets the data in the cache for the given URL.
     *
     * @param {string} url - The URL for which to set the cache data.
     * @param {any} data - The data to be set in the cache.
     *
     * @return {Promise<void>} - A Promise that resolves when the cache data is set.
     */
    static async set(url: string, data: any) {
        CacheService._cache[url] = {
            expires: Date.now() + TTL,
            data,
        };
        return
    }

    /**
     * Clears the cache.
     * @return {Promise<boolean>} - A promise that resolves to true if the cache is successfully cleared.
     */
    static async clear() {
        CacheService._cache = {};
        return true;
    }
}