import React, {ReactNode} from "react";
import ReactSelect from "react-select";
import {ThemeConfig} from "react-select/dist/declarations/src/theme";


export type SelectRender = (data: { value: any, label: string }) => ReactNode;

interface ISelectProps {
    onChange?: (v: any) => any;
    onInputChange?: (v: string) => any;
    defaultValue?: any;
    value?: any;
    options: { label: string, value: any }[];
    render?: SelectRender;
    disabled?: boolean;
    input?: string;
    className?: string;
    theme?: ThemeConfig;
    type?: 'number'|'text';
    freeHeight?: boolean;
    placeholder?: string;
}

export const Select: React.FC<ISelectProps> = ({
                                                   onChange,
                                                   onInputChange,
                                                   defaultValue,
                                                   input,
                                                   options,
                                                   render,
                                                   disabled,
                                                   value,
                                                   className,
                                                   theme,
                                                   type,
                                                   freeHeight,
                                                   placeholder
                                               }) => {
    const customStyles = {
        control: (baseStyles: any, {isDisabled}:any) => ({
            ...baseStyles,
            width: '100%',
            height: freeHeight ? 'initial' : '3rem',
            border: '1px solid var(--gray)',
            backgroundColor: isDisabled ? 'var(--gray-super-light)' : 'var(--primary-color-super-light)',
        }),
        menu: (provided: any, state: any) => ({
            ...provided,
            //width: 'calc(100vw - 1rem)',  // This line will make the control full width.
            width: '100%',
            margin: '0',
            //right: '0',
        })
    }

    return <ReactSelect
        isDisabled={disabled || false}
        options={options}
        defaultValue={defaultValue}
        value={value ? options?.find(x => type === 'number' ? Number(x.value) === Number(value) : x.value === value) : null}
        onChange={(v) =>  type === 'number' ? onChange && onChange(Number(v?.value)) : onChange && onChange(v?.value)}
        onInputChange={onInputChange}
        inputValue={input}
        formatOptionLabel={render}
        styles={customStyles}
        className={className}
        theme={theme}
        placeholder={placeholder}
    />
}

