import React, {useState} from 'react';
import {IPageProps} from "../../components/page.props";
import {useParamRole} from "../../components/useParam";
import {Layout} from "../../components/foundation/layout";
import {TrainerDetail} from "../../components/admin/trainer/trainer_detail";
import {Trainer} from "../../components/admin/trainer/trainer";
import {withDesktop} from "../../api/withDesktop";



const DesktopTrainerPage: React.FC<IPageProps> = (props) => {
    const role = useParamRole(props.user);
    const gotoUrl = `/${role?.orgId}/${role?.funkMgId}/trainer`;
    const [refresh, setRefresh] = useState<number>(0)

    return (
        <Layout list={
            <Trainer {...props} gotoUrl={gotoUrl} refresh={refresh} setRefresh={setRefresh} />
        } page = {
            <TrainerDetail {...props} gotoUrl={gotoUrl} refresh={refresh} setRefresh={setRefresh} />
        }
        />
    );
};

export default withDesktop(DesktopTrainerPage);