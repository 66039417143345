import React from "react";
import styles from './Input.module.scss'
import classNames from "classnames";
import {number} from "prop-types";

interface IInputProps {
    onChange: (e: string) => void;
    defaultValue?: string;
    label?: string;
    name?: string;
    placeholder?: string;
    disabled?: boolean;
    maxLength?: number;
    value?:string|number|null;
    className?: string;
    required?: boolean;
    type?: string;  // Allow type to be optional and include 'text'
}


export const Password: React.FC<IInputProps> = ({
    name,
    label,
    placeholder,
    disabled,
    onChange,
    defaultValue,
    maxLength,
    value,
    className,
    required,
    type = 'password',
}) => {

    return (
        <>
            {label && <label className={styles.label}>{label}</label>}
            <input
                className={classNames(styles.input, className)}
                name={name}
                placeholder={placeholder}
                disabled={disabled}
                defaultValue={defaultValue}
                value={value||''}
                onChange={(v) => onChange(v.target.value)}
                maxLength={maxLength}
                type={type}
                required={required}
            />
        </>
    )
}