import React, { useState, useEffect } from 'react';
import { Card } from "../../components/foundation";
import { Email, Form, Item, Submit } from "../../components/form";
import { Password } from "../../components/form/password";
import styles from "./login.module.scss";
import { toaster } from "../../core/toaster";
import { AuthService } from "../../api";
import i18n from "../../config/i18n";
import { Link } from "react-router-dom";
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {toast} from "react-toastify";

interface ILoginProps {
    // Define any props here if needed in the future
}

export const Login: React.FC<ILoginProps> = () => {
    const [email, setEmail] = useState<string | null>(localStorage.getItem('email'));
    //const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [disableButton, setDisableButton] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        console.log(email);
    }, [email]);

    const login = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (disableButton) {
            return;
        }
        setDisableButton(true);
        localStorage.setItem('email', email || '');
        try {
            const loginSuccess = await AuthService.authorizePassword(email || '', password);
            if (loginSuccess) {
                console.log('login successful');
                setTimeout(() => {
                    window.location.href = '/admin';
                }, 250);
            } else {
                throw new Error("Invalid Password");
            }
        } catch (error) {
            toaster(() => { throw error; }, {
                success: i18n().login.success,
                failure: i18n().login.failure,
            });
        } finally {
            setDisableButton(false);
        }
    };

    return (
        <div className={styles.center}>
            <div className={styles.image} />
            <Card>
                <Form onSubmit={login}>
                    <Item>
                        <h3>{i18n().login.message}</h3>
                    </Item>

                    <Item type={'full'} label="Email">
                        <Email
                            name="email"
                            placeholder="Email"
                            value={email || ''}
                            onChange={(e) => {
                                console.log("Event in parent component: ", e);
                                setEmail(e);  // Directly update state with new value
                            }}
                        />
                    </Item>
                    <Item type={'full'} label="Password">
                        <div style={{ position: 'relative' }}>
                            <Password
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => setPassword(e)}
                            />
                            <button
                                type="button"
                                onClick={() => setShowPassword(!showPassword)}
                                style={{ color: 'gray', position: 'absolute', right: '1rem', top: '50%', transform: 'translateY(-50%)', background: 'none', border: 'none' }}
                                aria-label={showPassword ? 'Hide password' : 'Show password'}
                            >
                                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                            </button>
                        </div>
                    </Item>

                    <Submit label="Log In" name="login" disabled={disableButton} />

                    <Link to={"/password_request"}>Password vergessen?</Link>
                </Form>
            </Card>
        </div>
    );
};
