import React, {useState} from 'react';
import styles from "./login.module.scss";
import {Card} from "../../components/foundation";
import {Email, Form, Item, Submit} from "../../components/form";
import i18n from "../../config/i18n";
import {Password} from "../../components/form/password";
import {toaster} from "../../core/toaster";
import {AuthService} from "../../api";
import {Link} from "react-router-dom";
import {useParams} from "react-router";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";

interface IPasswordResetProps {

}

export const PasswordReset: React.FC<IPasswordResetProps> = ({}) => {
    const [email, setEmail] = useState<string | null>(localStorage.getItem('email'));
    const [password, setPassword] = useState<string>("");
    const [disableButton, setDisableButton] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState(false);

    const {token} = useParams();
    const reset = (e: any) => {
        e.preventDefault();
        if (disableButton) {
            return;
        }
        setDisableButton(true);
        localStorage.setItem('email', email||'');
        toaster(async() => {
            if (await AuthService.resetPassword(token||'', email||'', password)) {
                setTimeout(() => {
                    window.location.href = '/admin/login';
                }, 250)
                setTimeout(() => {
                    setDisableButton( false);
                }, 0);
            } else {
                throw new Error("Invalid Password");
            }

        }, {
            success: "Passwort erfolgreich zurückgesetzt", // i18n().login.success,
            failure: "Passwort zurücksetzen fehlgeschlagen", // i18n().login.failure,
        }, () => {
            setDisableButton( false);

        })
    }

    return (
        <div className={styles.center}>
            <div className={styles.image}/>
            <Card>
                <Form onSubmit={(evt) => reset(evt)}>
                    <Item>
                        <h3>{i18n().login.reset}</h3>
                    </Item>

                    <Item type={'full'} label="Email">
                        <Email
                            name="email"
                            //autocomplete="on"
                            placeholder="Email"
                            // autofocus={true}
                            value={email || ''}
                            onChange={setEmail}
                        />
                    </Item>

                    <Item type={'full'} label={"Password"}>
                        <Password
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={setPassword}
                        />
                        <button
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                            style={{
                                position: 'absolute',
                                color: 'gray',
                                right: '1rem',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                background: 'none',
                                border: 'none'
                            }}
                            aria-label={showPassword ? 'Hide password' : 'Show password'}
                        >
                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye}/>
                        </button>
                    </Item>
                    <Submit label="Password zurücksetzen" name="reset" disabled={disableButton}/>
                    <Link to={"/login"}>Zum Login</Link>
                </Form>
            </Card>
        </div>
    );
};