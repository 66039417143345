import React from 'react';

import styles from "./NewsSwitcherPage.module.scss";
import PersonalNews from './PersonalNews/PersonalNews';
import PublicNews from './PublicNews/PublicNews';
import {SquareSwitcher} from '../../../components/SquareSwitcher/SquareSwitcher';
import {INewsModel} from "../../../api/models/NewsModel";
import {withFilter} from "../../../api/withFilter";
import {Loading} from "../../../components/foundation/Loading";

import PullToRefresh from "react-simple-pull-to-refresh";
import {CacheService, NewsService} from "../../../api";

interface IMemberNewsProps {
    isPersonalNews?: boolean
}

interface IMemberNewsState {
    mode: boolean
    publicNews?: INewsModel[]
    personalNews?: INewsModel[]
    isLoaded: boolean
}

class MemberNewsPage extends React.Component<IMemberNewsProps, IMemberNewsState> {

    constructor(props: IMemberNewsProps) {
        super(props);
        this.state = {
            mode: props.isPersonalNews || false,
            isLoaded: false
        }
    }

    componentDidMount() {
        this.refreshNews(false)
    }

    componentWillUnmount(): void {
    }



    //on future for redirect
    modeSwitcher(modeName: 'MY NEWS' | 'ALL NEWS') {
        this.setState({mode: modeName === 'MY NEWS'})
    }

    async refreshNews(force: boolean): Promise<void> {
        // const { api } = this.props;
        this.setState({isLoaded: false});
        if (force) await CacheService.clear();
        const publicNews = await NewsService.getNews();
        const personalNews = await NewsService.getNotifications();

        this.setState({
            publicNews,
            personalNews,
            isLoaded: true
        })

    }

    render() {
        const {mode, isLoaded, publicNews, personalNews} = this.state
        return (
            <>

                <SquareSwitcher
                    firstElement='News'
                    secondElement='Notifications'
                    defaultValue={mode}
                    onChange={(mode) => this.setState({mode})}
                />
                <div className={styles.memberArea}>

                    <>{!isLoaded &&  <Loading />}</>
                    <PullToRefresh onRefresh={() => this.refreshNews(true)}>
                        {
                            this.state.mode ?
                            (<PersonalNews
                              {...this.props}
                              news={personalNews}
                              //isLoaded={isLoaded}
                              // doRefresh={() => ApiService.clearCache() && this.refreshNews()}
                            />)
                            :
                            (<PublicNews
                              {...this.props}
                              news={publicNews}
                              //isLoaded={isLoaded}
                              // doRefresh={() => ApiService.clearCache() && this.refreshNews()}
                            />)
                        }
                    </PullToRefresh>
                </div>
                {/*</IonContent>*/}

                {/*<IonLoading isOpen={!isLoaded}/>*/}
            </>
        )
    };
}

export default withFilter("News", {}, MemberNewsPage);
