import React, {useState} from 'react';
import {Tab} from "./Tab";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import styles from './tab_layout.module.scss'

export interface ITabLayoutItem {
    label: string;
    icon: IconProp;
    component: React.ReactElement;
}

interface ITabLayoutProps {
    items: ITabLayoutItem[];
}

export const TabLayout: React.FC<ITabLayoutProps> = ({items}) => {
    const [selected, setSelected] = useState<number>(0)

    return (
        <div className={styles.layout}>
            <div className={styles.header}>
                {items.map((item, i) => (
                    <Tab
                        key={`tab-${i}`}
                        icon={item.icon}
                        mode={'compressed'}
                        label={item.label}
                        active={selected === i}
                        onClick={() => {setSelected(i)}}
                    />
                ))}
            </div>
            {items[selected].component}
        </div>
    );
};