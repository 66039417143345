import React from 'react';

interface IToggleProps {
    value: number;
    options: string[];
    className?: string;
    onChange?: (v: number) => void;
}

const Toggle: React.FC<IToggleProps> = ({value, options, onChange, className}) => {

    const handleClick = () => {
        const v = (value + 1) % options?.length
        onChange && onChange( v )
    };

    return (
        <button onClick={handleClick} className={className}>
            {options[value]}
        </button>
    );
};

export default Toggle;