import React, {useEffect, useState} from 'react';
import {IAdminOrganization, IUserModel, IUserProfile} from "../../../../../../api/models";
import {withFilter} from "../../../../../../api/withFilter";
import {Loading} from "../../../../../../components/foundation/Loading";
import {ItemNavGroup} from "../../../../../../components/form/ItemNavGroup";
import {useParamRole} from "../../../../../../components/useParam";
import {toaster} from "../../../../../../core/toaster";
import {OrganizationService} from "../../../../../../api/organization";
import {ItemNav} from "../../../../../../components/form/ItemNav";
import {faBuilding, faPlus} from "@fortawesome/free-solid-svg-icons";

interface IAdminOrgOrganizationProps {
    user: IUserModel;
    profile: IUserProfile;
}

const AdminOrgOrganization: React.FC<IAdminOrgOrganizationProps> = ({user, profile}) => {
    const [loading, setLoading] = useState(true);
    const [organizations, setOrganizations] = useState<IAdminOrganization[]>([]);

    const role = useParamRole(user);

    useEffect(() => {
        toaster(async () => {
            setOrganizations(await OrganizationService.all(role.orgId))
        }, {}, () => setLoading(false))
    }, [role]);


    return (
        <>
            {loading ? <Loading/> : <>
                {role.orgId <= 10 && <ItemNav icon={faPlus} label={"Neue Organisation erstellen"}
                                              href={`/more/organization/edit/${role.funkMgId}/`}/>}
                <ItemNavGroup label={role.orgName}>
                    {organizations.map(o => (
                        <ItemNav icon={faBuilding} label={o.short}
                                 href={`/more/organization/edit/${role.funkMgId}/${o.id}`}/>
                    ))}
                </ItemNavGroup>
            </>}
        </>
    );
};

export default withFilter('Org', {}, AdminOrgOrganization, undefined, -1);