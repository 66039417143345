import {IMeeting, IOrgFunction, IUserModel} from "../../../../../../api/models";
import {withFilter} from "../../../../../../api/withFilter";
import {ItemNav} from "../../../../../../components/form/ItemNav";
import {useParams} from "react-router";
import React, {useEffect, useState} from "react";
import {ItemNavGroup} from "../../../../../../components/form/ItemNavGroup";
import styles from './AdminOrgMeetingRoles.module.scss'
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import {Loading} from "../../../../../../components/foundation/Loading";
import {MeetingService} from "../../../../../../api/meeting";
import {IUserProfile} from "../../../../../../api/models/AuthModel";
import {useNavigate} from "react-router-dom";


interface IOrganizationMeetingRolesProps {
    user: IUserModel;
    profile: IUserProfile;
}

const OrganizationMeetingRoles: React.FC<IOrganizationMeetingRolesProps> = ({user, profile}) => {
    const params = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [meeting, setMeeting] = useState<IMeeting | null>(null)

    const roleId = Number(params['role'] || 0);
    const examId = Number(params['id'] || 0);
    const role = user?.rolesOrg.map(x => x?.adminOrg || x?.trainerOrg).flat().find(x => x?.funkMgId === roleId) as IOrgFunction;

    useEffect(() => {
        if (role?.orgId) {
            MeetingService.get(role.orgId, examId).then((m) => {
                setMeeting(m);
                setLoading(false);
            })
        }
    }, [examId, role]);

    return <>

        {loading && <div className={styles.loading}><Loading/></div>}
        <ItemNav
            label={`Event Nr. ${examId} bearbeiten`}
            icon={faEdit}
            href={`/more/organization/meeting/${roleId}/${examId}/edit`}
        />
        <ItemNavGroup label={`${meeting?.name || 'Ohne Namen'}`}>
                {/* <Item type="form" className={styles.search}>
                        <Input onChange={setSearch} className={styles.name} />
                        <Toggle className={styles.toggle} options={[ 'alle', 'angemeldet', 'verfügbar', 'erlaubt']} value={filterState} onChange={setFilterState} />
                    </Item> */}
                {meeting?.param?.MEETINGROLES_REQ?.map(mr => {
                    console.log(profile.meetingRoles)
                    const meetingRole = profile.meetingRoles.find(x => x.id === mr);
                    if (meetingRole) {
                        return <ItemNav
                            key={meetingRole.name}
                            type="list"
                            label={meetingRole?.name}
                            onClick={()=>navigate(`/more/organization/meeting/${roleId}/${meeting?.id}/${mr}`)}
                        />;
                    } else {
                        return <ItemNav key={mr} label={`MeetingRoleId ${mr} not available`} />
                    }
                })}
        </ItemNavGroup>

    </>
}

export default withFilter('Event', {}, OrganizationMeetingRoles, undefined, -1);