import React, { useState, useEffect } from "react";
import styles from './Segment.module.scss';
import classNames from "classnames";

interface ISegmentProps {
    onChange?: (v: any) => any;
    defaultValue?: any;
    value?: any;
    options: { label: string, value: any, disabled?: boolean }[];
    className?: string;
    disabled?: boolean;
}

export const Segment: React.FC<ISegmentProps> = ({ onChange, defaultValue, options, value, className, disabled }) => {
    const isMultiSelect = Array.isArray(value) || Array.isArray(defaultValue);
    const [internalValue, setInternalValue] = useState<any>(defaultValue);

    useEffect(() => {
        if (value !== undefined) {
            setInternalValue(value);
        }
    }, [value]);

    //console.log('Segment Log:', { value, internalValue, defaultValue });

    const update = (v: any) => {
        if (!disabled) {
            let newValue: any;
            if (isMultiSelect) {
                newValue = Array.isArray(internalValue) ? internalValue : [];
                if (newValue.includes(v)) {
                    newValue = newValue.filter((item: any) => item !== v);
                } else {
                    newValue = [...newValue, v];
                }
            } else {
                newValue = v;
            }
            setInternalValue(newValue);
            onChange && onChange(newValue);
        }
    };

    const isActive = (optionValue: any) => {
        if (isMultiSelect) {
            return Array.isArray(value)
                ? value.includes(optionValue)
                : (Array.isArray(internalValue) && internalValue.includes(optionValue));
        } else {
            return value !== undefined ? value === optionValue : internalValue === optionValue;
        }
    };

    return (
        <div className={classNames(styles.segment, className, disabled && styles.disabled)}>
            {options.map(o => (
                <div
                    className={classNames(
                        styles.option,
                        isActive(o.value) && styles.active,
                        o.disabled && styles.optionDisabled
                    )}
                    onClick={() => !o.disabled && update(o.value)}
                    key={o.value}
                >
                    {o.label}
                </div>
            ))}
        </div>
    );
};

// import React, { useState } from "react";
// import styles from './Segment.module.scss';
// import classNames from "classnames";
//
// interface ISegmentProps {
//     onChange?: (v: any) => any;
//     defaultValue?: any;
//     value?: any;
//     options: { label: string, value: any, disabled?: boolean }[];
//     className?: string;
//     disabled?: boolean;
// }
//
// export const Segment: React.FC<ISegmentProps> = ({ onChange, defaultValue, options, value, className, disabled }) => {
//     const [internalValue, setValue] = useState(defaultValue);
//
//     const update = (v: any) => {
//         if (!disabled) {
//             setValue(v);
//             onChange && onChange(v);
//         }
//     };
//
//     return (
//         <div className={classNames(styles.segment, className, disabled && styles.disabled)}>
//             {options.map(o => (
//                 <div
//                     className={classNames(
//                         styles.option,
//                         ((o.value === value) || (defaultValue && o.value === internalValue)) && styles.active,
//                         o.disabled && styles.optionDisabled
//                     )}
//                     onClick={() => !o.disabled && update(o.value)}
//                     key={o.value}
//                 >
//                     {o.label}
//                 </div>
//             ))}
//         </div>
//     );
// };