import {Constants} from "./constants";
import {HttpService} from "./http";
import {AuthService} from "./auth";
import {IMember, IMemberDuplicate, IRole, ITraining} from "./models/MemberModel";
import {IMeetingCandidate} from "./models";
import {IMAGE_URL} from "./util";
import {CacheService} from "./cache";
import {IKyuChairMember} from "./models";


const GET_MEMBERS = `${Constants.JAMA_SERVER_API_V2}/admin/:org/members`;
const GET_MEMBER = `${Constants.JAMA_SERVER_API_V2}/admin/:org/member/`;
const GET_MEMBER_TRAININGS = `${Constants.JAMA_SERVER_API_V2}/admin/:org/member/trainings/`;
const GET_MEMBER_ROLES = `${Constants.JAMA_SERVER_API_V2}/admin/:org/member/roles/`;
const POST_MEMBER = `${Constants.JAMA_SERVER_API_V2}/admin/:org/member`;
const PUT_MEMBER = `${Constants.JAMA_SERVER_API_V2}/admin/:org/member`;
const TERMINATE_MEMBER = `${Constants.JAMA_SERVER_API_V2}/admin/:org/member/terminate`
const REACTIVATE_MEMBER = `${Constants.JAMA_SERVER_API_V2}/admin/:org/member/reactivate`
const PUT_MEMBER_ASSIGN_SIMPLE = `${Constants.JAMA_SERVER_API_V2}/admin/:org/member/assign/`
const PUT_MEMBER_ASSIGN_ROLE = `${Constants.JAMA_SERVER_API_V2}/admin/:org/member/assign/role`;
const PUT_MEMBER_ASSIGN_TRAINING = `${Constants.JAMA_SERVER_API_V2}/admin/:org/member/assign/training`;
const PUT_MEMBER_SWITCH_ORG = `${Constants.JAMA_SERVER_API_V2}/admin/:org/member/switch`;
const GET_MEMBERS_BY_NAME = `${Constants.JAMA_SERVER_API_V2}/admin/:org/member/search`;

//const PUT_LICENCE_SUBMIT = `${Constants.JAMA_SERVER_API_V2}/admin/:org/licence/submit/`;
//const PUT_LICENCE_APPROVE = `${Constants.JAMA_SERVER_API_V2}/admin/:org/licence/approve/`;

const R = (r: string, org: number) => r.replace('/:org/', `/${org}/`);

export interface IHandleAdminLicenceCreateReq {
    categoryId: number;
}

interface IHandleAdminLicenceUpdateReq {
    id: number;
    name: string;
    date: string;
}

/**
 * A class that provides methods for interacting with Licence orders and candidates.
 */
export class MemberService {

    public static imageUrl(uuid?: string) {
        if (uuid) {
            return IMAGE_URL + uuid + `?v=${(Date.now() / 1000000).toFixed(0)}`;
        } else {
            return undefined;
        }
    }
    
    public static async all(org: number): Promise<IMember[]> {
        await AuthService.refreshJwt();
        const res = await HttpService.get<IMember[]>(R(`${GET_MEMBERS}`, org), true);
        return res || [];
    }

    public static async get(org: number, id: number): Promise<IMember> {
        await AuthService.refreshJwt();
        const res = await HttpService.get<IMember>(R(`${GET_MEMBER}${id}`, org), true);
        return res || []
    }

    public static async update(org: number, member: IMember): Promise<IMember> {
        await AuthService.refreshJwt();
        return await HttpService.put<IMember>(R(PUT_MEMBER, org), member);
    }


    public static async create(org: number, member: IMember): Promise<{created?: IMember, duplicates?: IMemberDuplicate[]}> {
        await AuthService.refreshJwt();
        return await HttpService.post2<IMember, {created?: IMember, duplicates?: IMemberDuplicate[]}>(POST_MEMBER.replace('/:org/', `/${org}/`), member);
    }

    public static async assignRoleSimple(org: number, memberId: number, role: 'MEMBERSHIP'): Promise<Boolean> {
        await AuthService.refreshJwt();
        let roleId = 0;
        switch (role) {
            case "MEMBERSHIP":
                roleId = 32
                break;
        }
        await HttpService.post<void>(R(`${PUT_MEMBER_ASSIGN_SIMPLE}${memberId}/${roleId}`, org) ,null);
        await CacheService.clear();
        return true;
    }

    public static async trainings(org: number, memberId: number): Promise<ITraining[]> {
        await AuthService.refreshJwt();
        const res = await HttpService.get<ITraining[]>(R(`${GET_MEMBER_TRAININGS}${memberId}`, org), true);
        return res || []
    }

    public static async roles(org: number, memberId: number): Promise<IRole[]> {
        await AuthService.refreshJwt();
        const res = await HttpService.get<IRole[]>(R(`${GET_MEMBER_ROLES}${memberId}`, org), true);
        return res || []
    }

    // get expired roles
    public static async rolesExpired(org: number, memberId: number): Promise<IRole[]> {
        await AuthService.refreshJwt();
        const res = await HttpService.get<IRole[]>(R(`${GET_MEMBER_ROLES}${memberId}/expired`, org), true);
        return res || []
    }

    public static async assignRole(org: number, memberId: number, orgId: number, role: number, start?: string, end?: string): Promise<Boolean> {
        await AuthService.refreshJwt();
        const body = {
            memberId,
            orgId,
            role,
            start,
            end,
        }
        await HttpService.put<any>(R(`${PUT_MEMBER_ASSIGN_ROLE}`, org), body);
        await CacheService.clear();
        return true;
    }

    public static async assignTraining(org: number, memberId: number, orgId: number, training: number, start: string, end?: string): Promise<Boolean> {
        await AuthService.refreshJwt();
        const body = {
            memberId,
            orgId,
            training,
            start,
            end,
        }
        await HttpService.put<any>(R(`${PUT_MEMBER_ASSIGN_TRAINING}`, org), body);
        await CacheService.clear();
        return true;
    }

    public static async switchOrg(org: number, fromOrg: number, toOrg: number, memberId: number, date?: string) {
        await AuthService.refreshJwt();
        const body = {
            id: memberId,
            fromOrg,
            toOrg,
            date,
        };
        await HttpService.put<any>(R(`${PUT_MEMBER_SWITCH_ORG}`, org), body )
        await CacheService.clear();
    }

    public static async terminate(org: number, memberOrgId: number, memberId: number, date: string) {
        await AuthService.refreshJwt();

        const body = {
            id: memberId,
            orgId: memberOrgId,
            date,
        };
        await HttpService.put<any>(R(`${TERMINATE_MEMBER}`, org), body);
        await CacheService.clear();
    }

    public static async reactivate(org: number, memberOrgId: number, memberId: number) {
        await AuthService.refreshJwt();

        const body = {
            id: memberId,
            orgId: memberOrgId
        };
        await HttpService.put<any>(R(`${REACTIVATE_MEMBER}`, org), body);
        await CacheService.clear();
    }

    public static async searchMembersByName(orgId: number, /*date: string, */ name: string) {
        await AuthService.refreshJwt();
        return await HttpService.get<IMeetingCandidate>(
            `${GET_MEMBERS_BY_NAME.replace('/:org/', `/${orgId}/`)}/${name}`);
            // `${GET_MEMBERS_BY_NAME.replace('/:org/', `/${orgId}/`)}/${date}/${id}`);
    }

    /*
        public static async categories(org: number) : Promise<ILicenceCategory[]> {
            await AuthService.refreshJwt();
            const res = await HttpService.get<ILicenceCategory[]>(`${GET_LICENCE_CATEGORIES}`.replace('/:org/', `/${org}/`), true);
            return res || [];
        }

        public static async candidates(org: number, id: number): Promise<ILicenceCandidate[]> {
            await AuthService.refreshJwt();
            const res = await HttpService.get<ILicenceCandidate[]>(`${GET_LICENCE_CANDIDATES}${id}`.replace('/:org/', `/${org}/`), true)
            return res || [];
        }

        public static async update(org: number, orderId: number, memberId: number, remove: boolean): Promise<boolean> {
            await AuthService.refreshJwt();
            const res = await HttpService.put2<{
                id: number,
                mgId: number,
                remove: boolean
            }, boolean>(PUT_LICENCE_CANDIDATE.replace('/:org/', `/${org}/`), {
                id: orderId,
                mgId: memberId,
                remove,
            });
            return res || false;
        }

        public static async create(org: number, data: IHandleAdminLicenceCreateReq) {
            await AuthService.refreshJwt();
            return await HttpService.post2<IHandleAdminLicenceCreateReq, number>(POST_LICENCE.replace('/:org/', `/${org}/`), data);
        }

        public static async submit(org: number, id: number) {
            await AuthService.refreshJwt();
            return await HttpService.put2<null, boolean>(PUT_LICENCE_SUBMIT.replace('/:org/', `/${org}/`) + id, null);
        }

        public static async approve(org: number, id: number) {
            await AuthService.refreshJwt();
            return await HttpService.put2<null, boolean>(PUT_LICENCE_APPROVE.replace('/:org/', `/${org}/`) + id, null);
        }

        public static async delete(org: number, id: number) {
            await AuthService.refreshJwt();
            return await HttpService.delete<boolean>(PUT_LICENCE.replace('/:org/', `/${org}/`) + `/${id}`);
        }*/
}