
import styles from './NewsDetail.module.scss';

import React, {useEffect, useState} from "react";
import {INewsModel} from "../../../api/models/NewsModel";
import {withFilter} from "../../../api/withFilter";
import {useParams} from "react-router";
import {IUserModel} from "../../../api/models/UserModel";
import {EFileVersion, NewsService, UtilService} from "../../../api";

interface INewsDetailPageProps {
    user: IUserModel;
}

const NewsDetailPage: React.FC<INewsDetailPageProps> = ({user}) => {
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [news, setNews] = useState<INewsModel|null>(null);

    useEffect(() => {
        refresh();
    }, [user])

    const refresh = async(): Promise<void> => {
        setLoading(true);
        setNews(await NewsService.getNewsArticle(Number(params['id'] || 0)));
        setLoading(false);
    }
    console.log(news);

    return (
        <div className={styles.news}>
            {news && <>
                <h1 dangerouslySetInnerHTML={{__html: news.title}}></h1>
                <p dangerouslySetInnerHTML={{__html: news.abstract}}></p>

                {news?.attachedFiles?.map((image, i) => (
                        <img
                            key={`detail-${image.fileId}`}
                            src={UtilService.getFileUrl(image, EFileVersion.md)}
                            alt={image.fileName}/>
                ))}
                <div className={styles.content}
                     dangerouslySetInnerHTML={{__html: news?.content || news?.abstract}}/>

            </>}
            {/*     <IonHeader className="my-title">
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref={`/news`}/>
                        </IonButtons>
                        <IonTitle>
                          <span className={classNames("my-title-text", styles.title)}>
                              {
                                  'NEWS'
                              }
                          </span>
                        </IonTitle>
                        <IonTitle slot="end">
                            <span
                                className={Capacitor.getPlatform() === 'iphone' ? 'logo logo-iphone' : 'logo logo-android'}/>
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent className={styles.container}>
                    {detail && (
                        <IonCard className={styles.card}>
                            <IonCardHeader>
                                <IonCardTitle>
                                        <span className={styles.containerHeader}
                                              dangerouslySetInnerHTML={{__html: detail.title}}/>
                                </IonCardTitle>
                            </IonCardHeader>
                            <IonCardContent className="ion-no-padding">
                                {(detail && detail.attachedFiles) && detail?.attachedFiles.map((image, i) => (
                                    <img
                                        key={`detail-${image.fileId}`}
                                        src={ApiService.getFileUrl(image, EFileVersion.md)}
                                        alt={image.fileName}/>
                                ))}
                                <div className={styles.containerContent}
                                     dangerouslySetInnerHTML={{__html: detail?.content || detail?.abstract}}/>

                            </IonCardContent>
                        </IonCard>

                    )}
                </IonContent>*/}
        </div>);

}

export default withFilter("News", {}, NewsDetailPage, undefined, "/news");
