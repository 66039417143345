import React, { useEffect, useMemo, useState } from 'react';
import { useParamId, useParamRole } from "../../useParam";
import { ILicenceCandidate, ILicenceOrder } from "../../../api/models";
import { LicenceService } from "../../../api/licence";
import { CacheService, IMAGE_URL } from "../../../api";
import { ItemNavGroup } from "../../form/ItemNavGroup";
import { ItemNav } from "../../form/ItemNav";
import { faCircleCheck, faPaperPlane, faRemove } from "@fortawesome/free-solid-svg-icons";
import { FD, Input, Item, Segment } from "../../form";
import styles from "./licence_detail_candidates.module.scss";
import classNames from "classnames";
import AvatarImage from "../../foundation/AvatarImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IPageLinkProps } from "../../page.props";

export const LicenceDetailCandidates: React.FC<IPageLinkProps> = ({ user, profile, gotoUrl, refresh, setRefresh }) => {
    const role = useParamRole(user)
    const [loading, setLoading] = useState(true)
    const [candidates, setCandidates] = useState<ILicenceCandidate[]>([])
    const [search, setSearch] = useState<string>('')
    const [filterState, setFilterState] = useState<number>(1)
    const [order, setOrder] = useState<ILicenceOrder | null>(null)

    const id = useParamId()

    useEffect(() => {
        LicenceService.get(role?.orgId || 0, id || 0).then((o) => {
            if (order == null) {
                if (o.orgId === role?.orgId) {  // self order
                    console.log('orgId === role?.orgId')
                    //setFilterState(2);
                    if (o.status == 'submitted') {
                        setFilterState(1);
                    } else if (o.status == 'approved') {
                        console.log('status===approved', o.status === 'approved')
                        setFilterState(1);
                        console.log('filterState-approved', filterState)
                        // } else if (o.status != 'created') {
                        //     setFilterState(1);
                    } else {
                        setFilterState(2) // LV
                    }
                } else {
                    console.log('orgId != role?.orgId')
                    setFilterState(2) // LV
                }
            }else {
                setFilterState(2); // fresh start
            }
            //setOrder(o)
            console.log('filterState-end', filterState)
        })
    }, [role, id, candidates, order]);

    useEffect(() => {
      console.log('filterState updated:', filterState);
    }, [filterState]);

    useEffect(() => {
        refreshJudoCards(true)
    }, [user, id])
useEffect(() => {
  if (order?.status === 'approved') {
    setFilterState(1);
    console.log('Setting filterState to 1 for approved status');
  }
}, [order]);
    const filter = useMemo(() => {
        return candidates.filter(x =>
            (x.firstname?.toLowerCase()?.includes(search?.toLowerCase()) ||
                x.surname?.toLowerCase()?.includes(search?.toLowerCase()) ||
                `${x.mgId}`.includes(search)) &&
            (filterState === 0 ||
                (filterState === 1 ? x.ordered :
                    filterState === 2 ? (!x.ordered || x.ordered) && !x.errorCode : !x.errorCode)));
    }, [candidates, filterState, search]);

    const refreshJudoCards = async (force: boolean) => {
        setLoading(true);
        if (force) await CacheService.clear();
        if (role != null) {
            setCandidates(await LicenceService.candidates(role.orgId, id || 0))
        }
        setLoading(false);
    }

    const locked = order?.status !== 'created'
    // const select = async (candidate: ILicenceCandidate) => {
    //     if ((!candidate.errorCode || candidate.ordered) && !locked && (candidate?.orgId === role?.orgId)) {
    //         await LicenceService.update(role.orgId, id || 0, candidate.mgId, candidate.ordered)
    //         await refreshJudoCards(true);
    //         setRefresh && setRefresh((refresh || 0) + 1);
    //         if (filterState === 2) {
    //             setCandidates(prevCandidates => prevCandidates.filter(c => c.mgId !== candidate.mgId));
    //         }
    //     }
    // }

    const select = async (candidate: ILicenceCandidate) => {
        if ((!candidate.errorCode || candidate.ordered) && !locked && (candidate?.orgId === role?.orgId)) {
            await LicenceService.update(role.orgId, id || 0, candidate.mgId, candidate.ordered);
            await refreshJudoCards(true);
            setRefresh && setRefresh((refresh || 0) + 1);
        }
    }

    const renderFilterOptions = (): { label: string; value: number; }[] => {
        if (order !== null && role?.orgId == order.orgId) {
            return [
                { label: 'alle', value: 0 },
                { label: 'ausgewählte', value: 1 },
                { label: 'ausgewählte & verfügbare', value: 2 }
            ];
        }
        return [
            //{ label: 'alle', value: 0 },
            { label: 'ausgewählte', value: 1 },
            //{ label: 'ausgewählte & verfügbare', value: 2 }
        ];
    };

    return (
        <div>
            <ItemNavGroup label={`Bestellung Nr. ${order?.id}`}>
                {order?.orgId ? (
                    <>
                        {/*order.canSubmit && */order.items > 0 && order.status === 'created' && order.orgId === role?.orgId && (
                            <ItemNav
                                className={styles.submit}
                                primary={true}
                                align="center"
                                label={`Bestellung #${order.id} abschicken`}
                                icon={faPaperPlane}
                                href={`${gotoUrl}/${id}?t=submit`}
                            />
                        )}
                        {order.canApprove && order.items > 0 && order.status === 'submitted' && (
                            <ItemNav
                                className={styles.approve}
                                primary={true}
                                align="center"
                                label={`Bestellung #${order.id} freigeben`}
                                icon={faPaperPlane}
                                href={`${gotoUrl}/${id}?t=approve`}
                            />
                        )}
                        {/*order.canSubmit && order.items === 0 && */ (order.status === 'created' || order.status === 'submitted') && (
                            <ItemNav
                                className={styles.delete}
                                primary={true}
                                align="center"
                                label={`Bestellung #${order.id} Löschen`}
                                icon={faRemove}
                                href={`${gotoUrl}/${id}?t=delete`}
                            />
                        )}
                    </>
                ) : ''}
                <Item type="full" className={styles.search}>
                    <Input onChange={setSearch} className={styles.name} value={search}/>
                </Item>

                {order?.status == 'approved' ? (
                    <Segment
                        className={styles.filter}
                        value={filterState}
                        onChange={setFilterState}
                        options={renderFilterOptions()}
                    />
                ) : ''}

                {filter?.map(jc => (
                    <div
                        className={classNames(styles.judocard, jc.ordered && styles.active)}
                        key={jc.mgId}>
                        <div className={styles.card} onClick={() => select(jc)}>
                            <div className={styles.imageContainer}>
                                <AvatarImage
                                    className={styles.image}
                                    src={IMAGE_URL + jc?.uuid + `?v=${(Date.now() / 1000000).toFixed(0)}`}
                                />
                            </div>
                            <div className={styles.content}>
                                <div className={styles.text}>
                                    <div className={styles.title}>{jc.surname} {jc.firstname} / {jc.mgId}</div>
                                    <div className={styles.subtitle}>Geb.Datum: {FD(jc.birthdate)}</div>
                                </div>
                                {order?.orgId === role?.orgId && order?.status !== 'approved' && order?.status !== 'submitted' && jc.errorMessage && (
                                    <div className={styles.error}>{jc.errorMessage}</div>
                                )}
                                {jc.ordered && <div className={styles.selected}></div>}
                            </div>
                        </div>
                    </div>
                ))}
            </ItemNavGroup>
        </div>
    );};