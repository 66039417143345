import React, {useEffect, useState} from 'react';
import {useParamId, useParamRole} from "../../../../../../components/useParam";
import {ILicenceCandidate, IUserModel} from "../../../../../../api/models";
import {withFilter} from "../../../../../../api/withFilter";
import {ItemNavGroup} from "../../../../../../components/form/ItemNavGroup";
import {Button, Item} from "../../../../../../components/form";
import styles from "./AdminOrgLicenceSubmit.module.scss";
import {useNavigate} from "react-router-dom";
import {toaster} from "../../../../../../core/toaster";
import {CacheService} from "../../../../../../api";
import {LicenceService} from "../../../../../../api/licence";

interface IAdminOrgLicenceApproveProps {
    user: IUserModel
}

 const AdminOrgLicenceApprove: React.FC<IAdminOrgLicenceApproveProps> = ({user}) => {
     const role = useParamRole(user)
     const navigate = useNavigate();
     const id = useParamId()
     const [candidates, setCandidates] = useState<ILicenceCandidate[]>()

     useEffect(() => {
         toaster(async () => {
                 await CacheService.clear()
                 setCandidates(await LicenceService.candidates(role.orgId, id||0))
                 return null
             }, {
                 success: null, failure: "Fehler beim laden"
             }
         )

     }, [role, id]);
     const submit = () => {
         toaster(async () => {
                 await LicenceService.approve(role.orgId, id||0)
                 return
             }, {
                 success: "Bestellung freigegeben",
                 failure: "Fehler beim Freigeben"
             }, () => navigate(`/more/organization/licence/${role?.funkMgId}/`)

         )
     }
    return (
        <div>
            <ItemNavGroup label={`Bestellung #${id}`}>
                <>
                    {candidates?.filter(x => x.ordered)?.map(c => (
                        <Item key={c.mgId} type={"list"} label={`${c.surname} ${c.firstname}`}></Item>

                    ))}
                </>
            </ItemNavGroup>

            <Button label="Freigeben" className={styles.save}
                    onClick={submit} disabled={false}/>
        </div>
    );
};

export default withFilter('Judocard', {}, AdminOrgLicenceApprove, undefined, -1);