import React, {useEffect, useState} from 'react';

import styles from './Gallery.module.scss'
import moment from "moment";
import {Content, Header, TContentState} from "../../../components/foundation";
import {IGalleryModel, IGalleryCollectionModel} from '../../../api/models/GalleryModel';


import { Thumb } from '../../../components/form/Thumb';
import {IRouteComponentProps} from "../../../components/withRouter";
import {IWithFilterProps, withFilter} from "../../../api/withFilter";
import PullToRefresh from "react-simple-pull-to-refresh";
import {useNavigate} from "react-router-dom";
import {IUserModel} from "../../../api/models/UserModel";
import {CacheService, GalleryService} from "../../../api";
import {ITrainerJudocardFilter} from "../../../api/models";

export type GalleryFiltersType = {
    dates: {
        from: string | null,
        to: string | null
    }
    search: string
    cities: string[]
}

interface IGalleryProps extends IRouteComponentProps<void>   {
    user: IUserModel;
}

interface IGalleryState {
    galleries: IGalleryModel[]
    // nativeGalleries: IGalleryModel[]
    // selectedGallery: IGalleryModel | null
    citiesOptions: string[]
    //collection: IGalleryCollectionModel[];
    loading: boolean;
    filters: GalleryFiltersType;
    state: TContentState;
}


const GalleryFilter: React.FC<IWithFilterProps<ITrainerJudocardFilter>> = ({filter}) => {
    //GalleryFilters setFilters={(filters) => this.filter(filters)} citiesOptions={citiesOptions}/>
    return <p>test</p>
}


const GalleryPage: React.FC<IGalleryProps> = ({user}) => {
    const [loading, setLoading] = useState(true);
    const [galleries, setGalleries] = useState<IGalleryModel[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        refresh(false);
    }, [user]);

    const refresh = async(force: boolean): Promise<void> => {
        setLoading(true);
        if (force) await CacheService.clear();
        const galleries = await GalleryService.getGalleries();
        setGalleries(galleries);
        /*this.setState({
            galleries,
            state: 'active',
        });*/
        setLoading(false);
    }


    const refreshGallery = async (): Promise<void>  => {
        /*const {api} = this.props;
        const galleries = await api.getGalleries()

        this.setState(
            {
                galleries,
                nativeGalleries: galleries,
                loading: false,
                citiesOptions: this.generateCitiesOptions(galleries)
            });
        */
    }

    const setCollection = async (id?: number) => {
        //const { history } = this.props;
       // history.push(`/gallery/${id}`);
        /*const {galleries} = this.state;
        this.setState({loading: true});
        console.log(id)
        try {
            if (id) {
                const collection = await this.props.api.getCollection(id || 0)
                this.setState({
                    collection,
                    selectedGallery: galleries.find(g => g.id === id) || null,
                    loading: false,
                })
            } else {
                this.setState({
                    collection: [],
                    selectedGallery: null,
                    loading: false,
                })
                console.log('asdf')
            }
        } catch (e) {
            console.error(e);
            this.setState({loading: false})
        }*/
    }

    /*async doRefresh(evt: CustomEvent<RefresherEventDetail>) {
        console.log('dorefresh')
        this.props.api.clearCache();
        await this.refreshGallery()
        evt.detail.complete();
    };*/

    //const filter = (filters: GalleryFiltersType) => {
    //    const {dates, search, cities} = filters
        // const {nativeGalleries} = this.state

            //const arr = [...nativeGalleries]

        /*const result = arr
            .filter((g) => {
                return g.title.includes(search)
            })
            .filter((g) => {
                if(dates.to && dates.from && g.date) {
                    const mls = this.getTime(g.date)
                    return mls <= this.getTime(dates.to) && mls >= this.getTime(dates.from)
                }
                return true
            })
            .filter((g) => cities.length ? cities.includes(g.city) : true)

        this.setState({
            galleries: result
        })*/

    //}

    const getTime = (date: string) => {
        return moment(date).valueOf() as number
    }

    /*generateCitiesOptions(arr: IGalleryModel[]): string[] {
        //return arr.filter((g) => g.city && g.city.length > 0).map((g) => g.city)
        return [];
    }*/


    // const {collection} = this.state;
    // const {galleries, loading, citiesOptions, selectedGallery} = this.state;
    // const {galleries, state} = this.state;
    //console.log('render')
    const filter = [

    ]
    return (
        <>
            <PullToRefresh onRefresh={() => refresh(true)} >
                <div>
                    {galleries.map(g => (
                        <Thumb
                            onClick={() => navigate(`/gallery/${g.id}`)}
                            key={`gallery-${g.id}`}
                            image={g.imageURL}
                            date={g.date}
                            title={g.title}
                            className={styles.galleryImg}
                        />
                    ))}
                </div>
            </PullToRefresh>
        </>
    )
}

export default withFilter('Gallery', {sub: ['galleries']}, GalleryPage, /*GalleryFilter*/ undefined) ;

/*
    constructor(props: IGalleryProps) {
        super(props);
        this.state = {
            galleries: [],
            // nativeGalleries: [],
           // collection: [],
            // selectedGallery: null,
            loading: true,
            filters: {
                dates: {
                    from: null,
                    to: null
                },
                search: '',
                cities: []
            },
            citiesOptions: [],
            state: 'loading'
        }
    }
 */