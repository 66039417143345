import React, {useState} from 'react';

import styles from './AdminTrainerNomination.module.scss';
import {withFilter} from "../../../../../api/withFilter";

interface ITrainerNominationProps {
    members: any[] //IMember[];
    filter: any;
}

/*interface ITrainerNennungenState {
    roleId: number;
    selected: number[];
}*/

const TrainerNominationFilter: React.FC<ITrainerNominationProps> = ({members, filter}) => {
    return <p>todo add filter</p>
};


const TrainerNomination: React.FC<ITrainerNominationProps> = ({members, filter}) => {
    const [selected, setSelected] = useState<number[]>([]);

    const select = (jamaId: number) => {
        if (!selected.includes(jamaId)) {
            setSelected([jamaId].concat(selected));
        } else {
            setSelected(selected.filter(x => x !== jamaId));
        }
        console.log(jamaId)
    }


    return (
        <div className={styles.nominations}>
            {members.map((jc, i) => (
                <div
                    className={`judocard
                                    ${jc.lastJudocardYear ? '' : 'disabled'}
                                    ${selected.includes(jc.jamaId) ? 'selected' : ''}`}
                    key={jc.jamaId}
                    onClick={() => jc.lastJudocardYear && select(jc.jamaId)}
                >
                    <div className="card">
                        <img className="image" src={`https://picsum.photos/id/${i}/200/250`}/>
                        <div className="label">
                            <p className="title">{jc.firstName} {jc.lastName}</p>
                            <p className="subtitle">{jc.birthDate}</p>
                            <p>{jc.club}</p>
                        </div>
                    </div>
                </div>
            ))}

        </div>
    )
};


export default withFilter('Nennungen', {sub: ['roles', 'tournaments', 'members']}, TrainerNomination, TrainerNominationFilter);
