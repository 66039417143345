import React, { useEffect, useState } from 'react';
import { IPageLinkProps } from "../../page.props";
import { useNavigate } from "react-router-dom";
import {IMeeting, IMeetingRole, IMeetingTypes} from "../../../api/models";
import { MeetingService } from "../../../api/meeting";
//import styles from "../../../pages/mobile/more/admin/organization/meeting/AdminOrgMeetingRoles.module.scss";
import { Loading } from "../../foundation/Loading";
import { ItemNav } from "../../form/ItemNav";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { ItemNavGroup } from "../../form/ItemNavGroup";
import { useParamId, useParamRole } from "../../useParam";
import {Input, Item, Select, FD, Form, Segment} from "../../form";
import {SelectMulti} from "../../form/SelectMulti";
import {InputArea} from "../../form/InputArea";

import styles from './event_detail.module.scss';

export const EventDetailRoles: React.FC<IPageLinkProps> = ({ user, profile, gotoUrl }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [meeting, setMeeting] = useState<IMeeting | null>(null);

    const examId = useParamId();
    const role = useParamRole(user);

    useEffect(() => {
        if (role?.orgId) {
            MeetingService.get(role.orgId, examId || 0).then((m) => {
                setMeeting(m);
                setLoading(false);
            });
        }
    }, [examId, role]);

    if (loading) {
        return <div className={styles.loading}><Loading/></div>;
    }

    console.log('meeting: ', meeting?.meetingtype_id);

    const meetingRoles = meeting?.param?.MEETINGROLES_REQ ||
        JSON.parse(meeting?.param?.MEETINGROLES || '[]') as number[];

    const filteredRoles = meetingRoles.filter(x => (meeting?.org_id === role.orgId || meeting?.org_mutter_id === role.orgId || 1=== role.orgId) || x === 9);

    function getMeetingTypeNameById(meetingTypes: IMeetingTypes[], id: number): string | undefined {
        const meetingType = meetingTypes.find(mt => mt.id === id);
        return meetingType?.name;
    }


    let meetingTypeName;
    if (Array.isArray(profile.meetingTypes) && meeting?.meetingtype_id !== undefined && typeof meeting.meetingtype_id === 'number') {
        meetingTypeName = getMeetingTypeNameById(profile.meetingTypes, meeting.meetingtype_id);
    } else {
        meetingTypeName = 'Meeting Type not found';
    }

    let disabeldRoleSelect = false;
    if (meeting?.open_from && meeting?.open_to && new Date(meeting.open_from) <= new Date() && new Date(meeting.open_to) >= new Date()) {
        disabeldRoleSelect = false;
    } else {
        disabeldRoleSelect = true;
    }

    //console.log('filteredRoles: ', filteredRoles)

    return (
        <>
            {
                //console.log('editable: ', meeting?.org_id, role.orgId, meeting?.org_id === role.orgId )
                ///console.log('profile.meetingTypes: ', profile?.meetingTypes)
            }

                <ItemNavGroup label={`${meeting?.name || 'Ohne Namen'}`} >
                    <Form>

                    <Item type="full" label="Veranstalter" size={6}>
                        <div className={styles.children}>{Array.isArray(profile.organizations) ?
                            profile.organizations.find(org => org.id === meeting?.org_id)?.nameShort : ''}

                        </div>
                    </Item>
                        <Item type="full" label="Meeting Type" size={6}>
                            <div className={styles.children}>
                                {meetingTypeName}
                            </div>
                        </Item>
                        <Item type="full" label="Altersklassen" size={12}>
                            <div className={styles.children}>
                                {/*{meeting.param?.ty || ' '}*/}
                                <div>{meeting?.ages_nennbar && typeof meeting.ages_nennbar === 'object' && Array.isArray(meeting.ages_nennbar.m)
                                    && (meeting.ages_nennbar.m).length>0 ? `M: ${meeting.ages_nennbar.m.join(', ')}` : 'keine AK für Herrn definiert'}</div>
                                <div>{meeting?.ages_nennbar && typeof meeting.ages_nennbar === 'object'
                                    && Array.isArray(meeting.ages_nennbar.w) && (meeting.ages_nennbar.m).length>0 ? `W: ${meeting.ages_nennbar.w.join(', ')}` : 'keine AK für Damen definiert'}</div>
                            </div>
                        </Item>
                    <Item type="full" label="Nennungzeitraum" size={6}>
                        <div className={styles.children}>{FD(meeting?.open_from)} - {FD(meeting?.open_to)}</div>
                    </Item>
                        <Item type="full" label="Event Datum" size={6}>
                            <div className={styles.children}>{FD(meeting?.startdate)} - {FD(meeting?.enddate)}</div>
                        </Item>

                    <Item type="full" label="Ort" size={12}>
                        <div className={styles.children}>{meeting?.param?.ORT || ''}</div>
                    </Item>

                    <Item type="full" label="Nationencheck?" size={6}>
                        <div className={styles.children}>{meeting?.param?.NATIONENCHECK ? "JA" : "Nein"}</div>
                    </Item>
                    <Item type="full" label="Bundesweite Nennung?" size={6}>
                        <>
                        { console.log('meeting?.param?.BUNDESWEIT: ', meeting?.param?.BUNDESWEIT) }
                        </>
                        <div className={styles.children}>{meeting?.param?.BUNDESWEIT === true ? "JA" : meeting?.param?.BUNDESWEIT === false ? "Nein" : '1x speichern!'}</div>
                    </Item>

                    <Item type="full" label="Url" size={6}>
                        <div className={styles.children}>{(meeting?.param?.URL || ' ')}</div>

                    </Item>
                    <Item type="full" label="Streaming Url" size={6}>
                        <div className={styles.children}>{(meeting?.param?.STREAMING_URL || ' ')}</div>

                    </Item>

                        {/*<Item type="full" label="Teilnahme" size={6}>*/}
                        {/*    <div className={styles.children}>{meeting?.param?.TEILNAHME || ' '}</div>*/}
                        {/*</Item>*/}
                    <Item type="full" label="Anmerkungen">
                        <div className={styles.children}>{meeting?.param?.TEXT || ' '}</div>
                    </Item>
                    <Item type="full" label="Preis" size={6}>
                        <div className={styles.children}>{meeting?.param?.PRICING || ' '}</div>
                    </Item>
                    <Item type="full" label="veröffentlichen?" size={6}>
                        <div className={styles.children}>{meeting?.param?.PUBLISH_API ? "JA" : "Nein"}</div>
                    </Item>

                    </Form>
                </ItemNavGroup>
            {(meeting?.org_id === role.orgId || role.orgId === 1) &&
                (
                    <ItemNav
                        primary={true}
                        label={`Event Nr. ${examId} bearbeiten`}
                        icon={faEdit}
                        href={`${gotoUrl}/${examId}?t=edit`}
                    />
                )}

                <ItemNavGroup label='Anmeldung' className={styles.anmeldung}>
                    <>{disabeldRoleSelect && (
                        <ItemNav className={styles.info} label={`Anmeldung nur zwischen ${FD(meeting?.open_from)} - ${FD(meeting?.open_to)} möglich`} />
                    )}</>
                    {
                        filteredRoles.length > 0 ? filteredRoles.map(mr => {
                        const meetingRole = profile.meetingRoles.find(x => x.id === mr);
                        console.log('meetingRole: ', meetingRole)
                        if (meetingRole) {
                            if(meetingRole.name == 'Judoka') {
                                if(meeting?.ages_nennbar && typeof meeting.ages_nennbar === 'object' &&
                                    (
                                        Array.isArray(meeting.ages_nennbar.m) && (meeting.ages_nennbar.m).length==0
                                    &&  Array.isArray(meeting.ages_nennbar.w) && (meeting.ages_nennbar.m).length==0
                                    ))
                                {
                                        return <ItemNav
                                            className={styles.missingAk}
                                            key={meetingRole.name}
                                            type="list"
                                            label={`${meetingRole?.name}: Der Veranstalter hat noch keine Altersklassen definiert.`}
                                            disabled={disabeldRoleSelect}
                                        />;
                                    } else {
                                return <ItemNav
                                    key={meetingRole.name}
                                    type="list"
                                    label={meetingRole?.name}
                                     //{`${typeof meetingRole?.param?.funk_id === 'string' && meetingRole?.param?.funk_id.length > 0 ? 'funk-id' : 'none'} ${typeof meetingRole?.param?.ausb_id === 'string' && meetingRole?.param?.ausb_id.length > 0 ? 'ausb-id' : 'none'}`}
                                    onClick={() => navigate(`${gotoUrl}/${meeting?.id}?mr=${mr}&t=candidates`)}
                                    disabled={disabeldRoleSelect}
                                />;
                            }
                            } else {
                                return <ItemNav
                                    key={meetingRole.name}
                                    type="list"
                                    label={meetingRole?.name}
                                    onClick={() => navigate(`${gotoUrl}/${meeting?.id}?mr=${mr}&t=candidates`)}
                                    disabled={disabeldRoleSelect}
                                />;
                            }
                        } else {
                            return <ItemNav key={mr} label={`MeetingRoleId ${mr} not available`}/>;
                        }
                    }) : (
                        meeting?.param?.BUNDESWEIT === true ? (
                            <ItemNav label="Bundesweite Nennung innerhalb des Nennzeitraums. Für diese Veranstaltung sind entweder noch keine Rollen angelegt
                                oder eine Anmeldung über JAMA ist nicht vorgesehen." />
                        ) : <ItemNav label="Noch nicht als Bundesweit gespeichern. Bitte Event im neuen JAMA einmalig speichern." />
                    )}
                </ItemNavGroup>

        </>
    );
};
