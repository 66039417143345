import {withFilter} from "../../../../../../api/withFilter";
import React, {useEffect, useMemo, useState} from "react";
import {ItemNavGroup} from "../../../../../../components/form/ItemNavGroup";
import {Button, DateInput, DTS, Input, Item, PDS, Segment, Select} from "../../../../../../components/form";
import {addMonths} from "date-fns";
import styles from './AdminOrgMeetingNew.module.scss';
import {IOrgFunction, IUserModel} from "../../../../../../api/models";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import {toaster} from "../../../../../../core/toaster";
import {SelectMulti} from "../../../../../../components/form/SelectMulti";
import {Upload} from "../../../../../../components/form/Upload";
import {MeetingService} from "../../../../../../api/meeting";
import {IMeeting, IUserProfile} from "../../../../../../api/models";

interface AdminOrgMeetingEditProps {
    user: IUserModel;
    profile: IUserProfile;
}

function doStupidStringIntBoolParamsConverstion(value: any) {
    if (value === '1') return true;
    if (value === '0') return false;
    if (value==='') return false;
    if (value === 1) return true;
    if (value === 0) return false;
    if (value === true) return true;
    if (value === false) return false;
    return false;
}

const AdminOrgMeetingEdit: React.FC<AdminOrgMeetingEditProps> = ({user, profile}) => {
    const params = useParams();
    const [eventType, setEventType] = useState<number>(1);
    const [openFrom, setOpenFrom] = useState<Date | null>(null);
    const [openTo, setOpenTo] = useState<Date | null>(null);
    const [from, setFrom] = useState<Date | null>(null);
    const [to, setTo] = useState<Date | null>(null);
    const [name, setName] = useState<string | null>(null);
    const [ort, setOrt] = useState<string>("");
    const [altersklasse, setAltersklasse] = useState<string[]>([]);
    const [meetingRoles, setMeetingRoles] = useState<number[]>([]);
    const [bundesweit, setBundesweit] = useState<boolean>(false)
    const [publish, setPublish] = useState(false);
    const [url, setUrl] = useState<string|null>(null);
    const [streamingUrl, setStreamingUrl] = useState<string|null>(null);
    const [text, setText] = useState<string|null>(null)
    const [preis, setPreis] = useState<string|null>(null);
    const [teilnahme, setTeilnahme] = useState<string|null>(null);
    const [meeting, setMeeting] = useState<IMeeting|null>(null);
    const [country, setCountry] = useState<string>("AUT");
    const [nationencheck, setNationencheck] = useState<boolean>(false);

    const navigate = useNavigate()

    const meetingId = Number(params['id'] || 0);
    const roleId = Number(params['role'] || 0);
    const role = user?.rolesOrg.map(x => x?.adminOrg || x?.trainerOrg).flat().find(x => x?.funkMgId === roleId) as IOrgFunction;

    const akOptions = useMemo(() => {
        return profile.weightClasses.map(x => ({label: x.short, value: x.short}))
            .filter((x, i, a) => a.indexOf(a?.find(y => y?.value === x?.value) as { label: string, value: string }) === i)
    }, [profile])

    useEffect(() => {
        setEventType(meeting?.meetingtype_id||1)
        setOpenFrom(PDS(meeting?.open_from))
        setOpenTo(PDS(meeting?.open_to))
        setFrom(PDS(meeting?.open_from))
        setTo(PDS(meeting?.open_to))
        setName(meeting?.name||null)
        setOrt(meeting?.param?.ORT||'')
        setAltersklasse(Object.keys(meeting?.param?.AKGK || {})||[])
        setMeetingRoles(meeting?.param?.MEETINGROLES_REQ||[])
        setBundesweit(doStupidStringIntBoolParamsConverstion(meeting?.param?.BUNDESWEIT))
        setPublish(doStupidStringIntBoolParamsConverstion(meeting?.param?.PUBLISH_API))
        setUrl(meeting?.param?.URL||null)
        setStreamingUrl(meeting?.param?.STREAMING_URL||null)
        setText(meeting?.param?.TEXT||null)
        setPreis(meeting?.param?.PRICING||null)
        setTeilnahme(meeting?.param?.TEILNAHME||null)
        setCountry(meeting?.param?.COUNTRY_IOC||'AUT')
        setNationencheck(doStupidStringIntBoolParamsConverstion(meeting?.param?.NATIONENCHECK))
    }, [meeting]);

    // useEffect(() => {
    //     const eventTypeObject = profile.meetingTypes.find(x => x.id === eventType)
    //     if (eventTypeObject) {
    //         setMeetingRoles(eventTypeObject.param.MEETINGROLES || []);
    //         setPublish(eventTypeObject.param.PUBLISH_API === 1 || false);
    //         setBundesweit(eventTypeObject.param.BUNDESWEIT === 1 || false);
    //         setNationencheck(eventTypeObject.param.NATIONENCHECK === 1 || false);
    //         //console.log(eventTypeObject)
    //     }
    // }, [profile, eventType]);

    useEffect(() => {
        if ( role?.orgId) {
            MeetingService.get(role.orgId, meetingId).then(setMeeting)
        }
    }, [role]);

    const validate = () => {
        return openFrom != null && openTo != null && from != null && to != null &&
            (name?.length || 0) > 0;
    }

    const create = async (data: React.MouseEvent<HTMLButtonElement>): Promise<boolean> => {
        if (validate()) {
            toaster(async () => {

                const meeting: IMeeting = {
                    meetingtype_id: eventType,
                    name: name as string,
                    open_from: DTS(openFrom as Date),
                    open_to: DTS(openTo as Date),
                    startdate: DTS(from as Date),
                    enddate: DTS(to as Date),
                    org_id: role.orgId,
                    param: {
                        ORT: ort as string,
                        MEETINGROLES_REQ: meetingRoles,
                        URL: url,
                        STREAMING_URL: streamingUrl,
                        TEXT: text,
                        PRICING: preis,
                        TEILNAHME: teilnahme,
                    },
                    ages_nennbar: altersklasse,
                    mutter_id: 0, // create duplicates?
                    //bl_begeg_id: beg
                } as unknown as IMeeting // todo fix
                const newMeeting = await MeetingService.create(role.orgId, meeting);
                navigate(`/more/organization/meeting/${roleId}/${newMeeting}`)

                return
            }, {success: 'Event erfolgreich angelegt', failure: 'Fehler beim Anlegen der Prüfung!'})
        }
        return false;
    }

    return <>
        <ItemNavGroup label="Event bearbeiten">
            <Item type="full" label="Veranstaltungstyp">
                <Select value={eventType} onChange={setEventType}
                        options={profile.meetingTypes.sort((a, b) => a.id - b.id).map(x => ({
                            label: x.name,
                            value: x.id,
                        }))}/>
            </Item>
            <Item type="full" label="Teilnehmer-Rollen">
                <SelectMulti onChange={setMeetingRoles} value={meetingRoles}
                             options={profile.meetingRoles.map(x => ({label: x.name, value: x.id}))}/>
            </Item>
            <Item type="full" label="Name">
                <Input
                    value={name||''}
                    onChange={(v) => setName(v)}
                />
            </Item>
            <Item type="full" label="Anmeldung Von">
                <DateInput
                    value={openFrom}
                    onChange={setOpenFrom}
                    minDate={new Date()}
                    maxDate={from || addMonths(new Date(), 18)}
                />
            </Item>
            <Item type="full" label="Anmeldung Bis">
                <DateInput
                    value={openTo}
                    onChange={setOpenTo}
                    disabled={!openFrom}
                    minDate={openFrom || undefined}
                    maxDate={from || addMonths(openFrom as Date, 18)}
                />
            </Item>
            <Item type="full" label="Start">
                <DateInput
                    value={from}
                    onChange={setFrom}
                    disabled={!openTo}
                    minDate={openTo || undefined}
                    maxDate={addMonths(openTo as Date, 1)}
                />
            </Item>
            <Item type="full" label="Ende">
                <DateInput
                    value={to}
                    onChange={setTo}
                    disabled={!from}
                    minDate={from || undefined}
                    maxDate={addMonths(from as Date, 1)}
                />
            </Item>
            <Item type="full" label="Ort">
                <Input value={ort}
                       onChange={(v) => setOrt(v)}
                />
            </Item>
            <Item type="full" label="Land IOC">
                <Select
                    value={country}
                    onChange={setCountry}
                    options={profile.countries.map(x => ({label: x.name, value: x.IOC}))}
                />
            </Item>
            <Item type="full" label="Nationencheck">
                <Segment value={nationencheck} onChange={setNationencheck}
                         options={[{label: "Nein", value: false}, {label: "Ja", value: true}]}/>
            </Item>
            <Item type="full" label="Bundesweit">
                <Segment value={bundesweit} onChange={setBundesweit}
                         options={[{label: "Nein", value: false}, {label: "Ja", value: true}]}/>
            </Item>
            <Item type="full" label="Nennbare Altersklassen">
                <SelectMulti onChange={setAltersklasse} value={altersklasse} options={akOptions}/>
            </Item>
            <Item type="full" label="Url">
                <Input
                    value={url||''}
                    onChange={(v) => setUrl(v)}
                />
            </Item>
            <Item type="full" label="Streaming Url">
                <Input
                    value={streamingUrl||''}
                    onChange={(v) => setStreamingUrl(v)}
                />
            </Item>
            {/*<Item type="full" label="Bundesliga">
                <Select onChange={() => null}
                        options={[{label: 'tbd', value: '3'}]}/>
            </Item>*/}
            <Item type="full" label="Text">
                <Input
                    value={text||''}
                    onChange={(v) => setText(v)}
                />
            </Item>
            <Item type="full" label="Preis">
                <Input
                    value={preis||''}
                    onChange={(v) => setPreis(v)}
                />
            </Item>
            <Item type="full" label="Teilnahme">
                <Input
                    value={teilnahme||''}
                    onChange={(v) => setTeilnahme(v)}
                />
            </Item>
            <Item type="full" label="Veröffentlichen">
                <Segment value={publish} onChange={setPublish}
                         options={[{label: "Nein", value: false}, {label: "Ja", value: true}]}/>
            </Item>
            <Item type="full" label="Anhänge">
                <Upload orgId={role.orgId}/>
            </Item>
        </ItemNavGroup>
        <Button label="Speichern" className={styles.save} onClick={create} disabled={!validate()}/>
    </>
}

export default withFilter('Event', {}, AdminOrgMeetingEdit, undefined, -1);
