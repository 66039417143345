import React from 'react';
import {withFilter} from "../../../../api/withFilter";
import {IPageLinkProps} from "../../../../components/page.props";
import {ProfileTrainer} from "../../../../components/admin/profile/trainer";


const MoreProfileTrainer: React.FC<IPageLinkProps> = (props) => {
    return (    <ProfileTrainer {...props} />    );
};

export default withFilter('Trainer', {}, MoreProfileTrainer, undefined, '/more');
