// LICENCE FOR ARCHERY
import React, {useState} from 'react';
import {withFilter} from "../../../api/withFilter";
import {IUserModel} from "../../../api/models";
import {IMAGE_URL} from "../../../api";
import styles from './Licence.module.scss'
import PullToRefresh from "react-simple-pull-to-refresh";
import classNames from "classnames";

interface ILicenceProps {
    user: IUserModel;
    refresh: () => Promise<void>;
}

export const LicenceCard: React.FC<ILicenceProps> = ({user, refresh}) => {
    const [showJama, setShowJama] = useState(false);
    const backgroundImage = IMAGE_URL + user?.imageLink + `?v=${Date.now()}`;

    const lastJudocardHexColor = {
        color: user?.lastjudocardyearcolor
    };

    const toggleBarcode = (evt: any) => {
        setShowJama(!showJama);
    };


    return (
        <PullToRefresh onRefresh={() => refresh && refresh()}>
            <div className={styles.judo}>
                <div className={styles.judoCard}>
                    {user &&
                        <div className={styles.container}>
                            <div className={styles.title}>
                                <label>Lizenznummer / Lizenzende</label>
                                <h1>
                                    {user.lastjudocardyear !=='N/A' ? <div dangerouslySetInnerHTML={{__html: `${user.jama_id} / ${(user.license.funcTo).replace(/-/g,'.')}<sup>*</sup>`}}></div> :
                                        <div className={"abgelaufen"}>{user.jama_id} / <span className={"ungueltig"}>abgelaufen!</span></div>}
                                </h1>
                                <span className={styles.logo}/>
                            </div>
                            <div>
                                <h1 className={styles.name}>{user.title} {user.familyName} {user.givenName}</h1>
                            </div>
                            <div>
                                <label className={styles.birthdate}>{user.birthDate}   <span className={classNames(styles.gender, user.gender === 'm' ? styles.male : user.gender === 'w' ? styles.female : styles.genderx)}>
                                {user.gender === 'm' ? ' \u2642' : user.gender === 'w' ? ' \u2640' : ' \u26A5'}</span>
                                    <span className="age-class"> {user.addressEdit?.age_class}</span>
                                </label>
                            </div>
                            {/*{user.maxgrade && <div className={styles.beltContainer}>*/}
                            {/*        <label>{user.maxgrade} <span className={styles.belt}*/}
                            {/*                                     dangerouslySetInnerHTML={{__html: user.belt}}/></label>*/}
                            {/*    </div>}*/}
                            <div className={styles.memberships} ><label>Vereinsmitgliedschaft</label>
                                {/*{(user.membershiplicenses?.length || 0) === 0 && <label>{user.clubname}</label>}*/}
                                {user.membershiplicenses?.map((membership: any, i: number) => <div
                                    key={`ms_${membership.orgId}`} >{membership.orgName}{membership.annualLicense[0] ? <sup className={styles.licenseOrg} >*</sup> : null } {i !== user?.membershiplicenses?.length - 1 && (" ")}</div>)}
                            </div>

                            {/*<div className={styles.barcodeContainer} onClick={() => setShowJama(!showJama)}>*/}
                            {/*    <div className={classNames(user.allGrades?.find(x => x?.certId === 12) ? styles.plus : styles.plusPlaceholder)} />*/}
                            {/*    <label>{user.allGrades?.find(x => x?.certId === 12) ? 'Rot-Weiß-Roter Pfeil' : null}</label>*/}
                            {/*</div>*/}
                            {/*<div className={styles.allGrades} >*/}
                            {/*    <div className={classNames(user.allGrades?.find(x => x?.certId === 12) ? styles.rwr : styles.rwrPlaceholder)} />*/}
                            {/*    <label>{user.allGrades?.find(x => x?.certId === 12) ? 'Rot-Weiß-Roter Pfeil' : null}</label>*/}
                            {/*</div>*/}
                            <div className={styles.allGrades} >
                                <label>{user.allGrades?.find(x => x?.certId === 1) ? 'Safety First Einschulung \u2714' : null}</label>
                            </div>
                            {/*<div>*/}
                            {/*    <label>Nationalität: {user.nationality}</label>*/}
                            {/*</div>*/}
                            {/*<div className={styles.barcode}/>*/}
                            <div className={styles.profile} style={{backgroundImage: `url('${backgroundImage}')`}}/>
                            <div className={styles.sponsor}/>
                        </div>
                    }
                </div>
            </div>
        </PullToRefresh>
    );
};

export default withFilter('Lizenz', {}, LicenceCard);