import {Ii18n} from "../../app";

export const de: Ii18n = {
    global: {
        processing: "Anfrage wird bearbeitet",
        success: 'Erfolgreich',
        error: "Es ist ein Fehler aufgetreten",
    },
    login: {
        message: "Willkommen im JUDO Admin",
        success: "Login erfolgreich",
        failure: "Login fehlgeschlagen",
        reset: "Passwort zurücksetzen",
        request: "Neues Passwort anfordern"
    },
    pin: {
        message: 'Verknüpfe diese App mit deinem JAMA-Konto',
    }
}

export default de;