import React, {useEffect, useMemo, useState} from "react";
import {faAdd} from "@fortawesome/free-solid-svg-icons";
import {IMeeting} from "../../../api/models";
import {ItemNavGroup} from "../../form/ItemNavGroup";
import {ItemNav} from "../../form/ItemNav";
import {DateBetweenText, FD, FDS, FDHBR, Input, Item} from "../../form";
import classNames from "classnames";
import {MeetingService} from "../../../api/meeting";
import {IPageLinkProps} from "../../page.props";
import styles from './event.module.scss'
import {useParamId, useParamRole} from "../../useParam";
import {Loading} from "../../foundation/Loading";
import {doStupidStringIntBoolParamsConverstion} from "./event_detail_edit";


const Event: React.FC<IPageLinkProps> = ({user, profile, gotoUrl, refresh}) => {
    const [loading, setLoading] = useState(true);
    const [meetings, setMeetings] = useState<IMeeting[]>([]);
    const [search, setSearch] = useState<string>('');

    const id = useParamId();
    const role = useParamRole(user);

    const filterLength = 200;

    useEffect(() => {
        setLoading(true);
        if(role != null) {
            MeetingService.all(role.orgId).then((m) => {
                setMeetings(m);
            }).finally(() => setLoading(false));
        } else {
            setLoading(false)
        }
    }, [user, refresh, role])

    const filter = useMemo(() => {
        const source = meetings.filter(x => x.org_id === role.orgId || doStupidStringIntBoolParamsConverstion(x.param?.BUNDESWEIT))
        return search.length > 0 ? source.filter(x =>
                (x.name?.toLowerCase()?.includes(search?.toLowerCase()) ||
                    x.param?.ORT?.toLowerCase()?.includes(search?.toLowerCase())))
            : source

    }, [meetings, role.orgId, search]);

    return <>
        <ItemNavGroup label={role?.orgName}>
            <>
            {loading && <Loading/>}
            <ItemNav
                label="Neuen Event hinzufügen"
                icon={faAdd}
                href={`${gotoUrl}?t=edit`}
            />
            <Item type="full" className={styles.search}>
                <Input onChange={setSearch} value={search} className={styles.name} placeholder="Suche ..."/>
            </Item>
            {!loading && (filter?.length > 0 ? (
                <>
                    {filter.slice(0, filterLength).map((e) => {
                        const DateBetween = DateBetweenText(e.open_from, e.open_to);
                        const editable = (e?.org_id === role.orgId || role.orgId === 1) || false
                        //console.log('DateBetween: ', e.id + ' ' + DateBetween)
                        //console.log('from-to: ',e.open_from, e.open_to);
                        return (
                        <ItemNav
                            key={e.id}
                            href={`${gotoUrl}/${e.id}?t=roles`}
                            className={classNames(styles.content, e.id ===  1 && styles.active, editable ? styles.edit : styles.noedit) }
                            selected={id === e.id}
                        >
                            <div className={styles.entry}>
                                <div className={classNames(styles.date)}>
                                    <div className={styles.meetingId}>{e.id}</div>
                                    {FDHBR(e.startdate)}
                                </div>
                                <div className={styles.name}>
                                    {e.name}
                                    <div className={styles.nennzeitraum}>
                                        {e.open_from ? FDS(e.open_from)+' - ' : '1x speichern!'} {e.open_to ? FDS(e.open_to) : ''}
                                    </div>
                                </div>

                                <div>
                                    {doStupidStringIntBoolParamsConverstion(e.param?.BUNDESWEIT) ? <div className={styles.bundesweit}>
                                        BUNDESWEIT
                                    </div> : <div className={styles.local}>NUR LOKAL</div>}
                                    {DateBetween == 'open' ? <div className={styles.open}>ANMELDEN</div>
                                        : DateBetween == 'soon' ? <div className={styles.soon}>DEMNÄCHST</div>
                                            : DateBetween == 'closed' ? <div className={styles.closed}>GESCHLOSSEN</div>
                                                : DateBetween == 'missing' ? <div className={styles.nojama}>KEINE ANM.</div> : null
                                                    // : null <div className={styles.error}>! ENTWURF !</div>
                                    }
                                </div>
                            </div>
                        </ItemNav>
                    )})}
                    <Item>Es werden nur die ersten {filterLength} Ergebnise angezeigt</Item>
                </>)  :  <Item type="list" label="Keine Veranstaltungen verfügbar" />)}
            </>
        </ItemNavGroup>
    </>
}

export default Event;