import React from "react";
import styles from './ItemNavGroup.module.scss'
import classNames from "classnames";
import {ReactChildrenType} from "../../core/children";

interface IItemProps {
    type?: 'form'|'list' // default = form
    label?: string;
    className?: string;
    children: ReactChildrenType
    onClick?: ()=> void;
}


export const ItemNavGroup: React.FC<IItemProps> = ({label, type, children, onClick, className}) => {

    return (
        <div className={classNames(styles.item, styles[type || 'form'], className)} onClick={ onClick ? onClick : undefined}>
            <div className={styles.header}>
                {label && <label>{label}</label>}
            </div>
            <div className={styles.children}>
                {children}
            </div>
        </div>
    )
}