import React from "react";
import styles from './Content.module.scss'
import classNames from "classnames";
import {ReactChildrenType} from "../../core/children";

export type TContentState = 'loading'|'active'|'navigate'|'previewLeft'|'previewRight'

interface IContentProps {
    children?: ReactChildrenType
    state: TContentState;
}


export const Content: React.FC<IContentProps> = ({state, children}) => {

    return (
        <div className={classNames(styles.content)}>
            <div className={classNames(styles.children, styles[state])} >
                {children}
            </div>
        </div>
    )
}