import {IUserModelEdit} from "./models/UserModel";
import {HttpService} from "./http";
import {AuthService} from "./auth";
import {Constants} from "./constants";
import {ITrainerApplyResponse} from "./models/TrainerLicenseModel";

const TRAINER_APPLY = `${Constants.JAMA_SERVER_API_V2}/user/:org/trainer`;
const FORM = `${Constants.JAMA_SERVER_API_V2}/admin/:org/form/`;
const PROFILE = `${Constants.JAMA_SERVER_API_V2}/admin/profile`;

const UPDATE_USER = `${Constants.JAMA_SERVER_API}/updateUser`;
const R = (r: string, org: number) => r.replace('/:org/', `/${org}/`);

/**
 * A class that provides methods for editing user profiles and uploading profile pictures.
 */
export class ProfileService {
    /**
     * Edit the user profile.
     *
     * @param {IUserModelEdit} profile - The profile to be updated.
     * @returns {Promise<undefined>} A promise that resolves once the profile is updated.
     */
    public static async editProfile(profile: IUserModelEdit): Promise<undefined> {
        await HttpService.post2<IUserModelEdit, any>(UPDATE_USER, profile, true, false);
        //console.log(profile);
        return;
    }


    public static async getForm(org: number, roleMg: number): Promise<string> {
        return await HttpService.get<string>(R(`${FORM}${roleMg}`, org), true, false);
    }
    
    /**
     * Uploads a profile picture for a given mg.
     *
     * @param {number} uuid - The mg UUID of the profile to upload the picture for.
     * @param {FormData} data - The FormData object containing the picture data to upload.
     *
     * @return {Promise<boolean>} - A Promise that resolves to a boolean indicating if the upload was successful or not.
     */
    public static async uploadProfilePicture(uuid: string, data: FormData): Promise<boolean> {
        await AuthService.refreshJwt();
        return !!await HttpService.postBinary(`${Constants.JAMA_SERVER_API_V2}/web/profile/image/${uuid}`, data)
    }

    public static async trainerApply(org: number, categoryId: number) {
        await AuthService.refreshJwt();
        console.log(categoryId);
        //return !!await HttpService.post2(R(TRAINER_APPLY, org), {categoryId})
        const response: ITrainerApplyResponse = await HttpService.post2(R(TRAINER_APPLY, org), {categoryId});
        return response?.id || null;
    }
}