import React, {useRef, useState} from 'react';
import {ReactChildrenType} from "../../core/children";
import {Vec2} from "../../util/vec2";

interface ITapAndDragProps {
    onSingleTap?: () => void;
    onDoubleTap?: () => void;
    onDrag?: (pos: Vec2) => void;
    onDragEnd?: () => void;
    onPinch?: (zoom: number) => void;
    className?: string;
    children: ReactChildrenType;
}

export const TapAndDrag: React.FC<ITapAndDragProps> = ({
                                                           onSingleTap,
                                                           onDoubleTap,
                                                           onDrag,
                                                           onDragEnd,
                                                           children,
                                                           className,
                                                       }) => {
    const [lastTap, setLastTap] = useState(0);
    const isDrag = useRef(false);
    const tapTimeout = useRef<NodeJS.Timeout | null>(null);
    const touchStartPos = useRef<{ x: number, y: number }>();
    const previousDistanceRef = useRef<number>();
    const scaleRef = useRef(1);

    const handleTouchStart = (evt: React.TouchEvent<HTMLDivElement>) => {
        isDrag.current = false;
        touchStartPos.current = {x: evt.touches[0].clientX, y: evt.touches[0].clientY};
    }

    const handleTouchMove = (evt: React.TouchEvent<HTMLDivElement>) => {
        isDrag.current = true;
        if (!touchStartPos.current) return;

        if (evt.touches.length >= 2) {

            const distance = Vec2.distance(Vec2.fromTouch(evt.touches[0]), Vec2.fromTouch(evt.touches[1]));

            if (previousDistanceRef.current) {
                scaleRef.current *= distance / previousDistanceRef.current;
            }
            previousDistanceRef.current = distance;
        } else {
            previousDistanceRef.current = undefined;
            onDrag && onDrag(new Vec2(
                evt.touches[0].clientX - touchStartPos.current.x,
                evt.touches[0].clientY - touchStartPos.current.y,
            ));
            touchStartPos.current = {x: evt.touches[0].clientX, y: evt.touches[0].clientY};
        }
    }

    const handleTouchEnd = () => {
        const now = Date.now();
        const DOUBLE_TAP_DELAY = 300;

        if (isDrag.current) {
            if (tapTimeout.current) clearTimeout(tapTimeout.current);
            onDragEnd && onDragEnd();
        } else {
            if (now - lastTap < DOUBLE_TAP_DELAY) {
                if (tapTimeout.current) clearTimeout(tapTimeout.current);
                onDoubleTap && onDoubleTap();
            } else {
                tapTimeout.current = setTimeout(() => onSingleTap && onSingleTap(), DOUBLE_TAP_DELAY);
            }
        }
        setLastTap(now);
    }

    return (
        <div onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}
             style={{width: "100%", height: "100%"}}
             className={className}
        >

            {children}
        </div>
    );
};