import {Label} from "./Label";
import moment from "moment";
import React from "react";
import DatePicker from "react-datepicker";
import 'react-datepicker/src/stylesheets/datepicker.scss'
import { de } from 'date-fns/locale';

interface IDateLabelProps {
    onChange?: (value: Date|null) => void;
    value: Date|null;
    minDate?: Date;
    maxDate?: Date;
    disabled?: boolean;
    required?: boolean;
    placeholderText?: string;
}

function convertUTCToLocalDate(date?: Date|null) {
    if (!date) {
        return date
    }
    date = new Date(date)
    date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
    return date
}

function convertLocalToUTCDate(date?: Date|null) {
    if (!date) {
        return date
    }
    date = new Date(date)
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
    return date
}

export function PDS(v?: string): Date | null {
    return v ? moment(v).toDate() : null;
}

export function FD(v?: string|Date): string {
    return moment(v).format("DD.MM.YYYY")
}
// short
export function FDS(v?: string|Date): string {
    return moment(v).format("DD.MM.YY")
}

// with hidden spaces
export function FDHBR(v?: string|Date): string {
    return moment(v).format("DD.\u200BMM.\u200BYYYY")
}

export function DateBetween(a: string | undefined, b: string | undefined): boolean {
    const now = new Date();
    const startDate = a ? PDS(a) : undefined;
    const endDate = b ? PDS(b) : undefined;

    if (!startDate && !endDate) {
        return false;
    } else if (!startDate && endDate && endDate <= now) {
        return false;
    } else if (!endDate && startDate && startDate <= now) {
        return true;
    } else if (startDate && endDate && startDate <= now && endDate >= now) {
        return true;
    }
    return false;
}

export function DateBetweenText(a: string | undefined, b: string | undefined): string {
    const now = new Date();
    const startDate = a ? PDS(a) : undefined;
    const endDate = b ? PDS(b) : undefined;

    // console.log('startDate: ', startDate)
    // console.log('endDate: ', endDate)

    if (!startDate && !endDate) {
        return 'missing_dates';
    } else if (!startDate && endDate && endDate <= now) {
        return 'closed';
    } else if (startDate && !endDate && startDate <= now) {
        return 'missing_end';
    } else if (startDate && endDate && startDate <= now && endDate >= now) {
        return 'open';
    } else if (startDate && endDate && startDate >= now ) {
        return 'soon';
    }
    return 'false';
}

export function FDX(v?: string): string|null {
    if (!v) {
        return null;
    }
    if (v?.startsWith('2999')) {
        return null;
    }
    return moment(v).format("DD.MM.YYYY")
}

export function FDXS(v?: string): string|null { // short
    if (!v) {
        return null;
    }
    if (v?.startsWith('2999')) {
        return null;
    }
    return moment(v).format("DD.MM.YY")
}

export function DS(v?: string|Date): string {
    return moment(v).format("YYYY-MM-DD")
}

export function DTS(v?: string|Date): string {
    return moment(v).toISOString()
}


export const DateLabel: React.FC<IDateLabelProps> = ({value}) => {

    return <Label><>{moment(value).format('DD. MM. YYYY')}</>
    </Label>
}

export const DateInput: React.FC<IDateLabelProps> = ({
                                                         value,
                                                         onChange,
                                                         minDate,
                                                         maxDate,
                                                         disabled,
                                                         required,
                                                         placeholderText = "Datum auswählen"
}) => {
    return <DatePicker
        selected={convertUTCToLocalDate(value)}
        onChange={(v) => onChange && onChange(convertLocalToUTCDate(v)||null)}
        showYearDropdown
        locale={de}
        dateFormat={"dd.MM.yyyy"}
        minDate={convertUTCToLocalDate(minDate)}
        maxDate={convertUTCToLocalDate(maxDate)}
        disabled={disabled}
        required={required}
        placeholderText={placeholderText}
    />

    //return <Label><>{moment(date).format('DD. MM. YYYY')}</></Label>
}

function generateFormattedTimestamp(format: string): string {
    return moment(new Date()).format(format);
}

//export const VERSIONDATE: string = moment(new Date()).format('YYMM.DD.HHmm');