import React from 'react';

import {INewsModel} from "../../../../api/models/NewsModel";
import {NewsCard} from "../../../../components/NewsCard/NewsCard";
import {NewsModal} from "../NewsModal/NewsModal";
import {useNavigate} from "react-router-dom";

interface IPersonalNewsProps {
  news?: INewsModel[];
}

interface IPersonalNewsState {
  detail?: INewsModel;
}

const  PersonalNews: React.FC<IPersonalNewsProps> = ({news}) => {
    const navigate = useNavigate();

    return (
      <>
        {/*<IonRefresher slot="fixed" onIonRefresh={(evt) => this.refresh(evt)}>
          <IonRefresherContent />
        </IonRefresher>*/}

        {news && news.map((n) => (
            <NewsCard
              news={n}
              key={`panel-${n.id}`}
              onClick={(details) => navigate(`/news/notification/${n.id}`)}
            />
        ))}
      </>
    );
}

export default PersonalNews;
