import React, {useCallback, useEffect, useState} from 'react';
import {IPageLinkProps} from "../../page.props";
import {useParams} from "react-router";
import {EKyuExamStatus, IKyuChairMember, IKyuExam, IOrgFunction} from "../../../api/models";
import {useNavigate} from "react-router-dom";
import {KyuService} from "../../../api";
import {Button, DateInput, DS, Form, Input, Item, PDS} from "../../form";
import {toaster} from "../../../core/toaster";
import {ItemNavGroup} from "../../form/ItemNavGroup";
import {addMonths} from "date-fns";
import SelectKyuBordMembers from "../../tournaments/SelectKyuChairMembers";
import styles from "./kyu_detail_edit.module.scss";


export const KyuDetailApprove: React.FC<IPageLinkProps> = ({user, profile, gotoUrl, refresh, setRefresh}) => {
    const params = useParams();
    const [date, setDate] = useState<Date | null>(new Date(Date.now()));
    const [name, setName] = useState<string | null>(null);
    const [chairMemberMain, setChairMemberMain] = useState<number | null>(null)
    const [chairMember1, setChairMember1] = useState<number | null>(null)
    const [chairMember2, setChairMember2] = useState<number | null>(null)
    const [exam, setExam] = useState<IKyuExam|null>(null)
    const [orgChairMembers, setOrgChairMembers] = useState<IKyuChairMember[]>([])
    const navigate = useNavigate()

    const id = Number(params['id'] || 0);
    const roleId = Number(params['role'] || 0);
    const role = user?.rolesOrg.map(x => x?.adminOrg || x?.trainerOrg).flat().find(x => x?.funkMgId === roleId) as IOrgFunction;

    // console.log("ChairMembers", chairMemberMain, chairMember1, chairMember2)

    const refreshMain = useCallback(async () => {
        if (id > 0 && role?.orgId > 0) {
            const res = await KyuService.get(role.orgId, id)
            setDate(PDS(res.date))
            setName(res.name)
            setChairMemberMain(res.examChairMainId)
            setChairMember1(res.examChair1Id)
            setChairMember2(res.examChair2Id)
            setExam(res)
        }
    }, [id, role.orgId])

    useEffect(() => {
        if (date && role?.orgId) {
            KyuService.listChairMembers(role?.orgId, {
                search: '',
                date: DS(date as Date)
            }).then(setOrgChairMembers)
        }
    }, [date, role?.orgId]);

    useEffect(() => {
        refreshMain()
    }, [refreshMain]);



    const validate = () => {
        return date != null && (exam?.status === EKyuExamStatus.created || !exam?.status);
    }

    const validateApprove = () => {
        //!(date && (date?.getTime() < Date.now())) ||
        return date != null && exam?.status === EKyuExamStatus.completed;
    }


    const approve = (data: React.MouseEvent<HTMLButtonElement>): boolean => {
        if (validateApprove()) {
            toaster(async () => {
                await KyuService.approve(role?.orgId, id);
                setRefresh && setRefresh((refresh ||0)+1);
                navigate(`${gotoUrl}/${id}?t=candidates`)
                return
            }, {success: 'Kyu Prüfung erfolgreich durchgeführt', failure: 'Fehler!'})
        }
        return false
    }

    return <>
        <ItemNavGroup label={`Prüfung ${exam?.id}`}>
            <Form>
                <Item type="full" label="Datum" size={6}>
                    <DateInput
                        value={date}
                        onChange={setDate}
                        minDate={new Date()}
                        maxDate={addMonths(new Date(), 3)}
                        disabled={exam?.status !== EKyuExamStatus.created}
                    />
                </Item>
                <Item type="full" label="Name" size={6}>
                    <Input
                        onChange={(v) => setName(v)}
                        value={name || ''}
                        disabled={!validate()}
                    />
                </Item>
                <Item type="full" label="Vorsitz">
                    <SelectKyuBordMembers
                        orgId={role?.orgId}
                        id={chairMemberMain}
                        setId={setChairMemberMain}
                        orgMembers={orgChairMembers}
                        date={date}
                        disabled={!validate()}
                        exclude={[chairMember1 || 0, chairMember2 || 0]}
                    />
                </Item>
                <Item type="full" label="Beisitz 1">
                    <SelectKyuBordMembers
                        orgId={role?.orgId}
                        id={chairMember1}
                        setId={setChairMember1}
                        orgMembers={orgChairMembers}
                        date={date}
                        disabled={!validate() || chairMemberMain == null}
                        exclude={[chairMemberMain || 0, chairMember2 || 0]}
                    />
                </Item>
                <Item type="full" label="Beisitz 2">
                    <SelectKyuBordMembers
                        orgId={role?.orgId}
                        id={chairMember2}
                        setId={setChairMember2}
                        orgMembers={orgChairMembers}
                        date={date}
                        disabled={!validate() || chairMember2 == null}
                        exclude={[chairMemberMain || 0, chairMember1 || 0]}
                    />
                </Item>
            </Form>
            <Button width={'full'}  label="Freigeben" onClick={approve}
                    disabled={!validateApprove()} />
        </ItemNavGroup>
    </>
};