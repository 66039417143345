import React, {useEffect, useMemo, useState} from "react";
import {withFilter} from "../../../../../../api/withFilter";
import {IUserModel} from "../../../../../../api/models";
import {IMember} from "../../../../../../api/models/MemberModel";
import {MemberService} from "../../../../../../api/member";
import {useParamRole} from "../../../../../../components/useParam";
import {ItemNavGroup} from "../../../../../../components/form/ItemNavGroup";
import {ItemNav} from "../../../../../../components/form/ItemNav";
import {FD, Input, Item} from "../../../../../../components/form";
import AvatarImage from "../../../../../../components/foundation/AvatarImage";
import styles from './AdminOrgMember.module.scss'
import {useNavigate} from "react-router-dom";
import {Loading} from "../../../../../../components/foundation/Loading";
import {faPerson} from "@fortawesome/free-solid-svg-icons";
import {IUserProfile} from "../../../../../../api/models/AuthModel";

interface IAdminOrgMembersProps {
    user: IUserModel;
    profile: IUserProfile;
}

const AdminOrgMember: React.FC<IAdminOrgMembersProps> = ({user, profile}) => {
    const [loading, setLoading] = useState(true);
    const [members, setMembers] = useState<IMember[]>([]);
    const [search, setSearch] = useState<string>('')


    const navigate = useNavigate();
    const role = useParamRole(user)

    useEffect(() => {
        if (user && loading) {
             MemberService.all(role.orgId).then((m) => {
                 setMembers(m)
                 setLoading(false)
             })
        }
    }, [user])

    const goto = (id: number) => {
        navigate(`/more/organization/member/${role.funkMgId}/${id}`)
    }

    const filter = useMemo(() => {
        return members.filter(x =>
            (x.firstname?.toLowerCase()?.includes(search?.toLowerCase()) ||
                x.surname?.toLowerCase()?.includes(search?.toLowerCase())))

    }, [members, search]);

    return <>
        <ItemNavGroup label={role?.orgName}>
            <>
                {loading && <Loading />}
                <ItemNav
                    align="center"
                    label="Mitglied hinzufügen"
                    icon={faPerson}
                    onClick={()=>goto(0)}
                />
                <Item type="form" className={styles.search}>
                    <Input onChange={setSearch} className={styles.name}/>
                </Item>
                {!loading && (filter?.length > 0 ? filter.map((m) => (
                    <ItemNav
                        key={`member-${m.id}`}
                        className={styles.navItem}
                        onClick={() => goto(m.id || 0)}
                    >
                        <div className={styles.item}>
                            <AvatarImage src={MemberService.imageUrl(m.uuid)} className={styles.avatar}/>
                            <div className={styles.text}>
                                <div>{m.surname} {m.firstname}</div>
                                <div className={styles.bottom}>
                                    <div className={styles.org}>{m.orgId && m.orgId !== role.orgId && profile.organizations.find(x => x.id ===m.orgId)?.nameShort}</div>
                                    <div className={styles.birthdate}>{FD( m.birthdate)}</div>
                                </div>
                            </div>
                        </div>
                    </ItemNav>
                )) : <Item type="list" label="Keine Mitglieder gefunden"/>)}
            </>
        </ItemNavGroup>
    </>
}

/*

DECLARE _id int;
BEGIN
  INSERT INTO mg
(
    fname,
    vname1,
    vname2,
    titel,
    nationalitaet / *(->lookup table sysconfig.COUNTRIES)* /,
    gebdatum,
    strasse,
    hausnummer,
    stiege,
    tuernummer,
    plz,
    ort,
    land,
    geschlecht,
    tel_mobil,
    email,
    gewklasse
    ) / *(->lookup table gewklasse mit geschlecht)* /
VALUES
    ('ABCD', 'Ferdl',null,null,3,'1980-01-02','Straße',5,null,null,1234, 'wien',3,'m', '0664/123456','ferdl@marksim.org',12)
RETURNING id INTO _id;
-- create membership
INSERT INTO funk_mg (mg_id, org_id, funk_id, funk_start)
VALUES
    (_id,12,32,'2023-09-01'::date);
END
$$
 */

export default withFilter('Mitglieder', {}, AdminOrgMember, undefined, -1);
