import React from 'react';
import {withDesktop} from "../../api/withDesktop";

interface IJudocardsProps {

}

export const DesktopJudocardsPage: React.FC<IJudocardsProps> = ({}) => {
    return (
        <div>
            New Component Judocards
        </div>
    );
};

export default withDesktop(DesktopJudocardsPage)