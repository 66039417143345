import {IAuthProfile, IVerifyPinResult, LocalService} from "./local";
import {Observable, Subject} from "rxjs";
import {HttpService} from "./http";
import {Constants} from "./constants";
import {CacheService} from "./cache";
import {IUserModel} from "./models/UserModel";
import {IUserProfile} from "./models/AuthModel";

const UPDATE_TOKEN = `${Constants.JAMA_SERVER_API}/users/updatetoken`;
const SIGNUP_USER = `${Constants.JAMA_SERVER_API}/auth/pinsignup`;
const GET_USER = `${Constants.JAMA_SERVER_API}/getUser`;
const GET_PROFILE = `${Constants.JAMA_SERVER_API_V2}/auth/profile`;

interface IVerifyPin {
    email: string;
    pin: string;
}

interface IVerifyPassword {
    email: string;
    password: string;
}

const VERIFY_PIN = `${Constants.JAMA_SERVER_API}/verifypin`;
const VERIFY_PASSWORD = `${Constants.JAMA_SERVER_API_V2}/auth/password`;

/**
 * AuthService Class Documentation
 *
 * Represents a service for authorization and authentication functionalities.
 *
 * @class
 */
export class AuthService {
    /**
     * Represents an authorization state subject.
     * @type {Subject<boolean>}
     */
    private static _authorization: Subject<boolean> = new Subject<boolean>();


    /**
     * Authorize the user with credentials or JWT.
     * If email and pin are provided, authorize with credentials.
     * If not, authorize with JWT.
     *
     * @param {string | null} email - The user's email.
     * @param {string | null} pin - The user's PIN.
     * @returns {Promise<boolean>} - A promise that resolves to a boolean indicating whether the user is authorized.
     */
    public static async authorize(email: string | null = null, pin: string | null = null): Promise<boolean> {
        let auth = false;
        if (email != null && pin != null) {
            // authorize with credentials

            const res = await HttpService.post2<IVerifyPin, IVerifyPinResult>(VERIFY_PIN, {email, pin}, false, false);
            if (res != null && res.jwtToken != null && res.jwtToken.length > 0) {
                const token = AuthService.parseJwt(res.jwtToken)

                LocalService.setProfile({
                    name: '',
                    userId: token.userId,
                    auth: res,
                });
                CacheService.clear();
                const user = await AuthService.getUser();
                if (user) {
                    console.log('setprofile', user, token);
                    LocalService.setProfile({
                        name: `${user?.title || ''} ${user?.givenName} ${user?.familyName}`.trimStart(),
                        userId: token.userId,
                        auth: res,
                    });
                    auth = true;
                } else {
                    auth = false;
                }
            }

        } else {
            // authorize with jwt
            // @ts-ignore // TODO: Use native storage element

            /*const authData = ApiService._auth
            if (authData.jwtToken != null) {
                ApiService.token = authData.jwtToken;
            } else {
                ApiService.token = null;
                console.log('clear token')
            }*/

            auth = !(LocalService.getAuth() == null || LocalService.getAuth()?.jwtToken.length === 0);
        }
        // return 'Bearer abc';
        // auth = true;
        setTimeout(() => AuthService._authorization.next(auth), 500);
        return auth;
    }

    public static async authorizePassword(email: string, password: string): Promise<boolean> {
        let auth = false;
        if (email != null && password != null) {
            // authorize with credentials

            const res = await HttpService.post2<IVerifyPassword, IVerifyPinResult>(VERIFY_PASSWORD, {email, password}, false, false);
            console.log(res)
            if (res != null && res.jwtToken != null && res.jwtToken.length > 0) {
                const token = AuthService.parseJwt(res.jwtToken)
                console.log(token);
                LocalService.setProfile({
                    name: '',
                    userId: token.userId,
                    auth: res,
                });
                await CacheService.clear();
                const user = await AuthService.getUser();
                if (user) {
                    console.log('setprofile', user, token);
                    LocalService.setProfile({
                        name: `${user?.title || ''} ${user?.givenName} ${user?.familyName}`.trimStart(),
                        userId: token.userId,
                        auth: res,
                    });
                    auth = true;
                } else {
                    auth = false;
                }
            }

        } else {

            // authorize with jwt
            // @ts-ignore // TODO: Use native storage element

            /*const authData = ApiService._auth
            if (authData.jwtToken != null) {
                ApiService.token = authData.jwtToken;
            } else {
                ApiService.token = null;
                console.log('clear token')
            }*/

            auth = !(LocalService.getAuth() == null || LocalService.getAuth()?.jwtToken.length === 0);
        }
        // return 'Bearer abc';
        // auth = true;
        setTimeout(() => AuthService._authorization.next(auth), 500);
        return auth;
    }


    /*public static async login(email: string | null = null, password: string | null = null): Promise<boolean> {
        let auth = false;
        if (email != null && password != null) {
            // authorize with credentials

            const res = await HttpService.post2<IVerifyPin, IVerifyPinResult>(VERIFY_PIN, {email, pin}, false, false);
            if (res != null && res.jwtToken != null && res.jwtToken.length > 0) {
                const token = AuthService.parseJwt(res.jwtToken)

                LocalService.setProfile({
                    name: '',
                    userId: token.userId,
                    auth: res,
                });
                CacheService.clear();
                const user = await AuthService.getUser();
                if (user) {
                    console.log('setprofile', user, token);
                    LocalService.setProfile({
                        name: `${user?.title || ''} ${user?.givenName} ${user?.familyName}`.trimStart(),
                        userId: token.userId,
                        auth: res,
                    });
                    auth = true;
                } else {
                    auth = false;
                }
            }

        } else {
            // authorize with jwt
            // @ts-ignore // TODO: Use native storage element


            auth = !(LocalService.getAuth() == null || LocalService.getAuth()?.jwtToken.length === 0);
        }
        // return 'Bearer abc';
        // auth = true;
        setTimeout(() => AuthService._authorization.next(auth), 500);
        return auth;
    }*/



    /**
     * Refreshes the JWT token if necessary.
     *
     * @param {boolean} [force=false] - Whether to force refresh the token.
     * @returns {Promise<void>} - A Promise that resolves once the JWT token is refreshed.
     */
    public static async refreshJwt(force = false) {
        const profile = LocalService.getProfile();
        // console.log(auth.exp, Date.now());
        if (profile && (profile.auth.exp * 1000 < Date.now() + 2000 * 60 || force)) {
            const res = await HttpService.post2<any, IVerifyPinResult>(UPDATE_TOKEN, {
                    jwtToken: profile.auth.jwtToken,
                    refreshToken: profile.auth.refreshToken,
                }
                , true, false);
            if (res?.jwtToken != null) {
                profile.auth = res;
                LocalService.setProfile(profile);
            }
        }
        return;
    }

    /**
     * Sign up a user with the given email.
     *
     * @param {string} email - The email of the user.
     * @return {Promise<boolean>} - A Promise that resolves to a boolean indicating the success or failure of the sign up process.
     */
    static async signup(email: string): Promise<boolean> {
        const res = await HttpService.post2<{ email: string }, boolean>(SIGNUP_USER, {email}, false, false);
        return res || false;
    }

    /**
     * Logout the current user.
     * @returns {Promise<boolean>} A promise that resolves to a boolean indicating if the logout was successful.
     */
    public static async logout(): Promise<boolean> {
        // @ts-ignore // TODO: Use native storage element

        const userId = localStorage.removeItem('userId'); // RK
        const auth = localStorage.removeItem('auth'); // RK
        const profile = LocalService.getProfile();
        if (profile) {
            LocalService.removeProfile(profile.userId);
        }
        return !!LocalService.getProfile();
    }

    /**
     * Parses a JSON Web Token (JWT) and returns the payload as a JavaScript object.
     *
     * @param {string} token - The JWT to parse.
     * @return {Object} - The payload of the JWT as a JavaScript object.
     */
    private static parseJwt(token: string) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    }

    /**
     * Returns an Observable that emits the current authorization status.
     *
     * @return {Observable<boolean>} An Observable that emits a boolean value representing the authorization status.
     */
    public static get authorizationObservable(): Observable<boolean> {
        return AuthService._authorization.asObservable();
    }

    /**
     * Retrieves the current user.
     *
     * @return {Promise<IUserModel|null>} - A promise that resolves with the user object, or null if the user is not logged in.
     */
    public static async getUser(): Promise<IUserModel | null> {

        await AuthService.refreshJwt();
        //console.log(localStorage.getItem(AUTH_STORAGE))
        const res = await HttpService.post2<any, { user: IUserModel }>(GET_USER, null, true, true);
        /*if (res == null || res.user == null) setTimeout(() => {
            console.log('why?', res);

            ApiService.logout()
        }, 0);*/
        return (res || {}).user || null;
    }

    public static async getProfile(): Promise<IUserProfile> {
        await AuthService.refreshJwt();
        return await HttpService.get<IUserProfile>(GET_PROFILE, true);
    }


    public static async  resetPassword(token: string, email: string, password: string): Promise<IVerifyPassword> {
        return HttpService.put<IVerifyPassword>(`${Constants.JAMA_SERVER_API}/auth/reset_password/${token}`, {
            email, password
        });
    }

    public static async requestPassword(email: string): Promise<any> {
        return HttpService.put<any>(`${Constants.JAMA_SERVER_API}/auth/request_password/${email}`, {});
    }
}