/*
This class has methods to:
* Add and subtract vectors.
* Multiply and divide a vector by a scalar.
* Negate a vector (reverse its direction).
* Calculate the length (magnitude) of a vector.
* Normalize a vector (convert it to a unit vector, i.e., a vector of length 1).
It's worth noting that the add, subtract, multiply, divide, negate, and normalize methods all manipulate the vector
they're called on in-place and return the modified vector for chaining. If you want to create new vectors instead of
modifying existing vectors, you'd need to add methods or modify these to clone the vectors before performing operations.
 */

import React from "react";

export class Vec2 {
    readonly x: number;
    readonly y: number;

    constructor(x = 0, y = 0) {
        this.x = x;
        this.y = y;
    }

    static fromTouch(touch: React.Touch) {
        return new Vec2(touch.clientX, touch.clientY);
    }

    add(vector: Vec2): Vec2 {
        return new Vec2(this.x + vector.x, this.y + vector.y);
    }

    subtract(vector: Vec2): Vec2 {
        return new Vec2(this.x - vector.x, this.y - vector.y);
    }

    multiply(scalar: number): Vec2 {
        return new Vec2(this.x * scalar, this.y * scalar);
    }

    divide(scalar: number): Vec2 {
        if (scalar !== 0) {
            return new Vec2(this.x / scalar, this.y / scalar);
        }
        return this;
    }

    negate(): Vec2 {
        return new Vec2(-this.x, -this.y);
    }

    length(): number {
        return Math.sqrt(this.x * this.x + this.y * this.y);
    }

    normalize(): Vec2 {
        const length = this.length();
        if (length !== 0) {
            return this.divide(length);
        }
        return this;
    }

    aspect(): number {
        return this.x / this.y;
    }

    static lerp(start: Vec2, end: Vec2, t: number): Vec2 {
        return new Vec2(
            start.x * (1 - t) + end.x * t,
            start.y * (1 - t) + end.y * t
        );
    }

    static distance(a: Vec2, b: Vec2): number {
        const dx = a.x - b.x;
        const dy = a.y - b.y;
        return Math.sqrt(dx * dx + dy * dy);
    }
}

/*
// Usage
let v1 = new Vec2(2, 3);
let v2 = new Vec2(1, 1);
let v3 = v1.add(v2);
console.log(v3); // Vector {x: 3, y: 4}

 */