
export interface IFilterOptions<T> {
    fetch?: {[keys: string]: () => any[]};
    sub?: string[];
    pub?: string[];
    default?: T;
}

export class Filter<T> {
    private readonly _filter: {[key: string]: any | any[]};
    private readonly _sub: {[key: string]: any | any[]} = {}

    private _onUpdate?: (f: Filter<T>) => T = undefined;

    constructor(private readonly _options: IFilterOptions<T>) {
        this._filter = _options?.default || {};
        this._sub = _options?.sub || {};
    }

    public useFilter(key: keyof T, value: any) {
        this._filter[key as string] = value;
        this._onUpdate && this._onUpdate(this);
    }

    public get(key: keyof T) {
        return this._filter[key as string];
    }

    public clearFilter(key: string) {
        delete this._filter[key];
    }

    public sub<T>(key: string): T {
        return this._sub[key] as T;
    }

    public onUpdate(f: (f: Filter<T>) => T) {
        this._onUpdate = f;
    }

    public getParams() {
        return Object.assign({}, this._filter);
    }

    public reset() {
    }

}