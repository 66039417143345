import React, {useState} from "react";
import styles from './menu.module.scss'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faIdCard, faUser} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import {ReactChildrenType} from "../../core/children";
import moment from "moment/moment";
import AvatarImage from "./AvatarImage";
import {IMAGE_URL} from "../../api";

interface IMenuProps {
    children: ReactChildrenType
    user: any
}


export const Menu: React.FC<IMenuProps> = ({children, user}) => {
    const [open, setOpen] = useState<Boolean>(false);
    const profileImage = IMAGE_URL + user?.imageLink + `?v=${Date.now()}`;

    return (
        <div className={styles.container}>
            <div className={classNames(styles.menu, open && styles.open)} onClick={() => setOpen(!open)}>
                {/*<FontAwesomeIcon*/}
                {/*    icon={faIdCard}*/}
                {/*    className={`${styles.judocard} ${user?.lastjudocardyear == moment(new Date()).format('YYYY') ?*/}
                {/*        styles.judocardactive : styles.judocardinactive}`}*/}
                {/*/>*/}
                <div className={styles.imageContainer}>
                    <AvatarImage
                        className={styles.image}
                        src={profileImage}
                    />
                </div>
                {open && <div className={styles.content}>{children}</div>}
            </div>
        </div>
    )
}