import {withFilter} from "../../../../../../api/withFilter";
import React, {useEffect, useState} from "react";
import {IOrgFunction, IUserModel, IMeeting} from "../../../../../../api/models";
import {useParams} from "react-router";
import {ItemNavGroup} from "../../../../../../components/form/ItemNavGroup";
import {ItemNav} from "../../../../../../components/form/ItemNav";
import {FD, Item} from "../../../../../../components/form";
import {faAdd} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import styles from './AdminOrgMeeting.module.scss'
import {MeetingService} from "../../../../../../api/meeting";

interface IAdminOrgMeetingProps {
    user: IUserModel;
}

const AdminOrgMeeting: React.FC<IAdminOrgMeetingProps> = ({user}) => {
    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [meetings, setMeetings] = useState<IMeeting[]>([]);

    const roleId = Number(params['id'] || 0)
    const role = user?.rolesOrg.map(x => x?.adminOrg || x?.trainerOrg).flat().find(x => x?.funkMgId === roleId) as IOrgFunction;

    useEffect(() => {
        refresh();
    }, [user])

    const refresh = async () => {
        setLoading(true);
        if(role != null) {
            setMeetings(await MeetingService.all(role.orgId));
        }
        setLoading(false);
    }

    return <>
        <ItemNavGroup label={role.orgName}>
            <ItemNav
                label="Neuen Event hinzufügen"
                icon={faAdd}
                href={`/more/organization/meeting/${roleId}/new`}
            />
            {meetings?.length > 0 ? meetings.map(e => (
                    <ItemNav key={e.id} href={`/more/organization/meeting/${roleId}/${e.id}`} className={classNames(e.id ===  1 && styles.active)} >
                        <div className={styles.entry}>
                        <div className={styles.date}>
                            {FD(e.startdate)}
                        </div>
                        <div className={styles.name}>
                            {e.name}
                        </div>
                        </div>
                    </ItemNav>
            )) : <Item type="list" label="Keine Veranstaltungen verfügbar" />}

        </ItemNavGroup>
    </>
}

export default withFilter('Kyu', {}, AdminOrgMeeting, /* TrainerKyuFilter */ undefined, -1);