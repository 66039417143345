import {IUserModel} from "../../../../../../api/models";
import React, {useEffect, useState} from "react";
import {withFilter} from "../../../../../../api/withFilter";
import {useNavigate} from "react-router-dom";
import {useParamId, useParamRole} from "../../../../../../components/useParam";
import {IMember, IMemberDuplicate} from "../../../../../../api/models/MemberModel";
import {MemberService} from "../../../../../../api/member";
import {ItemNavGroup} from "../../../../../../components/form/ItemNavGroup";
import {Button, DateInput, FD, Form, Input, Item, PDS, Select} from "../../../../../../components/form";
import AvatarImage from "../../../../../../components/foundation/AvatarImage";
import styles from "./AdminOrgMemberDetail.module.scss";
import {IUserProfile} from "../../../../../../api/models";
import {toaster} from "../../../../../../core/toaster";
import {Duplicate} from "../../../../../../components/member/duplicate";
import CameraButton from "../../../../../../components/foundation/camera_button";
import {CacheService} from "../../../../../../api";

interface IAdminOrgMemberDetailProps {
    user: IUserModel;
    profile: IUserProfile;
}

const AdminOrgMemberDetail: React.FC<IAdminOrgMemberDetailProps> = ({user, profile}) => {
    const [loading, setLoading] = useState(true);
    const [member, setMember] = useState<IMember | null>({
        email: '',
        surname: '',
        birthdate: "",
        city: "",
        country: 3,
        doorNo: "",
        firstname: "",
        gender: 'm',
        houseNo: "",
        middlename: "",
        mobile: "",
        stair: "",
        street: "",
        title: "",
        uuid: "",
        nationality: 500,
        weightClass: 0,
        zip: 0
    });
    const [duplicates, setDuplicates] = useState<IMemberDuplicate[] | null>(null)
    const [selectedDuplicate, setSelectedDuplicate] = useState<IMemberDuplicate | null>(null)


    const navigate = useNavigate();
    const role = useParamRole(user);
    const id = useParamId();
    useEffect(() => {
        if (user && loading) {
            if (id) {
                MemberService.get(role.orgId, id).then((m) => {
                    setMember(m);
                    setLoading(false);
                });
            } else {
                setLoading(false);
            }
        }
    }, [user]);

    const setMemberParam = (param: keyof IMember) => {
        return (value: any) => {
            let m = member || {} as unknown as IMember;

            // @ts-ignore
            m[param] = value;
            setMember(Object.assign({}, m));
        }
    }

    const assignMembership = (m: IMemberDuplicate) => {
        console.log(m)
        toaster(async () => {
            await MemberService.assignRoleSimple(role.orgId, m.id, 'MEMBERSHIP');
            navigate(`/more/organization/member/${role.funkMgId}`);
        })
    }

    const onSubmit = (evt: any) => {
        evt.preventDefault();
        setLoading(true);
        if (member) {
            if (member.id) {
                toaster(async () => {
                    setMember(await MemberService.update(role.orgId, member));
                    await CacheService.clear();
                }, {
                    success: "Mitglied aktualisiert",
                    failure: "Speichern fehlgeschlagen"
                }, () => {
                    setLoading(false);
                });
            } else {
                toaster(async () => {
                    const m = await MemberService.create(role.orgId, member)
                    if (m.created) {
                        setMember(m.created);
                        await CacheService.clear();
                        navigate(`/more/organization/member/${role.funkMgId}/${m.created.id}`)
                    } else if ((m.duplicates?.length || 0) > 0) {
                        setDuplicates(m.duplicates || [])
                        throw new Error('found duplicates')
                    }
                }, {
                    success: "Mitglied hinzugefügt",
                    failure: "Speichern fehlgeschlagen"
                }, () => {
                    setLoading(false);
                });
            }
        }
        console.log(evt);
        return false;
    }

    const anyAssignable = (duplicates?.filter(x => x?.assignable)?.length || 0) > 0

    const currentYear = new Date(Date.now()).getFullYear()
    console.log(profile)

    const disabled = !!(role.orgId > 10 && member?.id);
    return <>
        <ItemNavGroup label={member?.id ? `JAMA #${member?.id}` : "Neues Mitglied"}>
            {duplicates ? <>
                <div className={styles.somePadding}> <h3>Die Person existiert bereits</h3></div>
                {duplicates.map(d => (
                    <Duplicate selected={d.id === selectedDuplicate?.id} member={d}
                               onClick={(x) => x.assignable && (x.id !== selectedDuplicate?.id ? setSelectedDuplicate(x) : setSelectedDuplicate(null))}/>
                ))}
                {anyAssignable ?
                    <Button width="full" disabled={selectedDuplicate == null} label="Zum Verein hinzufügen"
                            onClick={() => selectedDuplicate && assignMembership(selectedDuplicate)}/>
                    : <Item type="list">Ist bereits Mitglied in einem Verein. Setze dich mit deinem Landesverband in Verbindung.</Item>}
            </> : <Form onSubmit={onSubmit} className={styles.form}>
                <AvatarImage src={MemberService.imageUrl(member?.uuid)} className={styles.image}/>

                {member?.id && <CameraButton
                    className={styles.camera}
                    uuid={member?.uuid}
                    onClick={() => window.location.reload()}
                />}

                <Item type="full" label="Nachname">
                    <Input onChange={setMemberParam("surname")} value={member?.surname} required disabled={disabled} />
                </Item>
                <Item type="full" label="Vorname">
                    <Input onChange={setMemberParam("firstname")} value={member?.firstname} required disabled={disabled} />
                </Item>
                {member?.id && <Item type="full" label="Zweiter Vorname">
                    <Input onChange={setMemberParam("middlename")} value={member?.middlename} disabled={disabled} />
                </Item>}
                {member?.id && <Item type="full" label="Nationalität">
                    <Select type="number" onChange={setMemberParam("nationality")} value={member?.nationality}
                            options={profile?.countries?.map(x => ({
                                label: x.name,
                                value: x.id
                            }))} disabled={disabled} />
                </Item>}
                <Item type="full" label="Geburtsdatum">
                    <DateInput value={PDS(member?.birthdate)} onChange={setMemberParam("birthdate")} required  disabled={disabled} />
                </Item>
                <Item type="full" label="Geschlecht">
                    <Select onChange={setMemberParam("gender")} value={member?.gender} options={[
                        {label: 'M', value: 'm'},
                        {label: 'W', value: 'w'},
                        {label: 'X', value: 'x'}
                    ]} disabled={disabled} />
                </Item>
                {member?.id && <Item type="full" label="Gew. Klasse">
                    <Select type="number" onChange={setMemberParam("weightClass")} value={member?.weightClass}
                            options={profile?.weightClasses?.filter(x => {
                                const age = currentYear - (PDS(member?.birthdate)?.getFullYear() || 0);
                                return (age >= x.min && age <= x.max && (x.gender === member?.gender || member?.gender === 'x')) || x.max === 0;
                            }).map(x => ({
                                label: `${x.label}`,
                                value: x.id
                            }))}/>
                </Item>}
                {member?.id && <Item type="full" label="Straße">
                    <Input onChange={setMemberParam("street")} value={member?.street} required/>
                </Item>}
                {member?.id && <Item type="full" label="Hausnummer">
                    <Input onChange={setMemberParam("houseNo")} value={member?.houseNo} required/>
                </Item>}
                {member?.id && <Item type="full" label="Stiege">
                    <Input onChange={setMemberParam("stair")} value={member?.stair}/>
                </Item>}
                {member?.id && <Item type="full" label="Türnummer">
                    <Input onChange={setMemberParam("doorNo")} value={member?.doorNo}/>
                </Item>}
                {member?.id && <Item type="full" label="Plz">
                    <Input type="number" onChange={setMemberParam("zip")} value={member?.zip} required/>
                </Item>}
                {member?.id && <Item type="full" label="Ort">
                    <Input onChange={setMemberParam("city")} value={member?.city} required/>
                </Item>}
                {member?.id && <Item type="full" label="Land">
                    <Select type="number" onChange={setMemberParam("country")} value={member?.country}
                            options={profile?.countries?.map(x => ({
                                label: x.name,
                                value: x.id
                            }))}/>
                </Item>}
                {member?.id && <Item type="full" label="Tel Mobil">
                    <Input onChange={setMemberParam("mobile")} value={member?.mobile}/>
                </Item>}
                {member?.id && <Item type="full" label="Email">
                    <Input onChange={setMemberParam("email")} value={member?.email}/>
                </Item>}

                <Button type="submit" width="full" label="Speichern" disabled={loading}/>
            </Form>}
        </ItemNavGroup>
    </>
}

export default withFilter('Mitglied', {}, AdminOrgMemberDetail, undefined, -1);
