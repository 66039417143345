import React from 'react';
import {IPageProps} from "../../../../components/page.props";
import {EForms, useParamForm} from "../../../../components/useParam";
import Judocards from "../../../desktop/judocards";
import AppAdminLicences from "./organization/licences";
import AppAdminEvent from "./organization/events";
import AppAdminOrganizations from "./organization/organizations";
import AppAdminKyu from "./organization/kyu";
import AppAdminMember from "./organization/member";



export const AppForm: React.FC<IPageProps> = (props) => {
    const form = useParamForm();


    switch (form) {
        case EForms.memberships:
            return <Judocards {...props} />
        case EForms.members:
            return <AppAdminMember {...props} />
        case EForms.kyu:
            return <AppAdminKyu {...props} />
        case EForms.licences:
            return <AppAdminLicences {...props} />
        case EForms.events:
            return <AppAdminEvent {...props} />
        case EForms.organizations:
            return <AppAdminOrganizations {...props} />
        /*case EForms.trainer:
            return <Trainer {...props} />*/
        default:
            return <p>Not found</p>
            //return <ApplicationDesktop {...props} />
    }
};