import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {useLocation} from "react-router";
import classNames from "classnames";
import {useNavigate} from "react-router-dom";
import styles from './Tab.module.scss'

export interface ITabProps {
    icon: IconProp;
    label: string;
    href?: string;
    active?: boolean;
    onClick?: () => void;
    mode?: 'normal'|'compressed';
}


export const Tab: React.FC<ITabProps> = ({icon, label, href, onClick, active, mode}) => {
    const location = useLocation();
    const navigate = useNavigate();

    const activeUrl = location.pathname.startsWith(href || '~')
    // console.log(location.pathname, href, activeUrl)
    return (
        <div className={classNames(styles.tab, styles[mode || 'normal'],  (active || activeUrl) && styles.active )} onClick={() => onClick ? onClick() : (href && navigate(href))}>
            <div className={styles.icon} ><FontAwesomeIcon icon={icon} /></div>
            <div className={styles.label}>{label}</div>
        </div>
    )
}