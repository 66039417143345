import React, {MouseEvent} from "react";
import styles from './Label.module.scss'
import {ReactChildrenType} from "../../core/children";

interface ILabelProps {
    children: ReactChildrenType;
    onClick?: (evt: React.MouseEvent<HTMLLabelElement>) => void;
    className?: string;
}


export const Label: React.FC<ILabelProps> = ({children, onClick, className}) => {

    return (
        <>
            <label
                onClick={(evt) => onClick && onClick(evt)}
                className={`${styles.label} ${className}`}
            >
                {children}
            </label>
        </>
    )
}
