import React from 'react';
import {INewsModel} from "../../api/models/NewsModel";


import styles from './NewsCard.module.scss'
import classNames from "classnames";
import { Card } from '../foundation';
import {EFileVersion, UtilService} from "../../api";

interface INewsCardProps {
    news: INewsModel;
    onClick: (details: INewsModel) => void
}

interface INewsCardState {

}

export class NewsCard extends React.Component<INewsCardProps, INewsCardState> {

    render() {

        const { news, onClick} = this.props
        const image = news && news.attachedFiles && news.attachedFiles.length > 0 ? news.attachedFiles[0] : null;

        return (
            <Card
                noPadding={true}
                onClick={() => onClick(news)}
            >
                <div className={classNames(styles.container, news.readAt === null && styles.new)} >
                    <div className={styles.containerContent}>
                       <h1 dangerouslySetInnerHTML={{__html: news.title}} />
                       <p  dangerouslySetInnerHTML={{__html: news.abstract || news.content}} />
                    </div>

                    {image && (
                        <div className={styles.containerMask}>
                            <img
                                className={styles.containerImage}
                                key={image.fileId}
                                src={UtilService.getFileUrl(image, EFileVersion.sm)}
                                alt={image.fileName}/>
                        </div>
                    )}
                </div>
            </Card>
        );
    };
}


/*<div className={styles.containerMask}
                     children={news.readAt === null && <IonBadge className={styles.newBadge}> NEW </IonBadge>}
                />*/