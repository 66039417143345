import React, { useEffect, useMemo, useState } from 'react';
import { IPublicPageLinkProps } from "../page.props";
import styles from "../../pages/mobile/news/NewsDetail.module.scss";
import { EFileVersion, OrderService, UtilService } from "../../api";
import { IOrderInventory, IOrderInventoryCartItem, IOrderInventoryParams } from "../../api/models";
import { useParamId } from "../useParam";
import { Button, Item, Segment } from "../form";
import { useNavigate } from "react-router-dom";
import { CartItem } from "./cart_item";

const DEFAULT_GENDER = 'u';
const DEFAULT_SIZE = 'M';
const DEFAULT_COLOR = 'Standard';

const SIZE_SORT = [
    "92", "104", "116", "128", "140", "152", "164",
    "XXS", "XS", "S", "M", "L", "XL", "XXL", "2XL", "3XL", "4XL", "5XL", "6XL", "7XL",
]

const GENDER_TRANSLATIONS = {
    "m": "Herren",
    "f": "Damen",
    "u": "Unisex"
}

export const ShopDetail: React.FC<IPublicPageLinkProps> = ({ gotoUrl }) => {
    const [items, setItems] = useState<IOrderInventory[]>();
    const [selectedItem, setSelectedItem] = useState<IOrderInventory>()
    const [selectedGender, setSelectedGender] = useState<string>(DEFAULT_GENDER)
    const [selectedSize, setSelectedSize] = useState<string>(DEFAULT_SIZE)
    const [selectedColor, setSelectedColor] = useState<string>(DEFAULT_COLOR)
    const [cartItem, setCartItem] = useState<IOrderInventoryCartItem>()
    const id = useParamId();
    const navigate = useNavigate();

    const sortString = (v: IOrderInventory) => `${v.inventoryParams.gender || DEFAULT_GENDER}${SIZE_SORT.indexOf(v.inventoryParams.size || DEFAULT_SIZE).toString().padStart(3, '0')} ${v.inventoryParams.color || DEFAULT_COLOR}`
    const sort = (a: IOrderInventory, b: IOrderInventory) => sortString(a)?.localeCompare(sortString(b))

    const [userId] = useState<any | null>(localStorage.getItem('userId'));


    useEffect(() => {
        OrderService.inventory().then((inv) => {
            setItems(inv.filter(x => x.newsId === id).sort(sort))
        });
    }, [id])

    useEffect(() => {
        let item = items?.find(x =>
            (x.inventoryParams.gender || DEFAULT_GENDER) === selectedGender &&
            (x.inventoryParams.size || DEFAULT_SIZE) === selectedSize &&
            (x.inventoryParams.color || DEFAULT_COLOR) === selectedColor
        )
        if (item) {
            setSelectedItem(item)
        } else {
            item = items?.find((x) => (x?.inventoryParams.gender || DEFAULT_GENDER) === selectedGender);
            if (!item) {
                item = items?.find(() => true)
            }
            setSelectedGender(item?.inventoryParams.gender || DEFAULT_GENDER);
            setSelectedSize(item?.inventoryParams.size || DEFAULT_SIZE);
            setSelectedColor(item?.inventoryParams.color || DEFAULT_COLOR);
        }
        if (!cartItem) {
            setCartItem({
                itemId: item?.id || 0,
                quantity: 1
            });
        } else {
            if (cartItem.itemId !== (item?.id || 0)) {
                cartItem.itemId = item?.id || 0;
                setCartItem(Object.assign({}, cartItem));
            }
        }

    }, [items, selectedColor, selectedSize, selectedGender, cartItem]);

    const availableItems = useMemo(() => {
        return items?.filter(x =>
            (!selectedGender || (selectedGender && x.inventoryParams.gender === selectedGender)) ||
            (!selectedSize || (selectedSize && x.inventoryParams.size === selectedSize)) ||
            (!selectedColor || (selectedColor && x.inventoryParams.color === selectedColor))
        )
    }, [selectedItem, selectedGender])

    const filterItems = (items: IOrderInventory[], attr: keyof IOrderInventoryParams, defaultValue: string, filter: (item: IOrderInventory) => boolean = () => true) => {
        let ret = items?.filter(filter).map(x => x.inventoryParams[attr] as string || defaultValue) || [];
        ret = ret.filter((x, i, o) => o.indexOf(o.find(y => y === x) || defaultValue) === i);
        return ret.sort((a, b) => SIZE_SORT.indexOf(a) - SIZE_SORT.indexOf(b))
    }

    const filterDetail = (item: IOrderInventory) => !(item.inventoryParams.gender === selectedGender && item.inventoryParams.size === selectedSize && item.inventoryParams.color === selectedColor);

    const availableGender = useMemo(() => filterItems(items || [], 'gender', DEFAULT_GENDER), [items])
    const availableSizes = useMemo(() => filterItems(items || [], 'size', DEFAULT_SIZE), [items])
    const filteredSizes = useMemo(() => filterItems(availableItems || [], 'size', DEFAULT_SIZE, filterDetail), [availableItems, selectedColor, selectedSize, selectedGender])
    const availableColors = useMemo(() => filterItems(items || [], 'color', DEFAULT_COLOR), [items])
    const filteredColors = useMemo(() => filterItems(availableItems || [], 'size', DEFAULT_COLOR, filterDetail), [availableItems, selectedColor, selectedSize, selectedGender])

    const options = (available: string, filtered: string[] | undefined = undefined, translations: { [key: string]: string } | undefined = undefined) => ({
        label: translations ? translations[available] : available,
        value: available,
        disabled: filtered ? !filtered.includes(available) : false
    })

    const pay = async () => {
        if (cartItem) {
            OrderService.addCart(cartItem)
            navigate(`${gotoUrl}/cart`)
        }
    }
    //console.log('userId:', userId ? userId :  'missing in shop_detail')
    return (
        <div className={styles.news}>
            {selectedItem && <>
                <h1 dangerouslySetInnerHTML={{ __html: selectedItem.title }}></h1>
                <p dangerouslySetInnerHTML={{ __html: selectedItem.abstract }}></p>

                {selectedItem?.attachedFiles?.map((image, i) => (
                    <img className={styles.image}
                         key={`detail-${image.fileId}`}
                         src={UtilService.getFileUrl(image, EFileVersion.md)}
                         alt={image.fileName} />
                ))}
                <div className={styles.content}
                     dangerouslySetInnerHTML={{ __html: selectedItem?.content || selectedItem?.abstract }} />
            </>}
            {availableGender.length > 1 && <Item type={'full'} label={"Geschlecht"}>
                <Segment value={selectedGender} onChange={v => setSelectedGender(v || DEFAULT_GENDER)}
                         options={availableGender.map(x => options(x, undefined, GENDER_TRANSLATIONS))} />
            </Item>}
            {availableSizes.length > 1 && <Item type={'full'} label={"Größe"}>
                <Segment value={selectedSize} onChange={v => setSelectedSize(v || DEFAULT_SIZE)}
                         options={availableSizes.map(x => options(x, filteredSizes))} />
            </Item>}
            {availableColors.length > 1 && <Item type={'full'} label={"Farbe"}>
                <Segment value={selectedColor} onChange={v => setSelectedColor(v || DEFAULT_COLOR)}
                         options={availableColors.map(x => options(x, filteredColors))} />
            </Item>}
            {cartItem && <CartItem inventory={items || []} item={cartItem}
                                   onUpdate={(item) => setCartItem(Object.assign({}, item))} limit={999}
                                   remove={false} />}
            <h3 className={styles.price}>
                {cartItem?.quantity} x {userId && selectedItem ?
                selectedItem?.categoryParams?.memberNetPrice : selectedItem?.categoryParams?.netPrice} = {(cartItem?.quantity || 1) * (userId && selectedItem
                ? selectedItem?.categoryParams?.memberNetPrice : selectedItem?.categoryParams?.netPrice || 0)} EUR
                {userId ? <div> Mitgliedspreis{/*selectedItem?.categoryParams?.memberNetPrice*/}</div> : <div> Normalpreis</div>}
            </h3>
            <Button width={"full"} label={"Zur Bestellung hinzufügen"} onClick={pay} />
        </div>
    );
};
