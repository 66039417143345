import React, {useEffect, useMemo, useState} from 'react';
import {withFilter} from "../../../../../../api/withFilter";
import {useParamId, useParamRole} from "../../../../../../components/useParam";
import {IUserModel} from "../../../../../../api/models/UserModel";
import {Button, Item} from "../../../../../../components/form";
import {useNavigate} from "react-router-dom";
import {LicenceService} from "../../../../../../api/licence";
import {ILicenceCandidate} from "../../../../../../api/models";
import {toaster} from "../../../../../../core/toaster";
import styles from './AdminOrgLicenceSubmit.module.scss';
import {ItemNavGroup} from "../../../../../../components/form/ItemNavGroup";
import {CacheService} from "../../../../../../api";

interface IAdminOrgLicenceSubmitProps {
    user: IUserModel

}

const AdminOrgLicenceSubmit: React.FC<IAdminOrgLicenceSubmitProps> = ({user}) => {
    const role = useParamRole(user)
    const navigate = useNavigate();
    const id = useParamId()
    const [candidates, setCandidates] = useState<ILicenceCandidate[]>()

    useEffect(() => {
        toaster(async () => {
                await CacheService.clear()
                setCandidates(await LicenceService.candidates(role.orgId, id||0))
                return null
            }, {
                success: null, failure: "Fehler beim laden"
            }
        )

    }, [role, id]);

    const submit = () => {
        toaster(async () => {
                await LicenceService.submit(role.orgId, id||0)
                return
            }, {
                success: "Bestellung gesendet",
                failure: "Fehler beim Speichern"
            }, () => navigate(`/more/organization/licence/${role?.funkMgId}/`)

        )
    }

    return (
        <div>
            <ItemNavGroup label={`Bestellung #${id}`}>
                <>
                    {candidates?.filter(x => x.ordered)?.map(c => (
                        <Item key={c.mgId} type={"list"} label={`${c.surname} ${c.firstname}`}></Item>
                    ))}
                </>
            </ItemNavGroup>

            <Button label="Abschicken" className={styles.save}
                    onClick={submit} disabled={false}/>
        </div>
    );
};

export default withFilter('Judocard', {}, AdminOrgLicenceSubmit, undefined, -1);