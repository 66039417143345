import React, {useEffect, useMemo, useState} from 'react';
import './AdminTrainer.module.scss';

import {withFilter} from "../../../../../api/withFilter";
import {IOrgFunction, IUserModel} from "../../../../../api/models/UserModel";
import {ItemNavGroup} from "../../../../../components/form/ItemNavGroup";
import {ItemNav} from "../../../../../components/form/ItemNav";
import {useParams} from "react-router";
import styles from "../organization/AdminOrg.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBuilding, faCalendar, faCartShopping, faIdCard, faPerson} from "@fortawesome/free-solid-svg-icons";
import {FlattenAdminRoles} from "../../../../../util/roles";
import {ProfileService} from "../../../../../api";
import {EForms} from "../../../../../components/useParam";
import {v4 as uuidv4} from "uuid";


interface ITrainerProps {
    user: IUserModel
    filter: any
}


const Trainer: React.FC<ITrainerProps> = ({filter, user}) => {
    const params = useParams();
    const [forms, setForms] = useState<{funkMgId: number, form?: string}[]>()

    const orgId = Number(params['id']);

    const adminRoles = useMemo(() => FlattenAdminRoles(user).filter(x => x.orgId === orgId), [user, orgId])
    useEffect(() => {
        const refresh = async() => {
            const forms = await Promise.all(adminRoles.map(x => ProfileService.getForm(orgId, x.funkMgId)))

            const result = forms.map((form, i) => ({
                funkMgId: adminRoles[i].funkMgId,
                form,
            }))
            setForms(result);
        }
        refresh();
    }, [adminRoles, orgId]);

    const link = (funkMg: number) => {
        const form = forms?.find(x => x.funkMgId === funkMg)?.form
        const role = adminRoles.find(x =>x.funkMgId === funkMg);
        switch (form) {
            case EForms.memberships:
                return <ItemNav key={role?.funkMgId} label={role?.functionName} icon={faIdCard} href={`/more/trainer/membership/${role?.funkMgId}`} />
            case EForms.members:
                return <ItemNav key={role?.funkMgId} label={role?.functionName} icon={faPerson} href={`/more/admin/${role?.funkMgId}/members`} />
            case EForms.kyu:
                return <ItemNav key={role?.funkMgId} label={role?.functionName} icon={faCalendar} href={`/more/admin/${role?.funkMgId}/kyu`} />
            case EForms.licences:
                return <ItemNav key={role?.funkMgId} label={role?.functionName} icon={faCartShopping} href={`/more/admin/${role?.funkMgId}/licences`} />
            case EForms.events:
                return <ItemNav key={role?.funkMgId} label={role?.functionName} icon={faCalendar} href={`/more/admin/${role?.funkMgId}/events`} />
            case EForms.organizations:
                return <ItemNav key={role?.funkMgId} label={role?.functionName} icon={faBuilding} href={`/more/admin/${role?.funkMgId}/organizations`} />
            default:
                 return ''
                // return <ItemNav className={styles.noform} key={uuidv4()} label={`${role?.functionName}` }/>
        }
    }

    const orgEditMgId = forms?.find(x => x.form === EForms.organizations)?.funkMgId
    return (
        <>
            <div className={styles.header}>
                {orgEditMgId &&
                    <ItemNav icon={faBuilding} label={adminRoles.find(x => x.funkMgId === orgEditMgId)?.orgName} href={`/more/organization/edit/${orgEditMgId}/${orgId}`} />
                }
                <ItemNavGroup label={"Trainer"} >
                    {adminRoles.sort((a,b) => a.functionName.localeCompare(b.functionName) )?.map(role => link(role.funkMgId))}
                </ItemNavGroup>
                {/*<Item key={role.funkMgId} label={role.orgName}
                      onClick={() => navigate(`/more/tournament/detail/${role.funkMgId}`)}>
                    <Label>{role.functionName}</Label>
                </Item>*/}
            </div>
        </>
    )
}

export default withFilter('Trainer', {sub: ['roles']}, Trainer, undefined, -1);
/*
<Item key={role.funkMgId} label={role.orgName}
                      onClick={() => navigate(`/admin/trainer/detail/${role.funkMgId}`)}>
                    <Label>{role.functionName}</Label>
                </Item>
 */