export class Constants {

    static JAMA_DOMAIN = process.env.REACT_APP_JAMA_DOMAIN || 'https://api.judojama.org';

    static JAMA_SERVER_API = `${Constants.JAMA_DOMAIN}/api/v1`;
    static JAMA_SERVER_API_V2 = process.env.REACT_APP_JAMA_DOMAIN_V2 ?`${process.env.REACT_APP_JAMA_DOMAIN_V2}/api/v2` :`${Constants.JAMA_DOMAIN}/api/v2`

    static JAMA_WP_ROOT = `${Constants.JAMA_DOMAIN}/wp/`


    // const JAMA_SUBDOMAIN_PDF = process.env.REACT_APP_JAMA_SUBDOMAIN_PDF || 'demo';
}