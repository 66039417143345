import {Content} from "../components/foundation";
import React from "react";
import {ApplicationDesktop} from "../components/foundation/application_desktop";
import {useNavigate} from "react-router-dom";


export const withDesktop = (
    Component: any
) => (props: any) => {

    return <ApplicationDesktop {...props}>
        {/*<Content state={'active'}>

        </Content>*/}
        <Component {...props} />
    </ApplicationDesktop>

}