import {IFileModel} from "./NewsModel";

export const orderTranslations: { [key: string]: string } = {
    created: 'Beantragt',
    approved_jv: 'Freigegeben JV',
    approved_lv: "Freigegeben LV",
    approved_oejv: "Freigegeben OEJV",
    canceled: "Abgelehnt",
}

export interface Updates {
    date: string;
    state: string;
    orgId: number;
    orgName: string;
    comment: string;
    userId: number;
    userName: string;
    trainerLicenseName?: string;
}

export interface IOrderMember {
    id: number;
    orgId: number;
    completed: string;
    status: string;
    surname: string;
    firstname: string;
    nationality: number;
    birthdate: string;
    uuid: string;
    licence: boolean;
    mgId: number;
    orderCatId: number;
    createdAt: string;
    updates: Updates[];
}

export interface IOrderInventoryCategoryParams {
    vat: number;
    name: string;
    target: string;
    netPrice: number;
    memberNetPrice: number;
    product: string;
}

export interface IOrderInventoryParams {
    visibility: boolean;
    gender?: string;
    size?: string;
    color?: string;
}

export interface IOrderInventory {
    id: number;
    ordercatId: number;
    ordercat: string;
    newsId: number;
    title: string;
    abstract: string;
    content: string;
    attachedFiles: IFileModel[] | null;
    inventoryParams: IOrderInventoryParams;
    categoryParams: IOrderInventoryCategoryParams;
    quantity: number;
}

export interface IOrderInventoryCartItem {
    quantity: number;
    itemId: number;
    tournament?: number;
    price?: number;
    bowClassId?: number;
    ageClass?: string;
    // size?: string;
    // gender?: string;
    // color?: string;
}

export interface IOrderInventoryCart {
    items: IOrderInventoryCartItem[];
    params: {
        address: string,
        street: string,
        zip: number|null,
        city: string
    }
}