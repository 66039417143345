import React from "react";
import styles from './Tabs.module.scss'

export interface ITabsProps {
    children: (React.ReactElement|false)[];
}

export const Tabs: React.FC<ITabsProps> = ({children}) => {

    return (
        <div className={styles.tabs}>
            <div className={styles.tabLayout}>
                {children}
            </div>
        </div>
    )
}