import React, {useState} from 'react';
import {IPageProps} from "../../../components/page.props";
import {useParamId, useParamRole} from "../../../components/useParam";
import {Shop} from "../../../components/public/shop";
import {ShopDetail} from "../../../components/public/shop_detail";
import {withFilter} from "../../../api/withFilter";


const MobileShopPage: React.FC<IPageProps> = (props) => {
    const role = useParamRole(props.user);
    const id = useParamId();
    const gotoUrl = `/shop`;
    const [refresh, setRefresh] = useState<number>(0)

    return (
        id == null ? <Shop {...props} gotoUrl={gotoUrl} refresh={refresh} setRefresh={setRefresh} /> :
            <ShopDetail {...props} gotoUrl={gotoUrl} refresh={refresh} setRefresh={setRefresh} />
    );
};

export default withFilter('Shop', {}, MobileShopPage, undefined, "/shop");
