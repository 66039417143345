import React, {lazy} from "react";
import {IUserModel, IUserProfile} from "./api/models";
import {BrowserRouter, NavigateFunction, Route, Routes, useNavigate} from "react-router-dom";
import styles from './app__desktop.module.scss';
import {withAuth} from "./api/withAuth";
import Forbidden from "./pages/desktop/forbidden";
import ProfilePage from "./pages/desktop/profile";
import ProfileTrainerPage from "./pages/desktop/profile_trainer";
import {Login} from "./pages/desktop/login";
import {Toaster} from "./core/toaster";

//const DesktopForm = lazy(() => require("./pages/desktop/form").DesktopForm);
import {DesktopForm} from "./pages/desktop/form";
import DesktopShopPage from "./pages/desktop/shop";
import {MenuItem} from "./components/foundation";
import {AuthService} from "./api";


interface IDesktopProps {
    auth: boolean;
    user: IUserModel;
    profile: IUserProfile;
    refresh: () => Promise<void>;
}

const AppArcheryDesktop: React.FC<IDesktopProps> = ({auth, user, profile, refresh}) => {
    const menu = (navigate: NavigateFunction) => (<>
        <MenuItem label="Shop" to={"/shop"} />
        <MenuItem label="Profile" to={"/profile"}/>
        <MenuItem label="Logout" onClick={() => {
            AuthService.logout().then(() => {
                navigate('/login')
            });
        }}/></>)


    const pprops = {user, profile, menu};
    return (
        <BrowserRouter basename="/admin">
            <Toaster />
            <div className={styles.layout}>
                <Routes>
                    <Route path="/" element={auth ? <DesktopForm {...pprops} /> : <Forbidden/>} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/profile" element={auth ? <ProfilePage {...pprops} /> : <Forbidden />} />
                    <Route path="/shop" element={<DesktopShopPage {...pprops}/>} />
                    <Route path="/shop/:id" element={<DesktopShopPage {...pprops}/>} />
                    <Route path="/profile/trainer" element={auth ? <ProfileTrainerPage {...pprops} /> : <Forbidden />} />
                    <Route path="/:org" element={auth ? <DesktopForm {...pprops} /> : <Forbidden/>} />
                    <Route path="/:org/:role" element={auth ? <DesktopForm {...pprops} /> : <Forbidden/>} />
                    <Route path="/:org/:role/:form" element={auth ? <DesktopForm {...pprops} /> : <Forbidden/>} />
                    <Route path="/:org/:role/:form/:id" element={auth ? <DesktopForm {...pprops} /> : <Forbidden/>} />
                    {/*<Route path="/member/:role/:id" element={<DesktopMemberPage {...pprops} />} />*/}
                </Routes>
            </div>
        </BrowserRouter>
    )
}

export default withAuth(AppArcheryDesktop);
