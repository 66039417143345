import {IUserModel} from "../../../../api/models/UserModel";
import React, {useState} from "react";
import {withFilter} from "../../../../api/withFilter";
import {ItemNavGroup} from "../../../../components/form/ItemNavGroup";
import {Button, Input, Item, Segment} from "../../../../components/form";
import styles from './ProfileEdit.module.scss';
import {useNavigate} from "react-router-dom";
import {CacheService, ProfileService} from "../../../../api";

interface IProfileEditProps {
    user: IUserModel; // IProfile;
    refresh: () => Promise<void>;
}

const ProfileEdit: React.FC<IProfileEditProps> = ({user, refresh}) => {
    const [address, setAddress] = useState(user?.addressEdit?.strasse);
    const [house, setHouse] = useState(user?.addressEdit?.hausnummer);
    const [stair, setStair] = useState(user?.addressEdit?.stiege);
    const [door, setDoor] = useState(user?.addressEdit?.tuernummer);
    const [zip, setZip] = useState(user?.addressEdit?.plz);
    const [city, setCity ] = useState(user?.addressEdit?.ort);
    const [mobile, setMobile] = useState(user?.addressEdit?.tel_mobil);
    const [phone, setPhone] = useState(user?.addressEdit?.tel_festnetz);
    const [office, setOffice] = useState(user?.addressEdit?.tel_firma);
    const [ignore, setIgnore] = useState(user?.addressEdit?.email_ignore);

    const navigate = useNavigate();

    const save = async () => {
        await ProfileService.editProfile({
            ort: city,
            plz: zip,
            stiege: stair,
            strasse: address,
            tel_festnetz: phone,
            tel_firma: office,
            tel_mobil: mobile,
            tuernummer: door,
            hausnummer: house,
            email_ignore: ignore,
        })

        await CacheService.clear();
        await (refresh && refresh())
        navigate('/more/profile');
    }

    return (
        <ItemNavGroup label="Bearbeiten">
            <Item type="list" label="Straße *"><Input onChange={setAddress} defaultValue={address}  maxLength={50} /></Item>
            <Item type="list" label="Hausnr *"><Input onChange={setHouse} defaultValue={house}  maxLength={10} /></Item>
            <Item type="list" label="Stiege"><Input onChange={setStair} defaultValue={stair}  maxLength={5} /></Item>
            <Item type="list" label="Tür"><Input onChange={setDoor} defaultValue={door}  maxLength={5} /></Item>
            <Item type="list" label="PLZ *"><Input onChange={setZip} defaultValue={zip}  maxLength={6} type={'number'} /></Item>
            <Item type="list" label="Ort *"><Input onChange={setCity} defaultValue={city}  maxLength={50} /></Item>
            <Item type="list" label="Mobil"><Input onChange={setMobile} defaultValue={mobile}  maxLength={50} /></Item>
            <Item type="list" label="Festnetz"><Input onChange={setPhone} defaultValue={phone}  maxLength={50} /></Item>
            <Item type="list" label="Firma"><Input onChange={setOffice} defaultValue={office} maxLength={50} /></Item>
            <Item type="list" label="Newsletter"><Segment onChange={setIgnore} defaultValue={ignore} options={[{
                value: 0,
                label: 'Ja'
            }, {
                value: 1,
                label: 'Nein'
            }]} /></Item>
            <Button className={styles.button} label="Speichern" onClick={save}
                disabled={!(
                    (address || '').length > 1 &&
                    (house || '').length > 0 &&
                    (`${zip}`).length > 1 &&
                    (city || '').length > 1
                )}
            />

        </ItemNavGroup>
    )
}

export default withFilter('Profile', {}, ProfileEdit, undefined, '/more/profile')