import {Constants} from "./constants";
import {AuthService} from "./auth";
import {HttpService} from "./http";
import {R} from "./util";
import {IOrderInventory, IOrderInventoryCart, IOrderInventoryCartItem, IOrderMember} from "./models";
import {CacheService} from "./cache";

const GET_TRAINER = `${Constants.JAMA_SERVER_API_V2}/admin/:org/trainer`;
const GET_INVENTORY = `${Constants.JAMA_SERVER_API_V2}/web/shop/inventory`
const GET_INVENTORY_ITEM = `${Constants.JAMA_SERVER_API_V2}/web/shop/inventory/item`
const POST_PURCHASE = `${Constants.JAMA_SERVER_API_V2}/web/shop/purchase`

export class OrderService {

    public static async trainer(org: number): Promise<IOrderMember[]> {
        await AuthService.refreshJwt();
        return await HttpService.get<IOrderMember[]>(R(`${GET_TRAINER}`, org), true);
    }

    public static async trainerById(org: number, id: number): Promise<IOrderMember[]> {
        await AuthService.refreshJwt();
        return await HttpService.get<IOrderMember[]>(R(`${GET_TRAINER}/${id}`, org), true);
    }

    public static async trainerUpdate(org: number, id: number, status: string, comment: string, license?: number): Promise<Boolean> {
        await AuthService.refreshJwt();
        await CacheService.clear();
        return await HttpService.put2<{status: string, comment: string, license?: number}, boolean>(R(`${GET_TRAINER}/${id}`, org), {status, comment, license});
    }

    public static async inventory(): Promise<IOrderInventory[]> {
        return await HttpService.get(GET_INVENTORY);
    }

    public static async inventoryItem(id: number): Promise<IOrderInventory> {
        return await HttpService.get(`${GET_INVENTORY_ITEM}/${id}`);
    }

    public static async inventoryPurchase(cart: IOrderInventoryCart): Promise<string> {
        return await HttpService.post2<IOrderInventoryCart, string>(`${POST_PURCHASE}`, cart);
    }

    public static setCart(cart: IOrderInventoryCart): IOrderInventoryCart {
        localStorage.setItem("cart",  JSON.stringify(cart));
        return this.getCart();
    }

    public static getCart(): IOrderInventoryCart {
        return JSON.parse(localStorage.getItem("cart") || '{"items":[]}') as IOrderInventoryCart;
    }

    public static addCart(item: IOrderInventoryCartItem): IOrderInventoryCart {
        const cart = this.getCart();
        const existingItem = cart.items.find(x => x.itemId === item.itemId);

        if (existingItem) {
            existingItem.quantity += item.quantity
        }else {
            cart.items.push(item);
        }
        return this.setCart(cart);
    }
}